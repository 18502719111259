import {
  GET_RESERVATIONS,
  CREATE_RESERVATION,
  UPDATE_RESERVATION,
  DELETE_RESERVATION
} from 'store/actions/reservations'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  reservations: [],
  isPending: false,
  error: {
    messages: []
  }
}

export default typeToReducer(
  {
    [GET_RESERVATIONS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { response } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = response
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.reservations = payload
          draft.isPending = false
        })
    },
    [CREATE_RESERVATION]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.reservations.push(payload)
          draft.isPending = false
          draft.error = {
            messages: []
          }
        })
    },
    [UPDATE_RESERVATION]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const reservationIndex = state.reservations.findIndex(
          reservation => reservation.id === payload.id
        )
        return produce(state, draft => {
          if (reservationIndex > -1) {
            draft.reservations[reservationIndex] = payload
          } else {
            draft.reservations.push(payload)
          }
          draft.isPending = false
        })
      }
    },
    [DELETE_RESERVATION]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { meta: { id } }) => {
        const reservationIndex = state.reservations.findIndex(
          reservation => reservation.id === id
        )
        return produce(state, draft => {
          if (reservationIndex > -1) {
            draft.reservations.splice(reservationIndex, 1)
          }
          draft.isPending = false
        })
      }
    }
  },
  initialState
)
