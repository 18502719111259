import { Get, Post } from 'api/index'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>
// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_ORGANIZATION = 'GET_ORGANIZATIONS'
export const SEARCH_ORGANIZATION = 'SEARCH_ORGANIZATION'
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'

export const getOrganization = (id: number): ThunkAction => dispatch => {
  const url = `/api/organizations/${id}/`
  return dispatch({
    type: GET_ORGANIZATION,
    payload: Get(url)
  })
}

export const searchOrganization = (
  params: Object<{
    business_id: string
  }> = {}
): ThunkAction => dispatch => {
  const url = `/api/organizations/search/`
  return dispatch({
    type: SEARCH_ORGANIZATION,
    payload: Get(url, params)
  })
}

export const createOrganization = (
  body: Array<{
    business_id: string,
    city: string,
    name: string,
    phone_number: string,
    postcode: string,
    street_address: string,
    country_id: number
  }>
): ThunkAction => dispatch => {
  const url = `/api/organizations/`

  return dispatch({
    type: CREATE_ORGANIZATION,
    payload: Post(url, JSON.stringify(body))
  })
}
