import React from 'react'
import styled from 'styled-components/macro'
import { t } from 'ttag'

const HeaderContainer = styled.div`
  background-color: #f2f4f9;
  &:after {
    content: '';
    clear: both;
    display: table;
  }
`

const HeaderTitle = styled.h1`
  padding: 20px 25px;
  margin: 0;
  color: #303030;
  font-size: 19px;
  line-height: 23px;
  float: left;
`

const CloseButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  float: right;
  height: 100%;
  font-size: 24px;
  font-weight: bold;

  margin: 15px;
`

export default ({ title, action }: { title: string, action: void }) => (
  <HeaderContainer>
    <HeaderTitle>{title}</HeaderTitle>
    <CloseButton
      onClick={() => action()}
      type="button"
      aria-label={t`Close modal`}
    >
      &times;
    </CloseButton>
  </HeaderContainer>
)
