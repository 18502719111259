export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

// Open the named modal dialog
export const openModal = (name, options = {}) => ({
  type: OPEN_MODAL,
  payload: { name, options }
})

// Close the named modal dialog, or any active modal if no name is given
export const closeModal = () => ({
  type: CLOSE_MODAL
})
