import React from 'react'
import Modal from 'react-modal'

import ModalHeader from 'components/modals/ModalHeader/ModalHeader'
import ModalFooter, {
  ModalFooterLeft,
  ModalFooterRight
} from 'components/modals/ModalFooter/ModalFooter'

import styled from 'styled-components/macro'

import Button from 'components/Button/Button'
import { t } from 'ttag'

const ModalContainer = styled.div`
  padding: 15px;
`
export default (props: {
  children: React.Node,
  onSubmit: void,
  onSubmit: void,
  onDelete: void,
  pending: boolean,
  closeAction: void,
  rest: Array,
  onClose: void,
  contentLabel: string
}) => {
  const {
    children,
    onSubmit,
    onClose,
    contentLabel,
    onDelete,
    pending,
    closeAction,
    ...rest
  } = props

  return (
    <Modal
      {...rest}
      style={{
        overlay: {
          zIndex: '9999',
          backgroundColor: 'rgba(46,80,99,0.5)'
        },
        content: {
          border: '0',
          borderRadius: '3px',
          bottom: 'auto',
          minHeight: '10rem',
          left: '50%',
          padding: 0,
          position: 'fixed',
          right: 'auto',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          minWidth: '20rem',
          width: '80%',
          maxWidth: '60rem',
          boxShadow: '0 0 12px rgba(0, 0, 0, 0.3)',
          maxHeight: '100vh',
          overflowY: 'auto'
        }
      }}
    >
      <ModalHeader title={contentLabel} action={onClose} />
      <ModalContainer>{pending ? t`Loading...` : children}</ModalContainer>
      <ModalFooter>
        <ModalFooterLeft>
          <Button
            type="button"
            onClick={() => onClose()}
            disabled={pending}
            background="#E556A5"
          >
            {t`Cancel`}
          </Button>
        </ModalFooterLeft>

        <ModalFooterRight>
          {onDelete && (
            <Button
              type="button"
              onClick={onDelete}
              disabled={pending}
              background="#E556A5"
            >
              {t`Remove`}
            </Button>
          )}

          <Button
            type="button"
            onClick={onSubmit}
            disabled={pending}
            background="#6AD69C"
          >
            {t`Save`}
          </Button>
        </ModalFooterRight>
      </ModalFooter>
    </Modal>
  )
}
