// @flow

import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { initStore } from 'store/store'
import PrivateRoute from 'auth/PrivateRoute'

import {
  Login,
  Signup,
  ForgotPassword,
  Users,
  Home,
  ScreensAndGroups,
  WidgetLanding,
  WidgetMaterialUpload,
  Partnerships,
  Campaigns,
  SalesReport,
  WidgetManagement
} from 'pages'

import Layout from 'components/layouts/Layout'
import 'normalize.css'
import 'App.scss'
import 'typeface-roboto'

export default () => (
  <Provider store={initStore()}>
    <Router>
      <Layout>
        <Switch>
          <Route component={Login} path="/login" />
          <Route component={Signup} path="/signup" />
          <Route component={WidgetLanding} path="/checkout/:id/:wid" />
          <Route component={WidgetMaterialUpload} path="/materials/:id" />
          <Route
            component={ForgotPassword}
            path={['/forgotpassword', '/password_reset/:uid/:token/']}
          />
          <PrivateRoute component={Users} path="/users" />
          <PrivateRoute component={ScreensAndGroups} path="/screensandgroups" />
          <PrivateRoute component={Partnerships} path="/partnerships" />
          <PrivateRoute component={Campaigns} path="/campaigns" />
          <PrivateRoute component={SalesReport} path="/salesreport" />
          <PrivateRoute component={WidgetManagement} path="/widget" />
          <PrivateRoute component={Home} path="/" />
        </Switch>
      </Layout>
    </Router>
  </Provider>
)
