import React, { Component } from 'react'
import './CreateReservation.scss'
import styled from 'styled-components/macro'
import { t } from 'ttag'
import ModalContent from 'components/modals/ModalContent/ModalContent'
import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'
import BlipModal from './BlipModal.js'

const ModalContainer = styled.div`
  padding: 15px;
`

const FormPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
`
const FormGroup = styled.div`
  display: flex;
`

const FormLabel = styled.label`
  margin: 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

export default class extends Component<
  {
    campaign: Array<Object>,
    action: void,
    error: Array<Object>
  },
  {}
> {
  constructor(props) {
    super(props)

    const { campaign } = props
    let hasContact = false
    if (campaign.reservations[0].contact_person) hasContact = true

    if (campaign) {
      const { title } = campaign
      this.state = {
        title,
        hasContact,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      }
      if (hasContact) {
        const {
          contact_person: {
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: phoneNumber
          }
        } = campaign.reservations[0]
        this.state = {
          title,
          hasContact,
          firstName,
          lastName,
          email,
          phoneNumber
        }
      }
    } else {
      this.state = {
        title: '',
        hasContact,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      }
    }
  }

  handleChange = e => {
    const { target } = e
    const value = e.type === 'checkbox' ? target.checked : target.value
    const { name } = target

    this.setState({
      [name]: value
    })
  }

  submitForm = () => {
    const { campaign, action } = this.props
    const { id } = campaign
    const { title, status } = this.state

    const payload = {
      id,
      title,
      status
    }
    action(payload)
  }

  render() {
    const { error, campaign, ...rest } = this.props
    const {
      title,
      status,
      hasContact,
      firstName,
      lastName,
      email,
      phoneNumber
    } = this.state

    const {
      customer: {
        business_id: businessId,
        city,
        einvoice_address: eInvoiceAddress,
        einvoice_operator_number: eInvoiceOperatorCode,
        invoice_address: invoiceAddress,
        invoice_email: invoiceEmail,
        name,
        phone: customerPhone,
        postcode: postCode,
        street_address: streetAddress
      }
    } = campaign.reservations[0]

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <BlipModal onSubmit={this.submitForm} {...rest}>
        <ModalContainer>
          <ModalContent title={t`Campaign information`}>
            <FormPanel>
              <FormGroup>
                <FormLabel>
                  <InputWithLabel
                    label={t`Campaign name`}
                    name="title"
                    type="text"
                    value={title}
                    onChange={this.handleChange}
                    error={
                      error &&
                      Object.prototype.hasOwnProperty.call(error, 'title')
                        ? error.title[0].message
                        : false
                    }
                  />
                </FormLabel>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="status">
                  {t`Status`}
                  <select
                    id="status"
                    name="status"
                    onChange={this.handleChange}
                    value={status}
                    style={{
                      borderColor:
                        error &&
                        Object.prototype.hasOwnProperty.call(error, 'status')
                          ? 'red'
                          : '#ccc'
                    }}
                  >
                    <option value="">{t`Valitse...`}</option>
                    <option value="reserved">{t`Preliminary`}</option>
                    <option value="confirmed">{t`Confirmed`}</option>
                    <option value="timed">{t`Content scheduled`}</option>
                    <option value="invoiced">{t`Invoiced`}</option>
                  </select>
                  {error &&
                  Object.prototype.hasOwnProperty.call(error, 'status')
                    ? error.status[0].message
                    : false}
                </FormLabel>
              </FormGroup>
            </FormPanel>
          </ModalContent>
          <ModalContent title={t`Billing information`}>
            <FormPanel>
              {t`Business`}
              <ul>
                <li>
                  {t`Name`}: {name}
                </li>
                <li>
                  {t`Business ID`}: {businessId}
                </li>
                <li>
                  {t`Phone`}: {customerPhone}{' '}
                </li>
                <li>
                  {t`Invoicing E-mail`}: {invoiceEmail}{' '}
                </li>
                <li>
                  {t`Invoicing Address`}: {invoiceAddress}{' '}
                </li>
                <li>
                  {t`Street address`}: {streetAddress}{' '}
                </li>
                <li>
                  {t`Postcode`}: {postCode}{' '}
                </li>
                <li>
                  {t`City`}: {city}{' '}
                </li>
                <li>
                  {t`Electronic Invoicing Address`}: {eInvoiceAddress}{' '}
                </li>
                <li>
                  {t`Operator Code`}: {eInvoiceOperatorCode}{' '}
                </li>
              </ul>
              {hasContact ? (
                <>
                  {t`Contact person`}
                  <ul>
                    <li>
                      {t`Name`}: {firstName} {lastName}
                    </li>
                    <li>
                      {t`E-mail`}: {email}
                    </li>
                    <li>
                      {t`Phone`}: {phoneNumber}
                    </li>
                  </ul>
                </>
              ) : null}
            </FormPanel>
          </ModalContent>
        </ModalContainer>
      </BlipModal>
    )
  }
}
