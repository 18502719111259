import React from 'react'
import styled from 'styled-components/macro'

const ModalContent = styled.div``

const ContentHeader = styled.div`
  background-color: #233d4c;
  padding: 7px 17px;

  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`
const ContentContainer = styled.div`
  padding: 20px 16px;
  background-color: #f2f4f9;
`

export default ({
  title,
  children
}: {
  title: string,
  children: React.Node
}) => (
  <ModalContent>
    <ContentHeader>{title}</ContentHeader>
    <ContentContainer>{children}</ContentContainer>
  </ModalContent>
)
