import React from 'react'
import Box from '@material-ui/core/Box'

const TabPanel = props => {
  const { children, value, index, ...other } = props
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </span>
  )
  /* eslint-enable react/jsx-props-no-spreading */
}

export default TabPanel
