// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'

import { Redirect, Link } from 'react-router-dom'

import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { bindActionCreators } from 'redux'
import { login } from 'store/actions/auth'
import { resetErrorMessage } from 'store/actions/error'
import Input from 'components/FormElements/Inputs/FatInput/FatInput'

import Button from 'components/Button/Button'
import NavBar from 'components/Navbar/Navbar'
import LanguageSelector from '../components/LanguageSelector/LanguageSelector'

const Container = styled.div`
  text-align: center;
  border-radius: 3px;
  background-color: #f2f4f9;
  margin: 0 auto;
  padding: 25px 50px;
  width: 450px;
`

const FormContainer = styled.div``

const Form = styled.form`
  // margin: 50px 0;
`

const Submit = styled.button`
  width: 100%;
  height: 50px;
  padding: 0;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #56c2e5;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;

  :disabled {
    background: gray;
  }
`
class Login extends Component<
  {
    login: Object => void,
    error: Array<Object>,
    resetErrorMessage: () => void,
    auth: Array<Object>,
    location: Array<Object>
  },
  { username: string, password: string, redirectToReferrer: boolean }
> {
  state = {
    username: '',
    password: '',
    redirectToReferrer: false
  }

  fields = [
    { type: 'username', name: 'username' },
    { type: 'password', name: 'password' }
  ]

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0].message) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  submitLogin = e => {
    e.preventDefault()
    const { username, password } = this.state
    const { login: loginAction } = this.props

    loginAction({ username, password }).then(() => {
      this.setState({ redirectToReferrer: true })
    })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const {
      error,
      auth: { isPending },
      location: { state }
    } = this.props

    const { from } = state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state

    if (redirectToReferrer) return <Redirect to={from} />

    const inputs = this.fields.map(({ type, name }) => {
      const fieldError = error ? error[name] : null

      return (
        <Input
          key={`${type}-${name}`}
          type={type}
          name={name}
          onChange={this.handleChange}
          error={fieldError}
        />
      )
    })

    return (
      <div>
        <NavBar />
        <Container>
          <FormContainer>
            {/*  <p>You must log in to view the page at {from.pathname}</p> */}
            <h1>{t`Login`}</h1>
            <Form onSubmit={this.submitLogin}>
              {inputs}
              <Submit type="submit" disabled={isPending}>
                {t`Login`}
              </Submit>
            </Form>
            <p>
              <Link to="/forgotpassword">{t`Forgot password?`}</Link>
            </p>

            <Button style={{ marginTop: '15px' }} to="/signup">
              {t`Sign up`}
            </Button>
            <LanguageSelector />
          </FormContainer>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, error }) => ({
  auth,
  error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      resetErrorMessage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
