import { OPEN_MODAL, CLOSE_MODAL } from 'store/actions/dialogs'
import typeToReducer from 'type-to-reducer'

const initialState = {
  name: '',
  options: {}
}

export default typeToReducer(
  {
    [OPEN_MODAL]: (state, action) => action.payload,
    [CLOSE_MODAL]: () => initialState,
    CREATE_SCREEN_FULFILLED: state =>
      state.name === 'addScreen' ? initialState : state,
    UPDATE_SCREEN_FULFILLED: state =>
      state.name === 'editScreen' ? initialState : state,
    CREATE_CUSTOMER_FULFILLED: state =>
      state.name === 'addCustomer' ? initialState : state,
    CREATE_RESERVATION_FULFILLED: state =>
      state.name === 'createReservation' ? initialState : state,
    UPDATE_RESERVATION_FULFILLED: state =>
      state.name === 'modifyReservation' ? initialState : state,
    DELETE_RESERVATION_FULFILLED: state =>
      state.name === 'modifyReservation' ? initialState : state,
    UPDATE_SCREEN_GROUP_FULFILLED: state =>
      state.name === 'editScreenGroup' ? initialState : state,
    CREATE_PARTNERSHIP_FULFILLED: state =>
      state.name === 'addPartnership' ? initialState : state,
    UPDATE_CAMPAIGN_FULFILLED: state =>
      state.name === 'modifyCampaign' ? initialState : state
  },
  initialState
)
