import DayPickerInput from 'react-day-picker/DayPickerInput'
import styled from 'styled-components/macro'
import React from 'react'

const ErrorField = styled.span`
  padding: 5px;
`

const { classNames } = DayPickerInput.defaultProps

const DayPickerInputWrapper = React.forwardRef(
  (
    {
      className = '',
      error = false,
      ...rest
    }: {
      className: string,
      error: boolean
    },
    ref
  ) => (
    <>
      <DayPickerInput
        ref={ref}
        classNames={{
          ...classNames,
          container: `${classNames.container} ${className}`
        }}
        {...rest}
      />
      {error && <ErrorField>{error}</ErrorField>}
    </>
  )
)

const DayPicker = styled(DayPickerInputWrapper)`
  > input {
    border: 1px solid ${props => (props.error ? 'red' : '#DDDDDD')};
    padding: 4px 9px;
  }
`

export default DayPicker
