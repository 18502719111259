// @flow

import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from 'react-day-picker/moment'
import { openModal, closeModal } from 'store/actions/dialogs'
import {
  getCampaigns,
  modifyCampaign,
  changeStatus,
  deleteCampaign
} from 'store/actions/campaign'
import {
  updateReservation,
  deleteReservation
} from 'store/actions/reservations'
import { resetErrorMessage } from 'store/actions/error'
import Button from 'components/Button/Button'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import LinearProgress from '@material-ui/core/LinearProgress'

import NavBar from 'components/Navbar/Navbar'
import EditReservationModal from 'components/modals/EditReservation'
import EditCampaignModal from 'components/modals/EditCampaign'
import { getScreens } from 'store/actions/screens'
import { getScreenGroups } from 'store/actions/screenGroups'
import { getCustomers } from 'store/actions/customers'
import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'
import DayPicker from 'components/FormElements/Inputs/DayPicker/DayPicker'

export const SearchForm = styled.div`
  margin-top: 30px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  div.inputWithLabelComponent {
    width: 100%;
    padding-right: 20px;
    input#search {
      padding: 3px;
      border-radius: 3px;
      height: 40px;
      font-size: 16px;
    }
  }
  div.DayPickerInput {
    padding-right: 20px;
    text-align: center;
    input {
      padding: 3px;
      border-radius: 3px;
      height: 40px;
    }
  }
`

const DayPickerContainer = styled(DayPicker)({
  display: 'inline-block'
})

const Container = styled.div`
  margin: 50px 100px;
  padding: 25px 50px;
`
const Message = styled.div`
  padding: 20px;
`
const TabContainer = styled.div`
  padding: 20px;
`
const PartnerTableRow = styled(TableRow)({
  background: 'rgb(217, 234, 255)'
})

const CampaignTitle = styled.h3`
  font-size: 1.2rem;
  margin: 0;
`
const PaginationContainer = styled.div`
  text-align: center;
  padding: 10px;
`

const API_DATE_FORMAT = 'YYYY-MM-DD'
const UI_DATE_FORMAT = 'D.M.YYYY'

class Campaigns extends Component<
  {
    campaign: {
      campaigns: {
        count: number,
        next: string,
        previous: string,
        results: Array
      },
      isPending: boolean,
      error: Object
    },
    error: Array<Object>,
    resetErrorMessage: void,
    getCampaigns: void,
    getScreenGroups: void,
    getScreens: void,
    getCustomers: void,
    dialogs: { name: string, options: Object },
    modifyCampaign: void,
    updateReservation: void,
    deleteReservation: void,
    deleteCampaign: void,
    changeStatus: void,
    openModal: void,
    closeModal: void,
    customers: { customers: Array<Object> },
    screens: { screens: Array<Object> },
    screenGroups: { groups: Array, isPending: boolean },
    reservations: {
      reservations: Array<Object>,
      error: Object,
      isPending: boolean
    }
  },
  {}
> {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      searchFrom: '',
      searchTo: '',
      page: 1
    }
  }

  componentDidMount() {
    const {
      getCampaigns: getCampaignsAction,
      getScreenGroups: getScreenGroupsAction,
      getScreens: getScreensAction,
      getCustomers: getCustomersAction
    } = this.props

    Promise.all([
      getCampaignsAction(),
      getScreenGroupsAction(),
      getScreensAction(),
      getCustomersAction()
    ])
  }

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0].message) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  getApiDate = value => {
    const d = moment(value, UI_DATE_FORMAT, true)
    if (d.isValid()) {
      return `${d.format(API_DATE_FORMAT)} 00:00`
    }
    return null
  }

  handleNext = () => {
    const { getCampaigns: getCampaignsAction } = this.props
    const { page, search, searchFrom, searchTo } = this.state
    const newPage = page + 1 < 2 ? 2 : page + 1
    this.setState({ page: newPage })
    getCampaignsAction(
      newPage,
      search,
      this.getApiDate(searchFrom),
      this.getApiDate(searchTo)
    )
  }

  handlePrevious = () => {
    const { getCampaigns: getCampaignsAction } = this.props
    const { page, search, searchFrom, searchTo } = this.state
    const newPage = page - 1 < 1 ? 1 : page - 1
    this.setState({ page: newPage })
    getCampaignsAction(
      newPage === 1 ? null : newPage,
      search,
      this.getApiDate(searchFrom),
      this.getApiDate(searchTo)
    )
  }

  editCampaign = payload => {
    const {
      modifyCampaign: modifyCampaignAction,
      getCampaigns: getCampaignsAction,
      changeStatus: changeStatusAction
    } = this.props
    const { id, title, status } = payload

    if (status) {
      const formData = new FormData()
      formData.append('status', status)
      changeStatusAction(id, formData).then(
        modifyCampaignAction(id, { title }).then(
          getCampaignsAction().then(toast.success(t`Campaign updated!`))
        )
      )
      return
    }
    modifyCampaignAction(id, { title }).then(() => {
      Promise.all([getCampaignsAction()]).then(
        toast.success(t`Campaign updated.`)
      )
    })
  }

  showModifyCampaign = selectedCampaign => {
    const { openModal: action } = this.props
    action('modifyCampaign', { selectedCampaign })
  }

  ModifyCampaignHandler = id => {
    const {
      campaign: {
        campaigns: { results }
      }
    } = this.props
    const campaign = results.find(r => r.id === id)
    if (campaign !== undefined) this.showModifyCampaign(campaign)
  }

  showModifyReservation = selectedReservation => {
    const { openModal: action } = this.props
    action('modifyReservation', { selectedReservation })
  }

  ModifyReservationHandler = resID => {
    const {
      campaign: {
        campaigns: { results }
      }
    } = this.props

    results.map(({ reservations }) => {
      const res = reservations.find(r => r.id === resID)
      if (res !== undefined) this.showModifyReservation(res)
      return null
    })
  }

  editReservation = data => {
    const {
      updateReservation: updateReservationAction,
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction,
      getCampaigns: getCampaignsAction
    } = this.props

    updateReservationAction(data).then(() => {
      Promise.all([
        getCampaignsAction(),
        getScreensAction(),
        getScreenGroupsAction()
      ]).then(toast.success(t`Reservation changed!`))
    })
  }

  deleteReservation = data => {
    const {
      deleteReservation: deleteReservationAction,
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction,
      getCampaigns: getCampaignsAction
    } = this.props

    deleteReservationAction(data).then(() => {
      getCampaignsAction()
      getScreensAction()
      getScreenGroupsAction()
    })
  }

  deleteCampaign = data => {
    const {
      deleteCampaign: deleteCampaignAction,
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction,
      getCampaigns: getCampaignsAction
    } = this.props

    deleteCampaignAction(data).then(() => {
      getCampaignsAction()
      getScreensAction()
      getScreenGroupsAction()
    })
  }

  onInputChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleDateChange = (key, value) => {
    this.setState({ [key]: value })
  }

  searchQuery = () => {
    const { getCampaigns: getCampaignsAction } = this.props
    const { search, searchFrom, searchTo } = this.state
    getCampaignsAction(
      null,
      search,
      this.getApiDate(searchFrom),
      this.getApiDate(searchTo)
    )
  }

  render() {
    const {
      campaign: { campaigns, isPending: campaignPending, error: campaignError },
      closeModal: closeModalAction,
      dialogs,
      customers: { customers },
      screens: { screens },
      screenGroups: { groups },
      reservations: { error: reservationsError, isPending: reservationPending }
    } = this.props
    const { search, searchFrom, searchTo } = this.state
    const items = groups.map(({ id, title }) => {
      const groupIdField = `g-${id}`

      const children = screens
        .filter(screen => screen.screen_group_id === id)
        .map(({ id: screenId, title: titleLabel }) => ({
          id: `s-${screenId}`,
          title: titleLabel
        }))

      return {
        id: groupIdField,
        title,
        children
      }
    })

    const { results, next, previous } = campaigns

    let modal = null

    if (dialogs.name === 'modifyReservation') {
      modal = (
        <EditReservationModal
          isOpen
          contentLabel={t`Modify reservation`}
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          options={items}
          customers={customers}
          action={this.editReservation}
          deleteAction={this.deleteReservation}
          reservation={dialogs.options.selectedReservation}
          error={reservationsError}
          pending={reservationPending}
          mode="campaign"
        />
      )
    } else if (dialogs.name === 'modifyCampaign') {
      modal = (
        <EditCampaignModal
          isOpen
          contentLabel={t`Modify campaign`}
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          action={this.editCampaign}
          campaign={dialogs.options.selectedCampaign}
          error={campaignError}
          pending={campaignPending}
        />
      )
    }

    return (
      <div>
        <NavBar />
        <Container>
          <Paper style={{ flexGrow: 1 }}>
            <Tabs
              value={0}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label={t`Campaigns`} />
            </Tabs>
            <SearchForm>
              <InputWithLabel
                style={{ display: 'inline-block' }}
                placeholder={t`Search for campaign title or customer name`}
                name="search"
                value={search}
                onChange={this.onInputChange}
                onKeyDown={e => e.key === 'Enter' && this.searchQuery()}
              />
              <DayPickerContainer
                id="starts_at"
                placeholder={t`Start Date`}
                format={UI_DATE_FORMAT}
                search={searchFrom}
                formatDate={formatDate}
                parseDate={parseDate}
                localeUtils={MomentLocaleUtils}
                dayPickerProps={{
                  format: { UI_DATE_FORMAT },
                  locale: moment().locale(),
                  localeUtils: MomentLocaleUtils
                }}
                onChange={this.onInputChange}
                onDayChange={(selectedDay, modifiers, dayPickerInput) => {
                  const input = dayPickerInput.getInput()
                  this.handleDateChange(
                    'searchFrom',
                    !input.value.trim() ? '' : dayPickerInput.state.value
                  )
                }}
              />
              <DayPickerContainer
                id="ends_at"
                placeholder={t`End Date`}
                format={UI_DATE_FORMAT}
                value={searchTo}
                formatDate={formatDate}
                parseDate={parseDate}
                localeUtils={MomentLocaleUtils}
                dayPickerProps={{
                  format: { UI_DATE_FORMAT },
                  locale: moment().locale(),
                  localeUtils: MomentLocaleUtils
                }}
                onChange={this.onInputChange}
                onDayChange={(selectedDay, modifiers, dayPickerInput) => {
                  const input = dayPickerInput.getInput()
                  this.handleDateChange(
                    'searchTo',
                    !input.value.trim() ? '' : dayPickerInput.state.value
                  )
                }}
              />
              <Button
                style={{ display: 'inline-block', width: '10%' }}
                onClick={this.searchQuery}
              >{t`Search`}</Button>
            </SearchForm>
            <TabContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t`Campaign`}</TableCell>
                    <TableCell>{t`Screen`}</TableCell>
                    <TableCell>{t`Status`}</TableCell>
                    <TableCell>{t`Starts`}</TableCell>
                    <TableCell>{t`Ends`}</TableCell>
                    <TableCell>{t`Slot duration`}</TableCell>
                    <TableCell>{t`Price`}</TableCell>
                    <TableCell>{t`Materials`}</TableCell>
                    <TableCell>{t`Edit`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results && results.length > 0 ? (
                    results.map(
                      ({
                        id,
                        title,
                        total_price: totalPrice,
                        reservations
                      }) => (
                        <>
                          <PartnerTableRow key={id}>
                            <TableCell component="th" scope="row" colSpan={6}>
                              <CampaignTitle>{title}</CampaignTitle>
                              {reservations &&
                                reservations.length > 0 &&
                                reservations[0].customer &&
                                reservations[0].customer.name}
                            </TableCell>
                            <TableCell component="th" scope="row" colSpan={2}>
                              {totalPrice}
                            </TableCell>
                            <TableCell>
                              {!reservations || reservations.length === 0 ? (
                                <Button
                                  size="small"
                                  background="#ff8db6"
                                  onClick={() => {
                                    this.deleteCampaign(id)
                                  }}
                                >
                                  {t`Delete`}
                                </Button>
                              ) : (
                                <Button
                                  size="small"
                                  background="#2E5063"
                                  onClick={() => {
                                    this.ModifyCampaignHandler(id)
                                  }}
                                >
                                  {t`Edit`}
                                </Button>
                              )}
                            </TableCell>
                          </PartnerTableRow>
                          {reservations &&
                            reservations.map(
                              ({
                                id: resId,
                                slot_duration_in_seconds: slot,
                                status,
                                starts_at: starts,
                                ends_at: ends,
                                screen: { name: screenName },
                                materials,
                                override_price: overridePrice,
                                calculated_price: calculatedPrice
                              }) => (
                                <TableRow key={resId}>
                                  <TableCell />
                                  <TableCell component="th" scope="row">
                                    {screenName}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {status}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {moment.utc(starts).format('D.M.YYYY')}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {moment.utc(ends).format('D.M.YYYY')}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {slot}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {overridePrice || calculatedPrice}{' '}
                                    {overridePrice
                                      ? `(${calculatedPrice})`
                                      : null}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <ul>
                                      {materials.map(({ name: mName, url }) => (
                                        <li>
                                          <a
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {mName}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      size="small"
                                      background="#44AB74"
                                      onClick={() => {
                                        this.ModifyReservationHandler(resId)
                                      }}
                                    >
                                      {t`Edit`}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                        </>
                      )
                    )
                  ) : (
                    <>
                      {!campaignPending && (
                        <Message>{t`No campaigns found.`}</Message>
                      )}
                    </>
                  )}
                  {campaignPending && (
                    <TableRow key="loading">
                      <TableCell component="th" scope="row" colSpan={3}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <PaginationContainer>
                {previous ? (
                  <Button
                    size="small"
                    background="#7698cb"
                    onClick={() => {
                      this.handlePrevious(previous)
                    }}
                  >
                    {t`Previous page`}
                  </Button>
                ) : null}
                {next ? (
                  <Button
                    size="small"
                    background="#7698cb"
                    onClick={() => {
                      this.handleNext(next)
                    }}
                  >
                    {t`Next page`}
                  </Button>
                ) : null}
              </PaginationContainer>
            </TabContainer>
          </Paper>
          {modal}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({
  auth,
  error,
  dialogs,
  campaign,
  customers,
  screens,
  reservations,
  screenGroups
}) => ({
  auth,
  error,
  dialogs,
  campaign,
  customers,
  screens,
  reservations,
  screenGroups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getScreens,
      getCampaigns,
      getScreenGroups,
      getCustomers,
      resetErrorMessage,
      openModal,
      closeModal,
      modifyCampaign,
      updateReservation,
      deleteReservation,
      changeStatus,
      deleteCampaign
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns)
