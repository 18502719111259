// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { bindActionCreators } from 'redux'
import { registerOrganization } from 'store/actions/auth'
import { resetErrorMessage } from 'store/actions/error'
import Input from 'components/FormElements/Inputs/FatInput/FatInput'

import Button from 'components/Button/Button'
import NavBar from 'components/Navbar/Navbar'
import LanguageSelector from '../components/LanguageSelector/LanguageSelector'

const Container = styled.div`
  // text-align: center;
  border-radius: 3px;
  background-color: #f2f4f9;
  margin: 0 auto;
  padding: 25px 50px;
  width: 450px;
`

const FormContainer = styled.div``

const Form = styled.form`
  margin: 50px 0;
`

const Submit = styled.button`
  width: 100%;
  height: 50px;
  padding: 0;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #56c2e5;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;

  :disabled {
    background: gray;
  }
`
class Signup extends Component<
  {
    registerOrganization: () => void,
    error: Array<Object>,
    resetErrorMessage: () => void,
    auth: Array<Object>
  },
  { username: string, password: string, redirectToReferrer: boolean }
> {
  fields = [
    { type: 'email', name: 'email', label: t`Email` },
    { type: 'password', name: 'password', label: t`Password` },
    { type: 'text', name: 'name', label: t`Organization name` },
    { type: 'text', name: 'business_id', label: t`Business ID` },
    { type: 'text', name: 'street_address', label: t`Street Address` },
    { type: 'text', name: 'municipality', label: t`Municipality` },
    { type: 'text', name: 'postal_code', label: t`Postal Code` },
    { type: 'text', name: 'phone_number', label: t`Phone Number` }
  ]

  constructor() {
    super()
    this.state = {
      forms: {}
    }
  }

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0].message) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  submit = e => {
    e.preventDefault()

    const { forms } = this.state
    const { registerOrganization: registerOrganizationAction } = this.props

    registerOrganizationAction(forms)
  }

  handleChange = e => {
    const { forms } = this.state
    this.setState({ forms: { ...forms, [e.target.name]: e.target.value } })
  }

  render() {
    const {
      auth: { isPending, error, loggedIn }
    } = this.props

    if (loggedIn)
      return (
        <Redirect
          to={{
            pathname: '/home'
          }}
        />
      )

    const inputs = this.fields.map(({ type, name, label }) => {
      const { messages } = error
      const fieldError = messages ? messages[name] : null

      return (
        <Input
          key={`${type}-${name}`}
          type={type}
          name={name}
          onChange={this.handleChange}
          error={fieldError}
          label={label}
        />
      )
    })

    return (
      <div>
        <NavBar />
        <Container>
          <FormContainer>
            <h1>{t`Signup`}</h1>
            <Form onSubmit={this.submit}>
              {inputs}
              <p>
                {t`By signing up I accept the doohmanager.com `}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.blip.fi/terms"
                >
                  {t`Terms of service`}
                </a>
              </p>
              <Submit type="submit" disabled={isPending}>
                {t`Sign up`}
              </Submit>
            </Form>

            <Button style={{ width: '100%' }} to="/login">
              {t`Already have an account? Login here`}
            </Button>
          </FormContainer>
          <LanguageSelector />
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, error }) => ({
  auth,
  error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerOrganization,
      resetErrorMessage
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
