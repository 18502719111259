// @flow

import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { bindActionCreators } from 'redux'

import { openModal, closeModal } from 'store/actions/dialogs'
import { getScreens } from 'store/actions/screens'
import {
  getPartnerships,
  createPartnership,
  deletePartnership
} from 'store/actions/partners'
import { getOrganization } from 'store/actions/organizations'
import { getCountries } from 'store/actions/screenAttributes'
import { resetErrorMessage } from 'store/actions/error'
import Button from 'components/Button/Button'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import LinearProgress from '@material-ui/core/LinearProgress'

import NavBar from 'components/Navbar/Navbar'
import AddPartnershipModal from 'components/modals/AddPartnership'

const Container = styled.div`
  margin: 50px 100px;
  padding: 25px 50px;
`

const TabContainer = styled.div`
  padding: 20px;
`
const PartnerTableRow = styled(TableRow)({
  background: 'rgb(217, 234, 255)'
})

class Partnerships extends Component<
  {
    screens: {
      screens: Array,
      isPending: boolean,
      error: Object,
      lastId: number
    },
    partners: {
      partnerships: Array,
      isPending: boolean,
      error: Object
    },
    screenAttributes: {
      countries: Array,
      isPending: boolean,
      error: Object
    },
    error: Array<Object>,
    resetErrorMessage: void,
    getScreens: void,
    getPartnerships: void,
    deletePartnership: void,
    createPartnership: void,
    getCountries: void,
    openModal: void,
    closeModal: void,
    dialogs: { name: string, options: Object }
  },
  {}
> {
  state = {
    value: 0
  }

  componentDidMount() {
    const {
      getScreens: getScreensAction,
      getPartnerships: getPartnershipsAction,
      getCountries: getCountriesAction
    } = this.props

    Promise.all([
      getScreensAction(),
      getPartnershipsAction(),
      getCountriesAction()
    ])
  }

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0].message) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  handlePartnershipDelete = id => {
    const {
      deletePartnership: deletePartnershipAction,
      getScreens: getScreensAction,
      getPartnerships: getPartnershipsAction
    } = this.props

    deletePartnershipAction(id)
      .then(() => getScreensAction())
      .then(() => getPartnershipsAction())
      .then(() => toast.success(t`Partnership deleted!`))
  }

  handleOpenAddPartnershipModal = partnerId => {
    const { openModal: action } = this.props
    action('addPartnership', { partnerId })
  }

  handlePartnershipEdit = id => {
    const { openModal: action } = this.props
    action('editPartnership', { id })
  }

  handlePartnershipAdd = data => {
    const { createPartnership: createPartnershipAction } = this.props
    const {
      partnerId,
      screenId,
      revenueShare,
      timeShare,
      canSell,
      canOperate
    } = data

    createPartnershipAction({
      organization_id: partnerId,
      screen_id: screenId,
      revenue_share: revenueShare,
      time_share: timeShare,
      can_sell: canSell,
      can_operate: canOperate
    }).then(partnerResponse => {
      if (partnerResponse.status && partnerResponse.status === 400) {
        toast.error(t`Creating partnership failed`)
        return null
      }
      toast.success(t`Partnership created`)
      return true
    })
  }

  render() {
    const {
      screens: { screens, isPending: screensPending },
      partners: { partnerships, isPending: partnersPending },
      closeModal: closeModalAction,
      dialogs,
      screenAttributes: { countries }
    } = this.props

    const { value } = this.state
    let items

    const {
      name: dialogName,
      options: { partnerId }
    } = dialogs

    if (partnerships.length > 0) {
      const arr = partnerships.map(p => p.organization.id)
      const s = new Set(arr)
      const unique = [...s]

      items = unique.map(id => ({
        id,
        name: partnerships.find(ps => ps.organization.id === id).organization
          .name,
        children: partnerships
          .filter(({ organization: { id: parentId } }) => parentId === id)
          .map(({ screen: { name }, ...rest }) => ({
            name,
            ...rest
          }))
      }))
    }
    let modal = null

    if (dialogName === 'addPartnership') {
      modal = (
        <AddPartnershipModal
          contentLabel={t`Add new screen to partnership`}
          isOpen
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          screens={screens}
          countries={countries}
          action={this.handlePartnershipAdd}
          partnerId={partnerId}
        />
      )
    }

    return (
      <div>
        <NavBar />
        <Container>
          <Paper style={{ flexGrow: 1 }}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label={t`Partnerships`} />
            </Tabs>
            <TabContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t`Partnership`}</TableCell>
                    <TableCell>{t`Screen`}</TableCell>
                    <TableCell>{t`Partner status`}</TableCell>
                    <TableCell>{t`Revenue share`}</TableCell>
                    <TableCell>{t`Time share`}</TableCell>
                    <TableCell>{t`Screen ID`}</TableCell>
                    <TableCell>{t`Remove/edit screen`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {screensPending || partnersPending || !items ? (
                    <TableRow key="loading">
                      <TableCell component="th" scope="row" colSpan={3}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    items.map(({ id, name, children }) => (
                      <>
                        <PartnerTableRow key={id}>
                          <TableCell component="th" scope="row" colSpan={6}>
                            {name}
                          </TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              background="#44AB74"
                              onClick={() => {
                                this.handleOpenAddPartnershipModal(id)
                              }}
                            >
                              {t`Link new screen`}
                            </Button>
                          </TableCell>
                        </PartnerTableRow>
                        {children &&
                          children.map(
                            ({
                              id: childId,
                              name: childTitle,
                              revenue_share: revShare,
                              time_share: timeShare,
                              can_operate: operate,
                              can_sell: sell
                            }) => (
                              <TableRow key={childId}>
                                <TableCell />
                                <TableCell component="th" scope="row">
                                  {childTitle}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {operate ? t`Operator ` : ''}
                                  {sell ? t`Seller` : ''}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {revShare}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {timeShare}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  #{childId}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    size="small"
                                    background="#ff8db6"
                                    onClick={() => {
                                      this.handlePartnershipDelete(childId)
                                    }}
                                  >
                                    {t`Remove`}
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </>
                    ))
                  )}
                </TableBody>
              </Table>
            </TabContainer>
          </Paper>
          {modal}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({
  auth,
  error,
  screens,
  partners,
  dialogs,
  screenAttributes
}) => ({
  auth,
  error,
  screens,
  partners,
  dialogs,
  screenAttributes
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getScreens,
      getPartnerships,
      createPartnership,
      deletePartnership,
      getOrganization,
      getCountries,
      resetErrorMessage,
      openModal,
      closeModal
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Partnerships)
