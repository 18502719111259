import React, { Component } from 'react'
import moment from 'moment'
import './CreateReservation.scss'
import styled from 'styled-components/macro'

import MomentLocaleUtils, {
  formatDate,
  parseDate
} from 'react-day-picker/moment'

import { t } from 'ttag'

import Select from 'react-select'
import ModalContent from 'components/modals/ModalContent/ModalContent'
import DayPicker from 'components/FormElements/Inputs/DayPicker/DayPicker'

import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'

import BlipModal from './BlipModal.js'

const ModalContainer = styled.div`
  padding: 15px;
`

const FormPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
`
const FormGroup = styled.div`
  display: flex;
`

const FormLabel = styled.label`
  margin: 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

const ErrorField = styled.span`
  padding: 5px;
`

const contactPersonList = (customers, customerId) => {
  const customerDetails = customers.find(c => c.id === customerId)
  if (customerDetails === undefined) {
    return []
  }

  return customerDetails.contact_persons.map(p => ({
    value: p.id,
    label: `${p.first_name} ${p.last_name}`
  }))
}

const selectedCustomer = (customers, customerId) => {
  const customer = customers.find(c => c.id === customerId)

  return customer !== undefined
    ? { value: customer.id, label: customer.name }
    : { value: '', label: '' }
}

const selectedScreens = (groupedOptions, selectedIds) => {
  const ids = selectedIds.map(id => `s-${id}`)
  const selection = []
  for (let i = 0; i < groupedOptions.length; i += 1) {
    const group = groupedOptions[i]
    const screens = group.children || []
    for (let j = 0; j < screens.length; j += 1) {
      if (ids.includes(screens[j].id)) {
        selection.push({ value: screens[j].id, label: screens[j].title })
      }
    }
  }
  return selection
}

export default class extends Component<
  {
    reservation: Array<Object>,
    options: Array,
    customers: Array<Object>,
    action: void,
    deleteAction: void,
    error: Array<Object>
  },
  {}
> {
  constructor(props) {
    super(props)

    const { reservation, options, customers } = props
    if (reservation) {
      const contactPersons = contactPersonList(
        customers,
        reservation.customer.id
      )

      const offset = moment(reservation.ends_at).utcOffset()

      this.state = {
        from: moment(reservation.starts_at).toDate(),
        to: moment(reservation.ends_at)
          .subtract(offset, 'minutes')
          .toDate(),
        screens: selectedScreens(options, [reservation.screen_id]),
        status: reservation.status,
        slot_duration_in_seconds: reservation.slot_duration_in_seconds,
        customer: selectedCustomer(customers, reservation.customer.id),
        contactPersons,
        contactPerson: contactPersons.find(
          c => c.value === reservation.contact_person_id
        )
      }
    } else {
      this.state = {
        from: undefined,
        to: undefined,
        screens: [],
        status: 'reserved',
        slot_duration_in_seconds: 60,
        customer: {
          value: '',
          label: ''
        },
        contactPersons: [],
        contactPerson: {
          value: '',
          label: ''
        }
      }
    }
  }

  showFromMonth = () => {
    const { from, to } = this.state
    if (!from) {
      return
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from)
    }
  }

  selectScreen = screens => {
    this.setState({ screens })
  }

  selectCustomer = customer => {
    this.setState((state, props) => {
      if (customer === state.customer) {
        return {}
      }

      const { customers } = props
      const contactPersons = contactPersonList(customers, customer.value)
      const contactPerson = contactPersons.length > 0 ? contactPersons[0] : ''

      return {
        customer,
        contactPersons,
        contactPerson
      }
    })
  }

  selectContactPerson = contactPerson => {
    this.setState({ contactPerson })
  }

  handleFromChange = from => {
    // Change the from date and focus the "to" input field
    this.setState({ from })
  }

  handleToChange = to => {
    this.setState({ to }, this.showFromMonth)
  }

  handleChange = e => {
    const { target } = e
    const value = e.type === 'checkbox' ? target.checked : target.value
    const { name } = target

    this.setState({
      [name]: value
    })
  }

  submitForm = () => {
    const { reservation, action } = this.props

    const {
      slot_duration_in_seconds: length,
      from,
      to,
      status,
      customer: { value: customerId },
      contactPerson: { value: contactPersonId },
      screens
    } = this.state

    const screenIds = screens.map(({ value }) => value.split('-').pop())
    const payload = {
      contact_person_id: contactPersonId,
      customer_id: customerId,
      starts_at: moment(from)
        .utc()
        .startOf('day')
        .toISOString(),
      ends_at: moment(to)
        .utc()
        .endOf('day')
        .toISOString(),
      screens: screenIds,
      slot_duration_in_seconds: length,
      status
    }

    if (reservation) {
      const { id } = reservation
      action({ id, ...payload })
    } else {
      action(payload)
    }
  }

  handleDelete = () => {
    const {
      deleteAction,
      reservation: { id }
    } = this.props

    deleteAction(id)
  }

  render() {
    const { error, reservation, options, customers, ...rest } = this.props
    const {
      from,
      to,
      slot_duration_in_seconds: length,
      screens,
      customer,
      contactPerson,
      contactPersons,
      status
    } = this.state
    const modifiers = { start: from, end: to }

    const groupedOptions = options.map(({ title: label, children }) => ({
      label,

      options: children.map(({ title: optionLabel, id: value }) => ({
        label: optionLabel,
        value
      }))
    }))

    return (
      <BlipModal
        onSubmit={this.submitForm}
        onDelete={reservation && this.handleDelete}
        {...rest}
      >
        <ModalContainer>
          <ModalContent title={t`Campaign information`}>
            <FormPanel>
              <FormGroup>
                <FormLabel htmlFor="starts_at">
                  {t`Starting date`}
                  <DayPicker
                    id="starts_at"
                    value={from}
                    placeholder={t`From`}
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    localeUtils={MomentLocaleUtils}
                    dayPickerProps={{
                      locale: moment().locale(),
                      localeUtils: MomentLocaleUtils,
                      selectedDays: [from, { from, to }],
                      disabledDays: { after: to },
                      toMonth: to,
                      modifiers,
                      numberOfMonths: 2,
                      onDayClick: () => this.to.getInput().focus()
                    }}
                    onDayChange={this.handleFromChange}
                    error={
                      error &&
                      Object.prototype.hasOwnProperty.call(error, 'starts_at')
                        ? error.starts_at[0].message
                        : false
                    }
                  />
                </FormLabel>
                <FormLabel htmlFor="ends_at">
                  {t`Ending date`}
                  {/*  <span className="InputFromTo-to"> */}
                  <DayPicker
                    id="ends_at"
                    ref={el => {
                      this.to = el
                    }}
                    value={to}
                    placeholder={t`To`}
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      locale: moment().locale(),
                      localeUtils: MomentLocaleUtils,
                      selectedDays: [from, { from, to }],
                      disabledDays: { before: from },
                      modifiers,
                      month: from,
                      fromMonth: from,
                      numberOfMonths: 2
                    }}
                    onDayChange={this.handleToChange}
                    error={
                      error &&
                      Object.prototype.hasOwnProperty.call(error, 'ends_at')
                        ? error.ends_at[0].message
                        : false
                    }
                  />
                  {/*  </span> */}
                </FormLabel>
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  <InputWithLabel
                    label={t`Spot duration`}
                    name="slot_duration_in_seconds"
                    type="number"
                    placeholder="0"
                    value={length}
                    onChange={this.handleChange}
                    error={
                      error &&
                      Object.prototype.hasOwnProperty.call(
                        error,
                        'slot_duration_in_seconds'
                      )
                        ? error.slot_duration_in_seconds[0].message
                        : false
                    }
                  />
                </FormLabel>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="status">
                  {t`Status`}
                  <select
                    id="status"
                    name="status"
                    onChange={this.handleChange}
                    value={status}
                    style={{
                      borderColor:
                        error &&
                        Object.prototype.hasOwnProperty.call(error, 'status')
                          ? 'red'
                          : '#ccc'
                    }}
                  >
                    <option value="reserved">{t`Preliminary`}</option>
                    <option value="confirmed">{t`Confirmed`}</option>
                    <option value="timed">{t`Content scheduled`}</option>
                    <option value="invoiced">{t`Invoiced`}</option>
                  </select>
                  {error &&
                  Object.prototype.hasOwnProperty.call(error, 'status')
                    ? error.status[0].message
                    : false}
                </FormLabel>
              </FormGroup>
            </FormPanel>
            <FormPanel>
              <FormGroup>
                <FormLabel>
                  {t`Screens`}
                  <Select
                    value={screens}
                    onChange={this.selectScreen}
                    options={groupedOptions}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                  {error &&
                  Object.prototype.hasOwnProperty.call(error, 'screen_ids')
                    ? error.screen_ids[0].message
                    : false}
                </FormLabel>
              </FormGroup>
            </FormPanel>
          </ModalContent>
        </ModalContainer>
        <ModalContainer>
          <ModalContent title={t`Customer`}>
            <FormPanel>
              <FormGroup>
                <FormLabel>
                  {t`Customer`}
                  <Select
                    onChange={this.selectCustomer}
                    options={customers.map(({ name: label, id: value }) => ({
                      label,
                      value
                    }))}
                    value={customer}
                    placeholder={t`Select customer`}
                    styles={{
                      control: styles => ({
                        ...styles,
                        borderColor:
                          error &&
                          Object.prototype.hasOwnProperty.call(
                            error,
                            'customer_id'
                          )
                            ? 'red'
                            : '#ccc'
                      })
                    }}
                  />
                  {error &&
                  Object.prototype.hasOwnProperty.call(error, 'customer_id') ? (
                    <ErrorField>{error.customer_id[0].message}</ErrorField>
                  ) : (
                    false
                  )}
                </FormLabel>
                <FormLabel>
                  {t`Contact person`}
                  <Select
                    onChange={this.selectContactPerson}
                    options={contactPersons}
                    isDisabled={contactPersons.length === 0}
                    placeholder={t`Select contact person`}
                    value={contactPerson}
                    styles={{
                      control: styles => ({
                        ...styles,
                        borderColor:
                          error &&
                          Object.prototype.hasOwnProperty.call(
                            error,
                            'contact_person_id'
                          )
                            ? 'red'
                            : '#ccc'
                      })
                    }}
                  />
                  {error &&
                  Object.prototype.hasOwnProperty.call(
                    error,
                    'contact_person_id'
                  ) ? (
                    <ErrorField>
                      {error.contact_person_id[0].message}
                    </ErrorField>
                  ) : (
                    false
                  )}
                </FormLabel>
              </FormGroup>
            </FormPanel>
          </ModalContent>
        </ModalContainer>
      </BlipModal>
    )
  }
}
