import { GET_CUSTOMERS, CREATE_CUSTOMER } from 'store/actions/customers'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  customers: [],
  isPending: false,
  error: null
}

export default typeToReducer(
  {
    [GET_CUSTOMERS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
          draft.error = null
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.customers = payload
          draft.isPending = false
          draft.error = null
        })
    },
    [CREATE_CUSTOMER]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
          draft.error = null
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.customers.push(payload)
          draft.isPending = false
          draft.error = null
        })
    }
  },
  initialState
)
