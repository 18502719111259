import React, { Component } from 'react'
import styled from 'styled-components/macro'
import ModalContent from 'components/modals/ModalContent/ModalContent'
import Button from 'components/Button/Button'
import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'
import { t } from 'ttag'
import BlipModal from './BlipModal.js'

const FormPanel = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  label,
  input {
    margin: 6px 12px 6px 0;
  }

  label {
    text-align: right;
  }

  input {
    // border: 1px solid #ccc;
    padding: 3px;
    border-radius: 3px;
    width: 100%;
  }
`
const ErrorField = styled.span`
  padding: 5px;
`
const ContactPersonList = styled.div`
  > *:not(:last-child) {
    padding-bottom: 6px;
    border-bottom: 1px solid #ccc;
  }
`
const Select = styled.select`
  box-sizing: border-box;
  height: 29px;
  width: 100%;
  border: 1px solid #bac0cd;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 8px;
`

const getFieldError = (error: Array, fieldName: string) =>
  error && Object.prototype.hasOwnProperty.call(error, fieldName)
    ? error[fieldName][0].message
    : false

const ContactPerson = (props: {
  idx: number,
  removeContactPerson: void,
  updateContactPerson: void,
  value: Array<{
    first_name: string,
    last_name: string,
    phone_number: string,
    email: string
  }>,
  error: Array
}) => {
  const {
    idx,
    removeContactPerson,
    updateContactPerson,
    value: {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email
    },
    error
  } = props

  return (
    <FormPanel>
      <InputWithLabel
        group="addcustomer"
        label={t`First name`}
        name="first_name"
        onChange={e => updateContactPerson(idx, 'first_name', e.target.value)}
        value={firstName || ''}
        error={getFieldError(error, 'first_name')}
      />
      <InputWithLabel
        group="addcustomer"
        label={t`Last name`}
        name="last_name"
        onChange={e => updateContactPerson(idx, 'last_name', e.target.value)}
        value={lastName || ''}
        error={getFieldError(error, 'last_name')}
      />
      <InputWithLabel
        group="addcustomer"
        label={t`Phone number`}
        name="phone_number"
        onChange={e => updateContactPerson(idx, 'phone_number', e.target.value)}
        value={phoneNumber || ''}
        error={getFieldError(error, 'phone_number')}
      />
      <InputWithLabel
        group="addcustomer"
        label={t`Email`}
        name="email"
        onChange={e => updateContactPerson(idx, 'email', e.target.value)}
        value={email || ''}
        error={getFieldError(error, 'email')}
      />
      <span>&nbsp;</span>
      <Button size="small" onClick={() => removeContactPerson(idx)}>
        {t`Remove`}
      </Button>
    </FormPanel>
  )
}

const InputFields = [
  { name: 'name', label: t`Name`, required: true },
  { name: 'business_id', label: t`Business ID`, required: true },
  { name: 'phone_number', label: t`Phone number`, required: true },
  { name: 'street_address', label: t`Street address`, required: true },
  { name: 'city', label: t`City`, required: true },
  { name: 'postcode', label: t`Postcode`, required: true },
  { name: 'country_id', label: t`Country`, type: 'select', required: true },
  { name: 'invoice_address', label: t`Invoicing address`, required: true },
  { name: 'invoice_email', label: t`Invoicing email` },
  { name: 'einvoice_address', label: t`Electronic Invoicing Address` },
  { name: 'einvoice_operator_code', label: t`Operator Code` }
]

export default class AddCustomer extends Component<
  { action: void, error: Array, countries: Array },
  {}
> {
  constructor() {
    super()

    this.state = {
      business_id: '',
      contact_persons: [],
      city: '',
      name: '',
      phone_number: '',
      postcode: '',
      street_address: '',
      organization_id: '',
      country_id: '',
      invoice_address: '',
      invoice_email: '',
      einvoice_address: '',
      einvoice_operator_code: ''
    }
  }

  onSubmit = () => {
    const { action } = this.props
    const payload = this.state
    payload.organization_id = 1
    action(payload)
  }

  onContactPersonRemove = idx => {
    this.setState(state => {
      const people = state.contact_persons

      return {
        ...state,
        contact_persons: [...people.slice(0, idx), ...people.slice(idx + 1)]
      }
    })
  }

  onContactPersonChange = (idx, key, value) => {
    this.setState(state => {
      const people = [...state.contact_persons]
      people[idx] = { ...people[idx], [key]: value }
      return { ...state, contact_persons: people }
    })
  }

  onInputChange = e => {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  onAddContactPerson = e => {
    e.preventDefault()
    this.setState(state => ({
      contact_persons: [...state.contact_persons, {}]
    }))
  }

  textInput(title, name) {
    const { [name]: value } = this.state

    return (
      <>
        <label htmlFor={`addcustomer-${name}`}>{title}:</label>
        <input
          id={`addcustomer-${name}`}
          name={name}
          type="text"
          onChange={this.onInputChange}
          value={value}
        />
      </>
    )
  }

  render() {
    const { contact_persons: contactPersons } = this.state

    const { error, countries, ...rest } = this.props

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <BlipModal {...rest} onSubmit={this.onSubmit}>
        <ModalContent title={t`Organization information`}>
          <FormPanel>
            {InputFields.map(
              ({ name: fieldName, label, type: fieldType, required }) => {
                const { [fieldName]: fieldValue } = this.state

                const elementError =
                  error &&
                  Object.prototype.hasOwnProperty.call(error, fieldName)
                    ? error[fieldName][0].message
                    : false

                return fieldType && fieldType === 'select' ? (
                  <>
                    <label htmlFor={fieldName}>
                      {label} {required ? ' *' : ''}
                    </label>
                    <div>
                      <Select
                        id={fieldName}
                        name={fieldName}
                        onChange={this.onInputChange}
                        value={fieldValue}
                        required={required}
                      >
                        <option value={null}>{t`Valitse`}</option>
                        {countries.map(({ value, label: country }) => (
                          <option key={value} value={value}>
                            {country}
                          </option>
                        ))}
                      </Select>

                      {elementError && <ErrorField>{elementError}</ErrorField>}
                    </div>
                  </>
                ) : (
                  <InputWithLabel
                    key={fieldName}
                    group="addcustomer"
                    label={label}
                    name={fieldName}
                    onChange={this.onInputChange}
                    value={fieldValue}
                    error={elementError}
                    required={required}
                  />
                )
              }
            )}
          </FormPanel>
        </ModalContent>
        <ModalContent title={t`Contact persons`}>
          <ContactPersonList>
            {contactPersons.map((p, idx) => (
              <ContactPerson
                // eslint-disable-next-line
                key={idx}
                idx={idx}
                value={p}
                updateContactPerson={this.onContactPersonChange}
                removeContactPerson={this.onContactPersonRemove}
                error={
                  error &&
                  Object.prototype.hasOwnProperty.call(error, 'contact_persons')
                    ? error.contact_persons[0]
                    : false
                }
              />
            ))}
            <p style={{ textAlign: 'right' }}>
              <Button size="small" onClick={this.onAddContactPerson}>
                {t`Add`}
              </Button>
            </p>
          </ContactPersonList>
        </ModalContent>
      </BlipModal>
    )
  }
}
