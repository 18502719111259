import React from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as Open } from 'assets/open.svg'
import { ReactComponent as Collapse } from 'assets/collapse.svg'

const Button = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  width: 28px;
  height: 28px;
  /*  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 28px 28px;
  border-color: transparent transparent #d8d8d8 transparent;
 */
  border: 0;
  transition: all 0.2s;
  cursor: pointer;
  border-style: inset;
  background-color: transparent;
  padding: 0;
`
export default ({ collapse }: { collapse: boolean }) => (
  <Button>{collapse ? <Open /> : <Collapse />}</Button>
)
