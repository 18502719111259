import { GET_WIDGET, CREATE_WIDGET, UPDATE_WIDGET } from 'store/actions/widget'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  widget: [],
  isPending: false,
  error: null
}

export default typeToReducer(
  {
    [GET_WIDGET]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
          draft.error = null
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.widget = payload
          draft.isPending = false
          draft.error = null
        })
    },
    [CREATE_WIDGET]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
          draft.error = null
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.widget = payload
          draft.isPending = false
          draft.error = null
        })
    },
    [UPDATE_WIDGET]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.widget = payload
          draft.isPending = false
          draft.error = null
        })
    }
  },
  initialState
)
