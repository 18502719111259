import {
  SEARCH_ORGANIZATION,
  CREATE_ORGANIZATION,
  GET_ORGANIZATION
} from 'store/actions/organizations'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  organization: [],
  organizations: [],
  isPending: false,
  error: null
}

export default typeToReducer(
  {
    [GET_ORGANIZATION]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
          draft.error = null
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.organizations = payload
          draft.isPending = false
          draft.error = null
        })
    },
    [SEARCH_ORGANIZATION]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
          draft.error = null
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.organizations = payload
          draft.isPending = false
          draft.error = null
        })
    },
    [CREATE_ORGANIZATION]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
          draft.error = null
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.organization = payload
          draft.isPending = false
          draft.error = null
        })
    }
  },
  initialState
)
