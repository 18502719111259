// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import ModalContent from 'components/modals/ModalContent/ModalContent'
import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'
import { t } from 'ttag'
import Select from 'react-select'
import Button from 'components/Button/Button'
import BlipModal from './BlipModal.js'

const FormPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  label,
  input {
    margin: 6px 12px 6px 0;
  }

  label {
    text-align: right;
  }

  input {
    padding: 3px;
    border-radius: 3px;
  }
  .btn {
    grid-column-start: 2;
  }
`

const InputFields = [
  { name: 'name', label: t`Name` },
  { name: 'email', label: t`Contact person email` },
  { name: 'businessId', label: t`Business ID` },
  { name: 'phoneNumber', label: t`Phone number` },
  { name: 'streetAddress', label: t`Street address` },
  { name: 'city', label: t`City` },
  { name: 'postcode', label: t`Postal code` }
]

export default class AddPartnership extends Component<
  {
    screens: Array<Object>,
    action: void,
    error: Array,
    countries: Array<Object>,
    searchOrganization: void,
    createOrganizationAction: void,
    createPartnershipAction: void,
    partnerId: void,
    organizations: Array
  },
  {}
> {
  state = {
    name: '',
    businessId: '',
    phoneNumber: '',
    streetAddress: '',
    city: '',
    postcode: '',
    countryId: '',
    email: '',
    screenId: '',
    revenueShare: 0,
    timeShare: 0,
    canSell: false,
    canOperate: false,
    disabled: false
  }

  componentDidUpdate(previousProps) {
    const { organizations } = this.props
    if (previousProps.organizations !== organizations) {
      const {
        name,
        phone_number: phoneNumber,
        street_address: streetAddress,
        city,
        postcode,
        country_id: countryId,
        email
      } = organizations
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        name,
        phoneNumber,
        streetAddress,
        city,
        postcode,
        countryId,
        email,
        disabled: true
      })
    }
  }

  onSubmit = () => {
    const { action, partnerId } = this.props
    if (partnerId) {
      const {
        screenId,
        revenueShare,
        timeShare,
        canSell,
        canOperate
      } = this.state

      action({
        partnerId,
        screenId,
        revenueShare,
        timeShare,
        canSell,
        canOperate
      })
      return null
    }
    const {
      name,
      businessId,
      phoneNumber,
      streetAddress,
      city,
      postcode,
      email,
      countryId,
      screenId,
      revenueShare,
      timeShare,
      canSell,
      canOperate
    } = this.state

    action({
      name,
      businessId,
      phoneNumber,
      streetAddress,
      city,
      postcode,
      countryId,
      email,
      screenId,
      revenueShare,
      timeShare,
      canSell,
      canOperate
    })
    return null
  }

  onInputChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  onBooleanCheckboxChange = e => {
    const { name, checked } = e.target
    this.setState({ [name]: checked })
  }

  onSelectChange = selectedValue => {
    const { value } = selectedValue
    this.setState({
      screenId: value
    })
  }

  onCountryChange = selectedValue => {
    const { value } = selectedValue
    this.setState({
      countryId: value
    })
  }

  onSearchBusinessClick = () => {
    const { businessId } = this.state
    const { searchOrganization } = this.props
    searchOrganization(businessId)
  }

  render() {
    const { revenueShare, timeShare, countryId, disabled } = this.state
    const { screens, countries, error, partnerId, ...rest } = this.props
    const screenOptions = screens.map(screen => {
      const { id, title } = screen
      return { value: id, label: title }
    })
    const selectedCountry = countries.find(({ value }) => value === countryId)
    return (
      <BlipModal {...rest} onSubmit={this.onSubmit}>
        {!partnerId ? (
          <ModalContent title={t`Partner organization information`}>
            <FormPanel>
              {InputFields.map(({ name: fieldName, label }) => {
                const { [fieldName]: fieldValue } = this.state

                const elementError =
                  error &&
                  Object.prototype.hasOwnProperty.call(error, fieldName)
                    ? error[fieldName][0].message
                    : false

                return fieldName === 'businessId' ? (
                  <>
                    <InputWithLabel
                      key={fieldName}
                      group="addpartneship"
                      label={label}
                      name={fieldName}
                      onChange={this.onInputChange}
                      value={fieldValue}
                      error={elementError}
                    />
                    <Button
                      onClick={this.onSearchBusinessClick}
                      size="xsmall"
                      className="btn"
                    >{t`Search partner by Business ID`}</Button>
                  </>
                ) : (
                  <InputWithLabel
                    key={fieldName}
                    group="addpartneship"
                    label={label}
                    name={fieldName}
                    onChange={this.onInputChange}
                    value={fieldValue}
                    error={elementError}
                    disabled={disabled}
                  />
                )
              })}
              <label htmlFor="country">{t`Country *`}</label>
              <Select
                key="country"
                id="country"
                options={countries}
                value={selectedCountry}
                onChange={this.onCountryChange}
                required
                isDisabled={disabled}
              />
            </FormPanel>
          </ModalContent>
        ) : (
          ''
        )}
        <ModalContent title={t`Screen`}>
          <FormPanel>
            <label htmlFor="selectedScreen">{t`Select screen *`}</label>
            <Select
              key="selectedScreen"
              id="selectedScreen"
              options={screenOptions}
              onChange={this.onSelectChange}
              required
            />
            <InputWithLabel
              key="revenueShare"
              label="Revenue share %"
              name="revenueShare"
              onChange={this.onInputChange}
              value={revenueShare}
              required
            />
            <InputWithLabel
              key="timeShare"
              label="Time share %"
              name="timeShare"
              onChange={this.onInputChange}
              value={timeShare}
              required
            />
            <InputWithLabel
              key="canSell"
              type="checkbox"
              name="canSell"
              value
              label="Sales partner"
              onChange={this.onBooleanCheckboxChange}
            />
            <InputWithLabel
              key="canOperate"
              type="checkbox"
              name="canOperate"
              value
              label="Operator partner"
              onChange={this.onBooleanCheckboxChange}
            />
          </FormPanel>
        </ModalContent>
      </BlipModal>
    )
  }
}
