// @flow
import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { login, userRead } from 'store/actions/auth'
import { resetErrorMessage } from 'store/actions/error'
import { t } from 'ttag'

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    auth: { tokenExpired },
    userRead: userReadAction
  } = rest

  let isAuthenticated = false

  const [userData, setUserData] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const token = sessionStorage.getItem('API_TOKEN')

  if (token !== null) {
    isAuthenticated = true
  }

  useEffect(() => {
    userReadAction().then(userResponse => {
      if (userResponse.status && userResponse.status === 401) {
        setUserData(true)
        setRedirect(true)
        return null
      }
      if (userResponse.value && userResponse.value.organization) {
        const {
          value: { organization }
        } = userResponse
        if (organization) {
          setUserData(true)
        } else {
          setRedirect(true)
          setUserData(true)
        }
      } else {
        setRedirect(true)
        setUserData(true)
      }
      return null
    })
  }, [userReadAction])

  if (tokenExpired) {
    sessionStorage.removeItem('API_TOKEN')
    setRedirect(true)
  }

  return !userData ? (
    <>
      <h5>{t`Loading...`}</h5>
    </>
  ) : (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={
        props =>
          !isAuthenticated || redirect ? (
            <Redirect
              to={{
                pathname: '/login',
                // eslint-disable-next-line react/prop-types
                state: { from: props.location }
              }}
            />
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Component {...props} />
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  )
}

// export default PrivateRoute

const mapStateToProps = ({ auth, error }) => ({
  auth,
  error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      userRead,
      resetErrorMessage
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
