import React from 'react'
import { useTable } from 'react-table'
import styled from 'styled-components/macro'
import { t } from 'ttag'

const CartTable = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      color: #2b2e38;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #e1e4ed;
      text-align: left;
      :last-child {
        border-right: 0;
      }
    }
    td {
      color: #464850;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #e1e4ed;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const TableContainer = ({ data }: { data: any }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: t`Kampajaan valitut näytöt`,
        accessor: 'col1' // accessor is the "key" in the data
      },
      {
        Header: t`Alkaa`,
        accessor: 'col2'
      },
      {
        Header: t`Päättyy`,
        accessor: 'col3'
      },
      {
        Header: t`Spotin kesto`,
        accessor: 'col4'
      },
      {
        Header: t`Saavuttavuus`,
        accessor: 'col5'
      },
      {
        Header: t`Hinta`,
        accessor: 'col6'
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  })

  return (
    <CartTable className={`cart-table `}>
      <table {...getTableProps()}>
        <thead>
          {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
              headerGroup.headers.map(column => (
                // Apply the header cell props
                <th {...column.getHeaderProps()}>
                  {// Render the header
                  column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
          rows.map(row => {
            // Prepare the row for display
            prepareRow(row)
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {// Loop over the rows cells
                row.cells.map(cell => (
                  // Apply the cell props
                  <td {...cell.getCellProps()}>
                    {// Render the cell contents
                    cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </CartTable>
  )
}

export default TableContainer
