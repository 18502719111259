import { Get } from 'api/index'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>

// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_SALES_REPORT = 'GET_SALES_REPORT'

/*
export const getReservations = (
  params: Array<{
    availability_from_date: string,
    availability_to_date: string
  }> = {}
): ThunkAction => dispatch => {
  const url = `/api/reservations/`

  return dispatch({
    type: GET_RESERVATIONS,
    payload: Get(url, params)
  })
}
 */
export const getSalesReport = (
  params: Array<{
    range_start: string,
    range_end: string
  }> = {}
): ThunkAction => dispatch => {
  const url = `/api/reports/sales/`

  return dispatch({
    type: GET_SALES_REPORT,
    payload: Get(url, params)
  })
}
