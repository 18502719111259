// @flow
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import DataGrid from 'react-data-grid'
import NavBar from 'components/Navbar/Navbar'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { t } from 'ttag'
import styled from 'styled-components'
import 'components/DataGrid/datagrid.css'
import moment from 'moment'
import { getSalesReport } from 'store/actions/reports'
import { bindActionCreators } from 'redux'
import { resetErrorMessage } from 'store/actions/error'
import { connect } from 'react-redux'
import { closeModal, openModal } from 'store/actions/dialogs'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import { formatDate, parseDate } from 'react-day-picker/moment'
import TabPanel from 'components/TabPanel'
import SalesGraph from 'components/SalesGraph'
import { toast } from 'react-toastify'

const Container = styled.div`
  margin: 50px 10px;
  padding: 25px 10px;
`

const TabContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 60vh;
`

const StyledToolBar = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const DATE_FORMAT = 'D.M.YYYY'
const MONTH_FORMAT = 'M.YYYY'

interface SummaryRow {
  totalCount: number;
}

interface Row {
  id: number;
  title: string;
}

function getColumns() {
  return [
    {
      key: 'date',
      name: t`Date`,
      frozen: true,
      resizable: true,
      summaryFormatter() {
        return <>{t`Yhteensä`}</>
      }
    },
    {
      key: 'screen',
      name: t`Screen`,
      width: 120,
      frozen: true,
      resizable: true
    },
    {
      key: 'city',
      name: t`City`,
      width: 60,
      frozen: true,
      resizable: true
    },
    {
      key: 'sales',
      name: t`Sales`,
      width: 60,
      frozen: true,
      summaryFormatter({ row: { salesCount } }) {
        return <>{`${salesCount.toFixed(2)}`}</>
      }
    },
    {
      key: 'salesCommission',
      name: t`Sales commission`,
      frozen: true,
      resizable: true,
      summaryFormatter({ row: { salesCommissionCount } }) {
        return <>{`${salesCommissionCount.toFixed(2)}`}</>
      }
    },
    {
      key: 'commission',
      name: t`Commission`,
      width: 100,
      frozen: true,
      resizable: true
    },
    {
      key: 'profit',
      name: t`Profit`,
      width: 60,
      frozen: true,
      resizable: true,
      summaryFormatter({ row: { salesCount } }) {
        return <>{`${salesCount.toFixed(2)}`}</>
      }
    },
    {
      key: 'screenOwner',
      name: t`Screen owner`,
      frozen: true,
      resizable: true
    },
    {
      key: 'salesChannel',
      name: t`Sales Channel`,
      frozen: true,
      resizable: true
    },
    {
      key: 'seller',
      name: t`Seller`,
      frozen: true,
      resizable: true
    },
    {
      key: 'campaign',
      name: t`Campaign`,
      frozen: true,
      resizable: true
    },
    {
      key: 'campaignId',
      name: t`Campaign ID`,
      width: 30,
      frozen: true,
      resizable: true
    },
    {
      key: 'spot',
      name: t`Spot (s)`,
      width: 30,
      frozen: true,
      resizable: true
    },
    {
      key: 'duration',
      name: t`Duration (days)`,
      width: 30,
      frozen: true,
      resizable: true
    }
  ]
}

function rowKeyGetter(row: Row) {
  return row.campaignId
}

function createRows(props) {
  const {
    reports: { sales: salesData }
  } = props

  if (!salesData) {
    return []
  }
  const items = []

  salesData.map(row => {
    const {
      id: campaignId,
      starts_at: startsAt,
      screen: { name: screen, city, organization: screenOwner },
      price: sales,
      revenue_share_total: salesCommission,
      revenue_share: commission,
      profit,
      channel: salesChannel,
      seller: { organization: seller },
      title: campaign,
      slot_duration_in_seconds: spot,
      duration_in_days: duration
    } = row
    const date = moment(startsAt).format(DATE_FORMAT)
    items.push({
      date,
      screen,
      city,
      sales,
      salesCommission,
      commission,
      profit,
      screenOwner,
      salesChannel,
      seller,
      campaign,
      campaignId,
      spot,
      duration
    })
    return null
  })
  return items
}

type Props = {
  getSalesReport: void
}

const SalesReport = (props: Props) => {
  const { getSalesReport: getSalesReportAction } = props

  const [from, setFrom] = useState(
    moment()
      .startOf('day')
      .add(-4, 'w')
      .toDate()
  )

  const [to, setTo] = useState(
    moment()
      .startOf('day')
      .toDate()
  )

  const [graphData, setGraphData] = React.useState([])

  const handleFromChange = fromDay => {
    setFrom(fromDay)
  }

  const handleToChange = toDay => {
    setTo(toDay)
  }

  const getGraphData = (fromDay, toDay, salesData) => {
    if (!salesData) {
      return []
    }
    const startDate = moment(fromDay)
    const endDate = moment(toDay)
    const months =
      endDate.diff(startDate, 'months') === 0
        ? 1
        : endDate.diff(startDate, 'months')
    const data = []
    const dataByDate = []
    salesData.forEach(row => {
      const date = moment(row.starts_at).format(MONTH_FORMAT)
      const prevData = []
      if (dataByDate[date]) {
        prevData.sales = dataByDate[date][t`Sales`]
        prevData.profit = dataByDate[date][t`Profit`]
      }
      dataByDate[date] = {
        name: date,
        [t`Sales`]: parseFloat(row.price) + parseFloat(prevData.sales ?? 0),
        [t`Profit`]: parseFloat(row.profit) + parseFloat(prevData.profit ?? 0)
      }
    })
    const addData = (date, d) => {
      data.push({
        name: date,
        [t`Sales`]: d ? parseFloat(d[t`Sales`].toFixed(2)) : 0,
        [t`Profit`]: d ? parseFloat(d[t`Profit`].toFixed(2)) : 0
      })
    }
    const formattedStartDate = startDate.format(MONTH_FORMAT)
    addData(formattedStartDate, dataByDate[formattedStartDate])
    for (let month = 0; month < months; month += 1) {
      const date = startDate.add(1, 'months').format(MONTH_FORMAT)
      const d = dataByDate[date]
      addData(date, d)
    }
    return data
  }

  useEffect(() => {
    const params = {
      range_start: moment(from).toISOString(),
      range_end: moment(to).toISOString()
    }
    getSalesReportAction(params)
      .then(response => {
        const salesData = response.value
        if (salesData.length > 0) {
          setGraphData(getGraphData(from, to, salesData))
        }
      })
      .catch(() => {
        toast.error(t`There was a problem fetching report data.`)
      })
  }, [getSalesReportAction, from, to])

  const rows = createRows(props)

  const salesRows = useMemo(
    () => [...new Set(rows.map(r => r.title))].sort(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const columns = useMemo(() => getColumns(salesRows), [salesRows])

  const summaryRows = useMemo(() => {
    const summaryRow: SummaryRow = {
      salesCount: rows.reduce((a, b) => +a + +b.sales, 0),
      salesCommissionCount: rows.reduce((a, b) => +a + +b.salesCommission, 0),
      profitCount: rows.reduce((a, b) => +a + +b.profit, 0)
    }
    return [summaryRow]
  }, [rows])

  const [sortColumns, setSortColumns] = useState([])

  const textCompare = (sc, a, b) => {
    const v1 = a[sc] ?? ''
    const v2 = b[sc] ?? ''
    return v1.localeCompare(v2)
  }

  const numberCompare = (sc, a, b) => a[sc] - b[sc]

  const getComparator = useCallback(sortColumn => {
    switch (sortColumn) {
      case 'screen':
      case 'city':
      case 'screenOwner':
      case 'salesChannel':
      case 'seller':
      case 'campaign':
        return (a, b) => textCompare(sortColumn, a, b)
      case 'sales':
      case 'salesCommission':
      case 'commission':
      case 'profit':
      case 'campaignId':
      case 'spot':
      case 'duration':
        return (a, b) => numberCompare(sortColumn, a, b)
      case 'date':
        return (a, b) => {
          const date1 = moment(a[sortColumn], DATE_FORMAT, true).toDate()
          const date2 = moment(b[sortColumn], DATE_FORMAT, true).toDate()
          return date1 - date2
        }
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`)
    }
  }, [])

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return rows

    return [...rows].sort((a, b) => {
      // eslint-disable-next-line
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey)
        const compResult = comparator(a, b)
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult
        }
      }
      return 0
    })
  }, [getComparator, rows, sortColumns])

  const modifiers = { from, to }

  const a11yProps = index => {
    const id = `wrapped-tab-${index}`
    return { id, 'aria-controls': `wrapped-tabpanel-${index}` }
  }
  const [tabIndex, setTabIndex] = React.useState(0)

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex)
  }

  const dataKeys = [
    {
      type: 'monotone',
      dataKey: t`Sales`,
      color: '#7698CB'
    },
    {
      type: 'monotone',
      dataKey: t`Profit`,
      color: '#6AD59B'
    }
  ]

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div>
      <NavBar />
      <Container>
        <Paper style={{ flexGrow: 1 }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={t`Sales summary`} {...a11yProps(0)} />
            <Tab label={t`Sales graph`} {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <TabContainer>
              <StyledToolBar>
                <DayPickerInput
                  value={from}
                  placeholder="From"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { after: to },
                    toMonth: to,
                    modifiers,
                    numberOfMonths: 2
                  }}
                  onDayChange={handleFromChange}
                />
                <DayPickerInput
                  value={to}
                  placeholder="To"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 2
                  }}
                  onDayChange={handleToChange}
                />
              </StyledToolBar>
              <DataGrid
                rowKeyGetter={rowKeyGetter}
                columns={columns}
                rows={sortedRows}
                defaultColumnOptions={{
                  sortable: true,
                  resizable: true
                }}
                onSortColumnsChange={setSortColumns}
                sortColumns={sortColumns}
                summaryRows={summaryRows}
                className="rdg-light fill-grid"
              />
            </TabContainer>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <TabContainer>
              <StyledToolBar>
                <DayPickerInput
                  value={from}
                  placeholder="From"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { after: to },
                    toMonth: to,
                    modifiers,
                    numberOfMonths: 2
                  }}
                  onDayChange={handleFromChange}
                />
                <DayPickerInput
                  value={to}
                  placeholder="To"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 2
                  }}
                  onDayChange={handleToChange}
                />
              </StyledToolBar>
              <SalesGraph
                key="graphData"
                data={graphData}
                dataKeys={dataKeys}
              />
            </TabContainer>
          </TabPanel>
        </Paper>
      </Container>
    </div>
  )
}
/* eslint-enable react/jsx-props-no-spreading */

const mapStateToProps = ({ auth, error, dialogs, reports }) => ({
  auth,
  error,
  dialogs,
  reports
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSalesReport,
      resetErrorMessage,
      openModal,
      closeModal
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport)
