/* eslint-disable react/no-did-update-set-state */

import React, { Component } from 'react'

import styled from 'styled-components/macro'
import Creatable from 'react-select/creatable'
import Select from 'react-select'

import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'
import TextAreaWithLabel from 'components/FormElements/Inputs/TextAreaWithLabel/TextAreaWithLabel'
import { t } from 'ttag'
import Accordion from 'components/Accordion/Accordion'
import BlipModal from './BlipModal.js'

const FormPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
`
const FormPanelContent = styled.div`
  label,
  input {
    margin: 2px 12px 2px 0;
  }

  label {
    text-align: right;
    font-size: 10px;
  }

  input {
    border: 1px solid #ccc;
    padding: 2px;
  }
`

const customStyles = {
  option: provided => ({
    ...provided,
    padding: '4px 9px',
    color: '#464850',
    fontSize: '12px',
    lineHeight: '14px'
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #DDDDDD',
    borderRadius: '4px',
    color: '#464850',
    fontSize: '12px',
    lineHeight: '14px',
    minHeight: '28px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition }
  }
}

class AddScreen extends Component<
  {
    screenGroups: Array<Object>,
    action: void,
    createScreenGroupAction: void,
    error: Array,
    screen: Array<Object>,
    uploadImageAction: void,
    lastId: void,
    tags: Array<Object>,
    mediaSites: Array<Object>,
    ageGroups: Array<Object>,
    adFormats: Array<Object>,
    countries: Array<Object>,
    currencies: Array<Object>,
    timezones: Array<Object>,
    industryRestrictions: Array<Object>
  },
  {}
> {
  state = {
    name: '',
    playerId: '',
    description: '',
    city: '',
    postcode: '',
    latitude: '',
    longitude: '',
    countAs: '',
    minDuration: '',
    maxDuration: '',
    resolutionWidth: '',
    resolutionHeight: '',
    width: '',
    height: '',
    contactPrice: '',
    otcPerDay: '',
    tagIds: [],
    ageGroupIds: [],
    adFormatIds: [],
    industryRestrictionIds: [],
    openTime: '',
    closeTime: '',
    dwellTime: '',
    defaultSlotLength: '',
    screenGroup: {
      value: '',
      label: ''
    },
    selectedAgeGroups: [],
    selectedTags: [],
    selectedCurrency: [],
    selectedCountry: [],
    selectedTimeZone: [],
    selectedEnvironment: [],
    selectedMediaSite: [],
    selectedAdFormats: [],
    selectedIndustryRestrictions: [],
    accordionOpen: 1,
    selectedFile: ''
  }

  constructor(props) {
    super(props)

    const {
      screen,
      screenGroups,
      tags,
      mediaSites,
      ageGroups,
      adFormats,
      countries,
      currencies,
      timezones,
      industryRestrictions
    } = props

    if (screen) {
      const {
        tag_ids: tagIds,
        age_group_ids: ageGroupIds,
        currency_id: currencyId,
        country_id: countryId,
        timezone,
        environment,
        media_site_id: mediaSiteId,
        ad_format_ids: adFormatIds,
        industry_restriction_ids: industryRestrictionIds,
        title: name,
        player_id: playerId,
        description,
        loop_duration_in_seconds: loopDurationInSeconds,
        city,
        postcode,
        location: { latitude, longitude },
        count_as: countAs,
        min_duration: minDuration,
        max_duration: maxDuration,
        resolution_width: resolutionWidth,
        resolution_height: resolutionHeight,
        width,
        height,
        contact_price: contactPrice,
        otc_per_day: otcPerDay,
        open_time: openTime,
        close_time: closeTime,
        dwell_time: dwellTime,
        default_slot_length: defaultSlotLength
      } = screen

      const environments = [
        { value: 'indoor', label: t`Indoor` },
        { value: 'outdoor', label: t`Outdoor` }
      ]

      const sg = screenGroups.find(c => c.id === screen.screen_group_id)

      const selectedTags = tags.filter(tag => tagIds.includes(tag.value))
      const selectedAgeGroups = ageGroups.filter(group =>
        ageGroupIds.includes(group.value)
      )
      const selectedAdFormats = adFormats.filter(format =>
        adFormatIds.includes(format.value)
      )
      const selectedIndustryRestrictions = industryRestrictions.filter(
        restriction => industryRestrictionIds.includes(restriction.value)
      )

      const sc = currencies.find(c => c.id === currencyId)
      const selectedCountry = countries.find(c => c.value === countryId)
      const selectedTimeZone = timezones.find(c => c.value === timezone)
      const selectedEnvironment = environments.find(
        c => c.value === environment
      )
      const selectedMediaSite = mediaSites.find(c => c.value === mediaSiteId)

      this.state = {
        name,
        playerId,
        description,
        loopDurationInSeconds,
        city,
        postcode,
        latitude,
        longitude,
        countAs,
        minDuration,
        maxDuration,
        resolutionWidth,
        resolutionHeight,
        width,
        height,
        contactPrice,
        otcPerDay,
        openTime,
        closeTime,
        dwellTime,
        defaultSlotLength,
        screenGroup: {
          value: sg.id,
          label: sg.title
        },
        tagIds,
        ageGroupIds,
        adFormatIds,
        industryRestrictionIds,
        selectedTags,
        selectedAgeGroups,
        selectedCurrency: [{ value: sc.id, label: sc.name }],
        selectedCountry: [selectedCountry],
        selectedTimeZone: [selectedTimeZone],
        selectedEnvironment: [selectedEnvironment],
        selectedMediaSite: [selectedMediaSite],
        selectedAdFormats,
        selectedIndustryRestrictions,
        accordionOpen: 1,
        selectedFile: ''
      }
    }
  }

  componentDidUpdate() {
    const { screenGroups } = this.props
    const { _screenGroupName } = this.state
    if (_screenGroupName) {
      const sg = screenGroups.find(g => g.title === _screenGroupName)
      if (sg !== undefined) {
        this.setState({
          screenGroup: { value: sg.id, label: sg.title },
          _screenGroupName: null
        })
      }
    }
  }

  onSubmit = () => {
    const { screen, action } = this.props
    const {
      name,
      playerId,
      description,
      loopDurationInSeconds,
      selectedCountry,
      city,
      postcode,
      latitude,
      longitude,
      selectedEnvironment,
      countAs,
      selectedMediaSite,
      minDuration,
      maxDuration,
      selectedTimezone,
      resolutionWidth,
      resolutionHeight,
      width,
      height,
      contactPrice,
      otcPerDay,
      tagIds,
      ageGroupIds,
      adFormatIds,
      industryRestrictionIds,
      openTime,
      closeTime,
      dwellTime,
      defaultSlotLength,
      selectedCurrency,
      screenGroup: { value: screenGroupId },
      selectedFile
    } = this.state

    const payload = {
      name,
      player_id: playerId,
      description,
      loop_duration_in_seconds: loopDurationInSeconds,
      country_id: selectedCountry[0] ? selectedCountry[0].value : '',
      city,
      postcode,
      location: { latitude, longitude },
      environment: selectedEnvironment[0] ? selectedEnvironment[0].value : '',
      count_as: countAs,
      media_site_id: selectedMediaSite[0] ? selectedMediaSite[0].value : '',
      min_duration: minDuration,
      max_duration: maxDuration,
      timezone: selectedTimezone,
      resolution_width: resolutionWidth,
      resolution_height: resolutionHeight,
      width,
      height,
      contact_price: parseFloat(contactPrice.replace(/,/, '.')),
      otc_per_day: otcPerDay,
      tag_ids: tagIds,
      age_group_ids: ageGroupIds,
      ad_format_ids: adFormatIds,
      industry_restriction_ids: industryRestrictionIds,
      open_time: openTime,
      close_time: closeTime,
      dwell_time: dwellTime,
      default_slot_length: defaultSlotLength,
      currency_id: selectedCurrency[0] ? selectedCurrency[0].value : '',
      screen_group_id: screenGroupId
    }

    if (screen) {
      const { id } = screen
      action({ id, payload, selectedFile })
    } else {
      action(payload, selectedFile)
    }
  }

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] })
  }

  onInputChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  onScreenGroupChange = newValue => {
    this.setState({ screenGroup: newValue })
  }

  onAdFormatChange = selectedValue => {
    const selectedAdFormats = []
    const selectedAdFormatIds = []
    if (selectedValue) {
      selectedValue.map(selected => {
        const { value, label } = selected
        selectedAdFormats.push({ value, label })
        selectedAdFormatIds.push(value)
        return null
      })
    }
    this.setState({
      adFormatIds: selectedAdFormatIds,
      selectedAdFormats
    })
  }

  onTagChange = selectedValue => {
    const selectedTags = []
    const selectedTagIds = []
    if (selectedValue) {
      selectedValue.map(selected => {
        const { value, label } = selected
        selectedTags.push({ value, label })
        selectedTagIds.push(value)
        return null
      })
    }
    this.setState({
      tagIds: selectedTagIds,
      selectedTags
    })
  }

  onAgeGroupChange = selectedValue => {
    const selectedAgeGroups = []
    const selectedAgeGroupIds = []
    if (selectedValue) {
      selectedValue.map(selected => {
        const { value, label } = selected
        selectedAgeGroups.push({ value, label })
        selectedAgeGroupIds.push(value)
        return null
      })
    }
    this.setState({
      ageGroupIds: selectedAgeGroupIds,
      selectedAgeGroups
    })
  }

  onIndustryRestrictionsChange = selectedValue => {
    const selectedIndustryRestrictions = []
    const selectedIndustryRestrictionIds = []
    if (selectedValue) {
      selectedValue.map(selected => {
        const { value, label } = selected
        selectedIndustryRestrictions.push({ value, label })
        selectedIndustryRestrictionIds.push(value)
        return null
      })
    }
    this.setState({
      industryRestrictionIds: selectedIndustryRestrictionIds,
      selectedIndustryRestrictions
    })
  }

  onCurrencyChange = selectedValue => {
    const { value, label } = selectedValue
    this.setState({
      selectedCurrency: [{ value, label }]
    })
  }

  onCountryChange = selectedValue => {
    const { value, label } = selectedValue
    this.setState({
      selectedCountry: [{ value, label }]
    })
  }

  onMediaSiteChange = selectedValue => {
    const { value, label } = selectedValue
    this.setState({
      selectedMediaSite: [{ value, label }]
    })
  }

  onEnvironmentChange = selectedValue => {
    const { value, label } = selectedValue
    this.setState({
      selectedEnvironment: [{ value, label }]
    })
  }

  onTimezoneChange = selectedValue => {
    const { value, label } = selectedValue
    this.setState({
      selectedTimeZone: [{ value, label }]
    })
  }

  onScreenGroupCreate = name => {
    const { createScreenGroupAction } = this.props
    this.setState({ _screenGroupName: name })
    createScreenGroupAction(name)
  }

  handleAccordionOpen = id => {
    this.setState({ accordionOpen: id })
  }

  basicFields = (tags, screenGroups, ageGroups) => {
    const sgOptions = screenGroups.map(sg => ({
      value: sg.id,
      label: sg.title
    }))
    const { selectedTags, selectedAgeGroups } = this.state
    return [
      {
        name: 'name',
        label: t`Name`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'playerId',
        label: t`Player ID`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'description',
        label: t`Description`,
        type: 'textarea',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'countAs',
        label: t`Count as X screens`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'tags',
        label: t`Tags`,
        options: tags,
        defaultValue: selectedTags,
        type: 'select',
        multi: true,
        onChange: this.onTagChange,
        required: false
      },
      {
        name: 'ageGroups',
        label: t`Age groups`,
        options: ageGroups,
        defaultValue: selectedAgeGroups,
        type: 'select',
        multi: true,
        onChange: this.onAgeGroupChange,
        required: false
      },
      {
        name: 'screenGroup',
        label: t`Screen group`,
        options: sgOptions,
        type: 'creatable',
        onChange: this.onScreenGroupChange,
        required: true
      }
    ]
  }

  locationFields = (countries, mediaSites, environmentOptions, timezones) => {
    const {
      selectedCountry,
      selectedTimeZone,
      selectedEnvironment,
      selectedMediaSite
    } = this.state

    return [
      {
        name: 'country',
        label: t`Country`,
        type: 'select',
        options: countries,
        defaultValue: selectedCountry,
        multi: false,
        onChange: this.onCountryChange,
        required: true
      },
      {
        name: 'city',
        label: t`City`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'postcode',
        label: t`Postal code`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'latitude',
        label: t`Latitude`,
        type: 'input',
        onChange: this.onInputChange
      },
      {
        name: 'longitude',
        label: t`Longitude`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'timezone',
        label: t`Time zone`,
        options: timezones,
        defaultValue: selectedTimeZone,
        type: 'select',
        multi: false,
        onChange: this.onTimezoneChange,
        required: true
      },
      {
        name: 'environment',
        label: t`Environment`,
        options: environmentOptions,
        defaultValue: selectedEnvironment,
        type: 'select',
        multi: false,
        onChange: this.onEnvironmentChange,
        required: true
      },
      {
        name: 'mediaSite',
        label: t`Media site`,
        options: mediaSites,
        defaultValue: selectedMediaSite,
        type: 'select',
        multi: false,
        onChange: this.onMediaSiteChange,
        required: true
      }
    ]
  }

  timingFields = () => [
    {
      name: 'loopDurationInSeconds',
      label: t`Loop duration (in seconds)`,
      type: 'input',
      onChange: this.onInputChange,
      required: true
    },
    {
      name: 'minDuration',
      label: t`Min duration`,
      type: 'input',
      onChange: this.onInputChange,
      required: true
    },
    {
      name: 'maxDuration',
      label: t`Max duration`,
      type: 'input',
      onChange: this.onInputChange,
      required: true
    },
    {
      name: 'otcPerDay',
      label: t`OTC contacts per day`,
      type: 'input',
      onChange: this.onInputChange,
      required: true
    },
    {
      name: 'dwellTime',
      label: t`Dwell time`,
      type: 'input',
      onChange: this.onInputChange,
      required: true
    },
    {
      name: 'defaultSlotLength',
      label: t`Default slot length`,
      type: 'input',
      onChange: this.onInputChange,
      required: true
    },
    {
      name: 'openTime',
      label: t`Open time`,
      type: 'input',
      onChange: this.onInputChange,
      required: true
    },
    {
      name: 'closeTime',
      label: t`Close time`,
      type: 'input',
      onChange: this.onInputChange,
      required: true
    }
  ]

  pricingFields = currencies => {
    const currencyOptions = currencies.map(cur => ({
      value: cur.id,
      label: cur.name
    }))

    const { selectedCurrency } = this.state

    return [
      {
        name: 'contactPrice',
        label: t`Contact price`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'currency',
        label: t`Currency`,
        type: 'select',
        multi: false,
        options: currencyOptions,
        defaultValue: selectedCurrency,
        onChange: this.onCurrencyChange,
        required: true
      }
    ]
  }

  technicalFields = (adFormats, industryRestrictions) => {
    const { selectedAdFormats, selectedIndustryRestrictions } = this.state
    return [
      {
        name: 'resolutionWidth',
        label: t`Resolution width`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'resolutionHeight',
        label: t`Resolution height`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'width',
        label: t`Width`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'height',
        label: t`Height`,
        type: 'input',
        onChange: this.onInputChange,
        required: true
      },
      {
        name: 'adFormats',
        label: t`Ad formats`,
        type: 'select',
        options: adFormats,
        defaultValue: selectedAdFormats,
        multi: true,
        onChange: this.onAdFormatChange,
        required: true
      },
      {
        name: 'industryRestrictions',
        label: t`Industry restrictions`,
        type: 'select',
        options: industryRestrictions,
        defaultValue: selectedIndustryRestrictions,
        multi: true,
        onChange: this.onIndustryRestrictionsChange,
        required: false
      }
    ]
  }

  textInput(title, name) {
    const { [name]: value } = this.state

    return (
      <>
        <label htmlFor={`addscreen-${name}`}>{title}:</label>
        <input
          id={`addscreen-${name}`}
          name={name}
          type="text"
          onChange={this.onInputChange}
          value={value}
        />
      </>
    )
  }

  renderField = (
    type,
    fieldName,
    label,
    fieldValue,
    elementError,
    options,
    multi,
    onChange,
    required,
    defaultValue
  ) => {
    if (type === 'input') {
      return (
        <InputWithLabel
          id={fieldName}
          key={fieldName}
          label={label}
          name={fieldName}
          onChange={onChange}
          value={fieldValue}
          error={elementError}
          required={required}
        />
      )
    }
    if (type === 'textarea') {
      return (
        <TextAreaWithLabel
          id={fieldName}
          key={fieldName}
          label={label}
          name={fieldName}
          onChange={onChange}
          value={fieldValue}
          error={elementError}
          required={required}
        />
      )
    }
    if (type === 'select') {
      return (
        <>
          <label htmlFor={fieldName}>
            {label} {required ? ' *' : ''}
          </label>
          <Select
            key={fieldName + label}
            id={fieldName}
            options={options.map(g => ({
              value: g.value,
              label: g.label
            }))}
            onChange={onChange}
            styles={customStyles}
            isMulti={multi}
            required={required}
            value={defaultValue.map(g => ({
              value: g.value,
              label: g.label
            }))}
          />
        </>
      )
    }
    if (type === 'creatable') {
      return (
        <>
          <label htmlFor={fieldName}>
            {label} {required ? ' *' : ''}
          </label>
          <Creatable
            key={fieldName}
            id={fieldName}
            options={options.map(g => ({
              value: g.value,
              label: g.label
            }))}
            value={fieldValue}
            onChange={this.onScreenGroupChange}
            onCreateOption={this.onScreenGroupCreate}
            styles={customStyles}
            required={required}
          />
        </>
      )
    }
    return null
  }

  render() {
    const {
      screenGroups,
      error,
      tags,
      mediaSites,
      ageGroups,
      adFormats,
      countries,
      currencies,
      industryRestrictions,
      timezones
    } = this.props

    const { accordionOpen } = this.state

    const environmentOptions = [
      { value: 'indoor', label: t`Indoor` },
      { value: 'outdoor', label: t`Outdoor` }
    ]

    const basicFields = this.basicFields(tags, screenGroups, ageGroups)
    const locationFields = this.locationFields(
      countries,
      mediaSites,
      environmentOptions,
      timezones
    )
    const timingFields = this.timingFields()
    const pricingFields = this.pricingFields(currencies)
    const technicalFields = this.technicalFields(
      adFormats,
      industryRestrictions
    )
    return (
      <BlipModal {...this.props} onSubmit={this.onSubmit}>
        <Accordion
          title={t`1. Basic information`}
          key={1}
          id={1}
          open={accordionOpen === 1}
          handleAccordionOpen={this.handleAccordionOpen}
        >
          <FormPanel>
            <FormPanelContent>
              {basicFields.map(
                ({
                  name: fieldName,
                  label,
                  type,
                  options,
                  multi,
                  onChange,
                  required,
                  defaultValue
                }) => {
                  const { [fieldName]: fieldValue } = this.state
                  const elementError =
                    error &&
                    Object.prototype.hasOwnProperty.call(error, fieldName)
                      ? error[fieldName][0].message
                      : false
                  return this.renderField(
                    type,
                    fieldName,
                    label,
                    fieldValue,
                    elementError,
                    options,
                    multi,
                    onChange,
                    required,
                    defaultValue
                  )
                }
              )}
              {pricingFields.map(
                ({
                  name: fieldName,
                  label,
                  type,
                  options,
                  multi,
                  onChange,
                  required,
                  defaultValue
                }) => {
                  const { [fieldName]: fieldValue } = this.state

                  const elementError =
                    error &&
                    Object.prototype.hasOwnProperty.call(error, fieldName)
                      ? error[fieldName][0].message
                      : false

                  return this.renderField(
                    type,
                    fieldName,
                    label,
                    fieldValue,
                    elementError,
                    options,
                    multi,
                    onChange,
                    required,
                    defaultValue
                  )
                }
              )}
            </FormPanelContent>
            <FormPanelContent>
              {locationFields.map(
                ({
                  name: fieldName,
                  label,
                  type,
                  options,
                  multi,
                  onChange,
                  required,
                  defaultValue
                }) => {
                  const { [fieldName]: fieldValue } = this.state

                  const elementError =
                    error &&
                    Object.prototype.hasOwnProperty.call(error, fieldName)
                      ? error[fieldName][0].message
                      : false

                  return this.renderField(
                    type,
                    fieldName,
                    label,
                    fieldValue,
                    elementError,
                    options,
                    multi,
                    onChange,
                    required,
                    defaultValue
                  )
                }
              )}
            </FormPanelContent>
          </FormPanel>
        </Accordion>
        <Accordion
          title={t`2. Technical information`}
          key={2}
          id={2}
          open={accordionOpen === 2}
          handleAccordionOpen={this.handleAccordionOpen}
        >
          <FormPanel>
            <FormPanelContent>
              {timingFields.map(
                ({
                  name: fieldName,
                  label,
                  type,
                  options,
                  multi,
                  onChange,
                  required,
                  defaultValue
                }) => {
                  const { [fieldName]: fieldValue } = this.state

                  const elementError =
                    error &&
                    Object.prototype.hasOwnProperty.call(error, fieldName)
                      ? error[fieldName][0].message
                      : false

                  return this.renderField(
                    type,
                    fieldName,
                    label,
                    fieldValue,
                    elementError,
                    options,
                    multi,
                    onChange,
                    required,
                    defaultValue
                  )
                }
              )}
            </FormPanelContent>
            <FormPanelContent>
              {technicalFields.map(
                ({
                  name: fieldName,
                  label,
                  type,
                  options,
                  multi,
                  onChange,
                  required,
                  defaultValue
                }) => {
                  const { [fieldName]: fieldValue } = this.state

                  const elementError =
                    error &&
                    Object.prototype.hasOwnProperty.call(error, fieldName)
                      ? error[fieldName][0].message
                      : false

                  return this.renderField(
                    type,
                    fieldName,
                    label,
                    fieldValue,
                    elementError,
                    options,
                    multi,
                    onChange,
                    required,
                    defaultValue
                  )
                }
              )}
            </FormPanelContent>
          </FormPanel>
        </Accordion>
        <Accordion
          title={t`3. Add image of the screen`}
          key={3}
          id={3}
          open={accordionOpen === 3}
          handleAccordionOpen={this.handleAccordionOpen}
        >
          <FormPanel>
            <FormPanelContent>
              <div>
                <input
                  type="file"
                  id="file"
                  onChange={this.onFileChange}
                  required
                />
              </div>
            </FormPanelContent>
          </FormPanel>
        </Accordion>
      </BlipModal>
    )
  }
}

export default AddScreen
