import { Get } from 'api/index'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>

// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_MEDIA_SITES = 'GET_MEDIA_SITES'
export const GET_AGE_GROUPS = 'GET_AGE_GROUPS'
export const GET_TAGS = 'GET_TAGS'
export const GET_AD_FORMATS = 'GET_AD_FORMATS'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_CURRENCIES = 'GET_CURRENCIES'
export const GET_INDUSTRY_RESTRICTIONS = 'GET_INDUSTRY_RESTRICTIONS'
export const GET_TIMEZONES = 'GET_TIMEZONES'

export const getMediaSites = (id: number): ThunkAction => dispatch => {
  const url = `/api/media_sites?widget=${id}`
  return dispatch({
    type: GET_MEDIA_SITES,
    payload: Get(url)
  })
}

export const getAgeGroups = (id: number): ThunkAction => dispatch => {
  const url = `/api/age_groups?widget=${id}`
  return dispatch({
    type: GET_AGE_GROUPS,
    payload: Get(url)
  })
}

export const getTags = (): ThunkAction => dispatch => {
  const url = `/api/tags/`
  return dispatch({
    type: GET_TAGS,
    payload: Get(url)
  })
}

export const getAdFormats = (id: number): ThunkAction => dispatch => {
  const url = `/api/ad_formats?widget=${id}`
  return dispatch({
    type: GET_AD_FORMATS,
    payload: Get(url)
  })
}

export const getCountries = (id: number): ThunkAction => dispatch => {
  const url = `/api/countries?widget=${id}`
  return dispatch({
    type: GET_COUNTRIES,
    payload: Get(url)
  })
}

export const getCurrencies = (id: number): ThunkAction => dispatch => {
  const url = `/api/currencies?widget=${id}`
  return dispatch({
    type: GET_CURRENCIES,
    payload: Get(url)
  })
}

export const getIndustryRestrictions = (
  id: number
): ThunkAction => dispatch => {
  const url = `/api/industry_restrictions?widget=${id}`
  return dispatch({
    type: GET_INDUSTRY_RESTRICTIONS,
    payload: Get(url)
  })
}

export const getTimezones = (): ThunkAction => dispatch => {
  const url = `/api/timezones/`
  return dispatch({
    type: GET_TIMEZONES,
    payload: Get(url)
  })
}
