import { addLocale, useLocale } from 'ttag'
import * as cookie from './cookie'

const LOCALE_COOKIE = '__locale'
const DEFAULT_LOCALE = 'en'

/**
 * Return current locale
 *
 * If locale is not saved to cookie check user browsers language.
 *
 * NOTE !!! Code is currently optimized to support only english and finnish.
 *
 * @returns {*}
 */
export function getLocale() {
  const urlParams = new URLSearchParams(window.location.search)
  const lang = urlParams.get('lang')
  const languages = ['fi', 'en']
  if (lang && languages.includes(lang)) {
    return lang
  }

  const locale = cookie.get(LOCALE_COOKIE)
  if (typeof locale !== 'undefined') {
    return locale
  }

  if (window.navigator.language === 'fi-FI') {
    return 'fi'
  }
  return DEFAULT_LOCALE
}

export function saveLocale(locale) {
  cookie.set(LOCALE_COOKIE, locale)
}

const currentLocale = getLocale()

// eslint-disable-next-line
const translationObj = require(`../i18n/${currentLocale}.po.json`)
addLocale(currentLocale, translationObj)
useLocale(currentLocale)

if (currentLocale !== DEFAULT_LOCALE) {
  /* Dynamically import correct locale for moment. Add more complex selection
   * logic here when more options are available.
   *
   * This changes language of the timeline component. */
  // eslint-disable-next-line
  require('moment/locale/fi')
}
