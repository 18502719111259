import React, { Node } from 'react'
import styled from 'styled-components/macro'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Container = styled.div`
  margin: 15px 10px;
`

export default ({ children }: { children: Node }) => (
  <Container>
    {children}
    <ToastContainer position="top-center" />
  </Container>
)
