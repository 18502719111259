import { Get, Post, Delete, Patch } from 'api/index'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>
// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_SCREEN_GROUPS = 'GET_SCREEN_GROUPS'
export const CREATE_SCREEN_GROUP = 'CREATE_SCREEN_GROUP'
export const GET_SCREEN_GROUP = 'GET_SCREEN_GROUP'
export const DELETE_SCREEN_GROUP = 'DELETE_SCREEN_GROUP'
export const UPDATE_SCREEN_GROUP = 'UPDATE_SCREEN_GROUP'

export const getScreenGroups = (
  params: Array<{
    availability_from_date: string,
    availability_to_date: string
  }> = {}
): ThunkAction => dispatch => {
  const url = `/api/screen_groups/`

  return dispatch({
    type: GET_SCREEN_GROUPS,
    payload: Get(url, params)
  })
}

export const createScreenGroup = (name: string): ThunkAction => dispatch => {
  const url = `/api/screen_groups/`

  return dispatch({
    type: CREATE_SCREEN_GROUP,
    payload: Post(url, JSON.stringify({ name }))
  })
}

export const getScreenGroup = (id: number): ThunkAction => dispatch => {
  const url = `/api/screen_groups/${id}/`

  return dispatch({
    type: GET_SCREEN_GROUP,
    payload: Get(url)
  })
}

export const deleteScreenGroup = (id: number): ThunkAction => dispatch => {
  const url = `/api/screen_groups/${id}/`

  return dispatch({
    type: DELETE_SCREEN_GROUP,
    payload: Delete(url)
  })
}

export const updateScreenGroup = (
  body: Array<Object> = null
): ThunkAction => dispatch => {
  const { id, ...rest } = body
  const url = `/api/screen_groups/${id}/`

  return dispatch({
    type: UPDATE_SCREEN_GROUP,
    payload: Patch(url, JSON.stringify(rest))
  })
}
