// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { bindActionCreators } from 'redux'
import { getWidget, createWidget, updateWidget } from 'store/actions/widget'
import { resetErrorMessage } from 'store/actions/error'
import FatInput from 'components/FormElements/Inputs/FatInput/FatInput'
import Button from 'components/Button/Button'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import LinearProgress from '@material-ui/core/LinearProgress'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import NavBar from 'components/Navbar/Navbar'

const Container = styled.div`
  // text-align: center;
  // border-radius: 3px;
  // background-color: #f2f4f9;
  margin: 50px 100px;
  padding: 25px 50px;
`

const Logo = styled.img`
  max-height: 50px;
  padding-right: 20px;
`
const CodeContainer = styled.div`
  padding: 40px;
  border: 1px solid grey;
  background: #eaecec;
  border-radius: 4px;
`
const TabContainer = styled.div`
  padding: 20px;
`

class WidgetManagement extends Component<
  {
    isPending: void,
    error: Array<Object>,
    resetErrorMessage: void,
    getWidget: void,
    createWidget: void,
    updateWidget: void
  },
  {}
> {
  state = {
    value: 0,
    title: '',
    accentColor: '',
    logo: null,
    newLogo: null,
    active: false
  }

  componentDidMount() {
    const { getWidget: getWidgetAction } = this.props
    getWidgetAction().then(response => {
      if (response.action && response.action.payload) {
        const {
          id,
          title,
          accent_color: accentColor,
          logo,
          active
        } = response.action.payload[0]
        this.setState({
          id,
          title,
          accentColor,
          logo,
          active
        })
      }
    })
  }

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0].message) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  handleChange = e => {
    const { name, value } = e.currentTarget
    this.setState({ [name]: value })
  }

  handleCheckbox = e => {
    const { value, checked } = e.currentTarget

    this.setState({ [value]: checked })
  }

  changeTab = (event, value) => {
    this.setState({ value })
  }

  onFileChange = event => {
    this.setState({ newLogo: event.target.files[0] })
  }

  handleCreate = () => {
    const { createWidget: createWidgetAction } = this.props
    const { title, accentColor, newLogo, active } = this.state

    const formData = new FormData()
    formData.append('title', title)
    formData.append('accent_color', accentColor)
    formData.append('active', active)
    if (newLogo) formData.append('logo', newLogo)

    createWidgetAction(formData).then(() => toast.success(t`Widget created!`))
  }

  handleUpdate = id => {
    const { updateWidget: updateWidgetAction } = this.props
    const { title, accentColor, newLogo, active } = this.state

    const formData = new FormData()
    formData.append('title', title)
    formData.append('accent_color', accentColor)
    formData.append('active', active)
    if (newLogo) formData.append('logo', newLogo)

    updateWidgetAction(id, formData).then(() => toast.success(t`User updated!`))
  }

  render() {
    const { isPending: widgetPending } = this.props
    const { value, id, title, accentColor, logo, active } = this.state

    return (
      <div>
        <NavBar />
        <Container>
          <Paper style={{ flexGrow: 1 }}>
            <Tabs
              value={value}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label={t`Widget management`} />
            </Tabs>
            <TabContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t`Widget title`}</TableCell>
                    <TableCell>{t`Accent color in hex`}</TableCell>
                    <TableCell>{t`Logo`}</TableCell>
                    <TableCell>{t`Active`}</TableCell>
                    <TableCell>{t`Save`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {widgetPending ? (
                    <TableRow key="loading">
                      <TableCell component="th" scope="row" colSpan={3}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={id}>
                      <TableCell component="th" scope="row">
                        <FatInput
                          size="slim"
                          type="text"
                          name="title"
                          id="title"
                          placeholder="Slogan etc"
                          onChange={this.handleChange}
                          value={title}
                          required
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <FatInput
                          size="slim"
                          type="text"
                          name="accentColor"
                          id="accentColor"
                          placeholder="#000000"
                          onChange={this.handleChange}
                          value={accentColor}
                        />
                      </TableCell>
                      <TableCell>
                        {logo ? <Logo src={logo} alt="logo" /> : null}
                        <input
                          type="file"
                          id="logo"
                          name="logo"
                          onChange={this.onFileChange}
                        />
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="active"
                              checked={active}
                              onChange={this.handleCheckbox}
                            />
                          }
                          label={t`Active`}
                        />
                      </TableCell>
                      <TableCell>
                        {!id ? (
                          <Button
                            size="small"
                            background="#44AB74"
                            onClick={() => {
                              this.handleCreate()
                            }}
                          >
                            Create
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            background="#44AB74"
                            onClick={() => {
                              this.handleUpdate(id)
                            }}
                          >
                            Update
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <CodeContainer>
                <code>{`<script src="https://doohmanager.com/api/widgets/${id}/loader"></script>`}</code>
              </CodeContainer>
              <div>
                <p>{t`Copy the code to the page's source that you want the widget to be shown.`}</p>
              </div>
            </TabContainer>
          </Paper>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, error, widget }) => ({
  auth,
  error,
  widget
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWidget,
      createWidget,
      updateWidget,
      resetErrorMessage
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WidgetManagement)
