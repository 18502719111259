import { Get, Post, Put, Delete } from 'api/index'
import moment from 'moment'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>
// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_RESERVATIONS = 'GET_RESERVATIONS'
export const CREATE_RESERVATION = 'CREATE_RESERVATION'
export const UPDATE_RESERVATION = 'UPDATE_RESERVATION'
export const GET_RESERVATION = 'GET_RESERVATION'
export const DELETE_RESERVATION = 'DELETE_RESERVATION'
export const EXPORT_RESERVATIONS = 'EXPORT_RESERVATIONS'

export const getReservations = (
  params: Array<{
    availability_from_date: string,
    availability_to_date: string
  }> = {}
): ThunkAction => dispatch => {
  const url = `/api/reservations/`

  // Todo: refactor "Home" component and correct these there
    const reservationRangeParams  = {
      range_start: moment(params.availability_from_date).format("YYYY-MM-DD[T]00:00:00.000[Z]"),
      range_end: moment(params.availability_to_date).format("YYYY-MM-DD[T]23:59:59.999[Z]")
  };

  return dispatch({
    type: GET_RESERVATIONS,
    payload: Get(url, reservationRangeParams)
  })
}

export const exportReservations = (): ThunkAction => dispatch => {
  const url = `/api/reservations/export`

  return dispatch({
    type: EXPORT_RESERVATIONS,
    payload: Get(url)
  })
}

export const createReservation = (
  body: Array<{
    contact_person_id: number,
    customer_id: number,
    ends_at: string,
    screens: Array<Number>,
    slot_duration_in_seconds: number,
    starts_at: string,
    status: 'reserved' | 'confirmed' | 'timed' | 'invoiced'
  }>
): ThunkAction => dispatch => {
  const url = `/api/reservations/`

  return dispatch({
    type: CREATE_RESERVATION,
    payload: Post(url, JSON.stringify(body))
  })
}

export const updateReservation = (
  body: Array<{
    id: number,
    contact_person_id: number,
    customer_id: number,
    ends_at: string,
    screens: Array<Number>,
    slot_duration_in_seconds: number,
    starts_at: string,
    status: 'reserved' | 'confirmed' | 'timed' | 'invoiced',
    override_price?: number
  }>
): ThunkAction => dispatch => {
  const { id } = body
  const url = `/api/reservations/${id}/`

  return dispatch({
    type: UPDATE_RESERVATION,
    payload: Put(url, JSON.stringify(body))
  })
}

export const getReservation = (
  id: number,
  params: Array<{
    availability_from_date: string,
    availability_to_date: string
  }> = {}
): ThunkAction => dispatch => {
  const url = `/api/reservations/${id}/`

  return dispatch({
    type: GET_RESERVATION,
    payload: Get(url, { params })
  })
}

export const deleteReservation = (id: number): ThunkAction => dispatch => {
  const url = `/api/reservations/${id}/`

  return dispatch({
    type: DELETE_RESERVATION,
    payload: Delete(url),
    meta: {
      id
    }
  })
}
