import { Get, Post, Delete, Patch } from 'api/index'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>

// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_SCREENS = 'GET_SCREENS'
export const CREATE_SCREEN = 'CREATE_SCREEN'
export const GET_SINGLE_SCREEN = 'GET_SINGLE_SCREEN'
export const UPDATE_SCREEN = 'UPDATE_SCREEN'
export const DELETE_SCREEN = 'DELETE_SCREEN'
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'

export const getScreens = (
  params: Array<{
    availability_from_date: string,
    availability_to_date: string
  }> = {}
): ThunkAction => dispatch => {
  const url = `/api/screens/`

  return dispatch({
    type: GET_SCREENS,
    payload: Get(url, params)
  })
}

export const createScreen = (
  body: Array<{
    location?: Array<{
      latitude: string,
      longitude: string
    }>,
    loop_duartion_in_seconds?: number,
    municipality: string,
    name: string,
    screen_group_id: number
  }>
): ThunkAction => dispatch => {
  const url = `/api/screens/`

  return dispatch({
    type: CREATE_SCREEN,
    payload: Post(url, JSON.stringify(body))
  })
}

export const getScreen = (
  id: number,
  availability?: Array<{
    availabilityStart: string,
    availabilityStop: string
  }> = null
): ThunkAction => dispatch => {
  const url = `/api/screens/${id}/`

  return dispatch({
    type: GET_SINGLE_SCREEN,
    payload: Post(url, availability)
  })
}

export const deleteScreen = (id: number): ThunkAction => dispatch => {
  const url = `/api/screens/${id}/`

  return dispatch({
    type: DELETE_SCREEN,
    payload: Delete(url)
  })
}

export const updateScreen = (
  id: number,
  body: Array<Object> = null
): ThunkAction => dispatch => {
  const url = `/api/screens/${id}/`
  return dispatch({
    type: UPDATE_SCREEN,
    payload: Patch(url, JSON.stringify(body))
  })
}

export const uploadImage = (
  id: number,
  formData: Object
): ThunkAction => dispatch => {
  const url = `/api/screens/${id}/upload_image/`

  return dispatch({
    type: UPLOAD_IMAGE,
    payload: Post(url, formData, true)
  })
}
