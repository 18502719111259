// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { getCart, confirmCart } from 'store/actions/cart'
import { usersWidgetCreate } from 'store/actions/users'
import { resetErrorMessage } from 'store/actions/error'
import { getCountries } from 'store/actions/screenAttributes'
import { login, resetPassword } from 'store/actions/auth'

import Button from 'components/Button/Button'
import TableContainer from 'components/TableContainer/TableContainer'

import { t } from 'ttag'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import { saveLocale, getLocale } from '../i18nInit'

saveLocale(getLocale())

const HeaderText = styled.h2`
  max-width: 1280px;
  line-height: 35px;
  padding-bottom: 15px;
  margin: auto;
  font-weight: lighter;
`

const Header = styled.div`
  background: #2b2d38;
  color: #ffffff;
  width: 100%;
`

const Container = styled.div`
  max-width: 1280px;
  margin: auto;
`

const PasswordReset = styled.div`
  margin-top: 16px;
  text-align: center;
`

const Page = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-template-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-items: stretch;
  align-items: stretch;

  @media screen and (max-width: 1400px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`
const CartView = styled.div`
  grid-column: 1;
  grid-row: 1;

  @media screen and (max-width: 1400px) {
    overflow: scroll;
  }
`
const ContactForm = styled.div`
  grid-column: 2;
  grid-row: 1;

  @media screen and (max-width: 1400px) {
    max-width: 95%;
  }
`
const FormContainer = styled.div`
  background: #f2f4f9;
  grid-column: 1 / span 2;
  padding: 16px;
`
const H2 = styled.h2`
  color: #464850;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  margin: 32px 0;
`

const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 40px;
`
const TabButton = styled.div`
  justify-self: stretch;
  background: #f2f4f9;
  color: #464850;
  &.passive {
    background: #e1e4ed;
    color: #606060;
    cursor: pointer;
  }
`
const TabText = styled.h4`
  padding: 16px;
  margin: 0;
  font-family: Roboto;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
`
const Label = styled.label`
  color: #464850;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`
const Input = styled.input`
  box-sizing: border-box;
  height: 29px;
  width: 100%;
  border: 1px solid #bac0cd;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 8px;
`
const InputLabel = styled.label`
  color: #303030;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  margin-left: 8px;
  font-weight: normal;
`
const Select = styled.select`
  box-sizing: border-box;
  height: 29px;
  width: 100%;
  border: 1px solid #bac0cd;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 8px;
`
const FullWidthButton = styled(Button)`
  width: 100%;
  background-color: #67d79b;
  margin-top: 16px;
`

class WidgetLanding extends Component<
  {
    match: void,
    users: void,
    cart: void,
    getCart: void,
    usersWidgetCreate: void,
    login: void,
    confirmCart: void,
    error: Array<Object>,
    resetErrorMessage: () => void,
    getCountries: void,
    screenAttributes: {
      countries: Array
    },
    resetPassword: void
  },
  {}
> {
  constructor() {
    super()

    this.state = {
      id: '',
      loginActive: false,
      orderSuccess: false,
      resetPasswordActive: false
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { id, wid }
      },
      getCart: getCartAction,
      getCountries: getCountriesAction
    } = this.props
    this.setState({ id })

    getCartAction(id)
    getCountriesAction(wid)
  }

  componentDidUpdate() {
    const {
      error,
      resetErrorMessage: resetErrorAction,
      cart: { error: cartError }
    } = this.props

    if (cartError && cartError.message) {
      toast.error(cartError.message)
    }

    if (error && error.messages.non_field_errors) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  setupTable = () => {
    const {
      cart: { items }
    } = this.props

    const data = items.map(item => {
      const {
        starts_at: startsAt,
        ends_at: endsAt,
        slot_duration_in_seconds: duration,
        contacts,
        screen: { name },
        price
      } = item

      const fromDate = moment.utc(startsAt).format('D.M.YYYY')
      const toDate = moment.utc(endsAt).format('D.M.YYYY')

      let priceFormat = parseFloat(price)
      priceFormat = priceFormat.toFixed(2)

      return {
        col1: name,
        col2: fromDate,
        col3: toDate,
        col4: duration,
        col5: contacts,
        col6: priceFormat
      }
    })

    return data
  }

  onSubmit = e => {
    e.preventDefault()
    const {
      usersWidgetCreate: usersWidgetCreateAction,
      login: loginAction,
      confirmCart: confirmCartAction
    } = this.props

    const {
      id,
      email,
      password,
      name,
      phone_number: phoneNumber,
      postcode,
      street_address: streetAddress,
      business_id: businessId,
      city,
      first_name: firstName,
      last_name: lastName,
      country_id: countryId,
      invoice_address: invoiceAddress,
      invoice_email: invoiceEmail,
      einvoice_address: einvoiceAddress,
      einvoice_operator_code: einvoiceOperatorCode,
      campaignTitle
    } = this.state

    const customerData = {
      customer: {
        name,
        phone_number: phoneNumber,
        postcode,
        street_address: streetAddress,
        business_id: businessId,
        city,
        country_id: countryId,
        invoice_address: invoiceAddress,
        invoice_email: invoiceEmail,
        einvoice_address: einvoiceAddress,
        einvoice_operator_code: einvoiceOperatorCode
      },
      contact_person: {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber
      },
      campaign_title: campaignTitle
    }

    usersWidgetCreateAction({ email, password }).then(response => {
      if (response.status && response.status !== 201) {
        const {
          users: { error }
        } = this.props
        if (error && error.email) {
          toast.error(error.email[0].message)
        }
        return null
      }
      loginAction({ email, password }).then(loginResponse => {
        if (loginResponse.status && loginResponse.status !== 200) {
          const {
            users: { error }
          } = this.props
          const {
            error: { email: emailError }
          } = error
          if (error && emailError) {
            toast.error(emailError[0].message)
          }
          return null
        }
        confirmCartAction(id, customerData).then(confirmResponse => {
          if (confirmResponse.status && confirmResponse.status !== 200) {
            return null
          }
          toast.success(
            t`Tilaus onnistui. Saat hetken kuluttua sähköpostilla tilausvahvistuksen.`
          )
          this.setState({
            orderSuccess: true
          })
          return confirmResponse
        })
        return loginResponse
      })
      return response
    })
  }

  onLoginSubmit = e => {
    e.preventDefault()

    const { login: loginAction, confirmCart: confirmCartAction } = this.props

    const { id, email, password, campaignTitle } = this.state

    const customerData = {
      campaign_title: campaignTitle
    }

    loginAction({ email, password }).then(loginResponse => {
      if (loginResponse.status && loginResponse.status !== 200) {
        const { error, resetErrorMessage: resetErrorAction } = this.props

        if (error && error.messages.non_field_errors[0].message) {
          toast.error(error.messages.non_field_errors[0].message)
          resetErrorAction()
        }
        return null
      }
      confirmCartAction(id, customerData).then(confirmResponse => {
        if (confirmResponse.status && confirmResponse.status !== 200) {
          return null
        }
        toast.success(
          t`Tilaus onnistui. Saat hetken kuluttua sähköpostilla tilausvahvistuksen.`
        )
        this.setState({
          orderSuccess: true
        })
        return confirmResponse
      })
      return loginResponse
    })
  }

  onPasswordResetSubmit = e => {
    e.preventDefault()

    const { resetPassword: resetPasswordAction } = this.props

    const { resetEmail } = this.state

    resetPasswordAction({ email: resetEmail }).then(resetPasswordResponse => {
      if (
        resetPasswordResponse.status &&
        resetPasswordResponse.status !== 200
      ) {
        const { error, resetErrorMessage: resetErrorAction } = this.props

        if (error && error.messages.non_field_errors[0].message) {
          toast.error(error.messages.non_field_errors[0].message)
          resetErrorAction()
        }
        return null
      }

      toast.success(
        t`Ohjeet salasanan palauttamiseen lähetetty antamaasi sähköpostiosoitteeseen.`
      )
      this.setState({ resetPasswordActive: false, resetEmail: '' })

      return resetPasswordResponse
    })

    e.target.reset()
  }

  handleInputChange = event => {
    const {
      target: { id, value, type, checked }
    } = event
    const val = type === 'checkbox' ? checked : value

    this.setState({ [id]: val })
  }

  toggleTab = () => {
    const { loginActive } = this.state

    // Reset forms that no data is persisted
    if (loginActive) {
      document.getElementById('loginForm').reset()
    } else {
      document.getElementById('signupForm').reset()
    }

    this.setState({
      loginActive: !loginActive
    })
  }

  render() {
    const data = this.setupTable()
    const {
      cart: { error: cartError },
      screenAttributes: { countries }
    } = this.props
    const { loginActive, orderSuccess, resetPasswordActive } = this.state
    let disabled = true
    if (cartError.length === 0) disabled = false

    return (
      <Page>
        <Header>
          <HeaderText>{t`Blip SHOP - Ulkomainonnan ammattilainen`}</HeaderText>
        </Header>
        <Container>
          <Grid>
            <CartView>
              <H2>{t`Yhteenveto`}</H2>
              <TableContainer data={data} />
            </CartView>
            {orderSuccess ? (
              <h1>{t`Kiitos tilauksesta`}</h1>
            ) : (
              <ContactForm>
                <H2>{t`Tilaajan tiedot`}</H2>
                <TabContainer>
                  <TabButton
                    className={loginActive ? 'passive' : ''}
                    onClick={this.toggleTab}
                  >
                    <TabText>{t`Uusi käyttäjä`}</TabText>
                  </TabButton>
                  <TabButton
                    className={loginActive ? '' : 'passive'}
                    onClick={this.toggleTab}
                  >
                    <TabText>{t`Kirjaudu sisään`}</TabText>
                  </TabButton>
                  {loginActive ? (
                    <FormContainer>
                      {resetPasswordActive ? (
                        <form
                          id="passwordResetForm"
                          onSubmit={this.onPasswordResetSubmit}
                        >
                          <Label htmlFor="resetEmail">{t`Sähköpostiosoite`}</Label>
                          <Input
                            type="email"
                            required
                            onChange={this.handleInputChange}
                            id="resetEmail"
                          />
                          <FullWidthButton
                            type="submit"
                            disabled={disabled}
                          >{t`Vaihda salasana`}</FullWidthButton>
                          <PasswordReset>
                            <Link
                              to="#"
                              onClick={() => {
                                this.setState({ resetPasswordActive: false })

                                document
                                  .getElementById('passwordResetForm')
                                  .reset()
                              }}
                            >{t`Kirjaudu sisään`}</Link>
                          </PasswordReset>
                        </form>
                      ) : (
                        <form id="loginForm" onSubmit={this.onLoginSubmit}>
                          <Label htmlFor="campaignTitle">{t`Kampanjan nimi`}</Label>
                          <Input
                            type="text"
                            required
                            onChange={this.handleInputChange}
                            id="campaignTitle"
                          />
                          <Label htmlFor="email">{t`Käyttäjätunnus`}</Label>
                          <Input
                            type="text"
                            required
                            onChange={this.handleInputChange}
                            id="email"
                          />
                          <Label htmlFor="last_name">{t`Salasana`}</Label>
                          <Input
                            type="password"
                            required
                            onChange={this.handleInputChange}
                            id="password"
                          />
                          <input
                            type="checkbox"
                            value="ok"
                            required
                            onChange={this.handleInputChange}
                          />{' '}
                          <InputLabel>{t`Vahvistan että tilauksen tiedot ovat oikein ja hyväksyn tilausehdot`}</InputLabel>
                          <FullWidthButton
                            type="submit"
                            disabled={disabled}
                          >{t`Lähetä tilaus`}</FullWidthButton>
                          <PasswordReset>
                            <Link
                              to="#"
                              onClick={() => {
                                this.setState({ resetPasswordActive: true })

                                if (loginActive) {
                                  document.getElementById('loginForm').reset()
                                } else {
                                  document.getElementById('signupForm').reset()
                                }
                              }}
                            >{t`Unohdin salasanani`}</Link>
                          </PasswordReset>
                        </form>
                      )}
                    </FormContainer>
                  ) : (
                    <FormContainer>
                      <form id="signupForm" onSubmit={this.onSubmit}>
                        <Label htmlFor="email">{t`Kampanjan nimi`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="campaignTitle"
                        />
                        <Label htmlFor="first_name">{t`Etunimi`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="first_name"
                        />
                        <Label htmlFor="last_name">{t`Sukunimi`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="last_name"
                        />
                        <Label htmlFor="email">{t`Sähköposti`}</Label>
                        <Input
                          type="email"
                          required
                          onChange={this.handleInputChange}
                          id="email"
                        />
                        <Label htmlFor="phone_number">{t`Puhelinnumero`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="phone_number"
                        />
                        <Label htmlFor="name">{t`Yritys`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="name"
                        />
                        <Label htmlFor="street_address">{t`Katuosoite`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="street_address"
                        />
                        <Label htmlFor="postcode">{t`Postinumero`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="postcode"
                        />
                        <Label htmlFor="city">{t`Kaupunki`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="city"
                        />
                        <Label htmlFor="country_id">{t`Maa`}</Label>
                        <Select
                          id="country_id"
                          onChange={this.handleInputChange}
                        >
                          <option value={null}>{t`Valitse`}</option>
                          {countries.map(({ value, label }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </Select>
                        <Label htmlFor="business_id">{t`Y-tunnus`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="business_id"
                        />
                        <Label htmlFor="invoice_address">{t`Laskutusosoite`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="invoice_address"
                        />
                        <Label htmlFor="invoice_email">{t`Laskutus-sähköposti`}</Label>
                        <Input
                          type="email"
                          required
                          onChange={this.handleInputChange}
                          id="invoice_email"
                        />
                        <Label htmlFor="einvoice_address">{t`Verkkolaskuosoite`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="einvoice_address"
                        />
                        <Label htmlFor="einvoice_operator_code">{t`Operaattoritunnus`}</Label>
                        <Input
                          type="text"
                          required
                          onChange={this.handleInputChange}
                          id="einvoice_operator_code"
                        />
                        <Label htmlFor="password">{t`Salasana`}</Label>
                        <Input
                          type="password"
                          required
                          onChange={this.handleInputChange}
                          pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{8,}"
                          title={t`Salanassa täytyy olla vähintään kahdeksan merkkiä ja sen tulee sisältää yksi numero ja yksi erikoismerkki.`}
                          min={8}
                          id="password"
                        />
                        <input
                          type="checkbox"
                          value="ok"
                          required
                          onChange={this.handleInputChange}
                        />{' '}
                        <InputLabel>{t`Vahvistan että tilauksen tiedot ovat oikein ja hyväksyn tilausehdot`}</InputLabel>
                        <FullWidthButton
                          type="submit"
                          disabled={disabled}
                        >{t`Lähetä tilaus`}</FullWidthButton>
                      </form>
                    </FormContainer>
                  )}
                </TabContainer>
              </ContactForm>
            )}
          </Grid>
        </Container>
      </Page>
    )
  }
}

const mapStateToProps = ({ error, cart, users, screenAttributes }) => ({
  error,
  cart,
  users,
  screenAttributes
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCart,
      usersWidgetCreate,
      confirmCart,
      login,
      resetErrorMessage,
      getCountries,
      resetPassword
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WidgetLanding)
