import { LIST_USERS, CREATE_WIDGET_USER } from 'store/actions/users'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  users: [],
  user: [],
  isPending: false,
  error: {
    messages: []
  }
}

export default typeToReducer(
  {
    [LIST_USERS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { response } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = response
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.users = payload
          draft.isPending = false
        })
    },
    [CREATE_WIDGET_USER]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.user = payload
          draft.isPending = false
        })
    }
  },
  initialState
)
