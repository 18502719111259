// @flow
/* eslint-disable react/no-did-update-set-state */

import React, { Component } from 'react'

import styled from 'styled-components/macro'

import ModalContent from 'components/modals/ModalContent/ModalContent.js'
import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'
import { t } from 'ttag'
import BlipModal from './BlipModal.js'

const FormPanel = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  label,
  input {
    margin: 6px 12px 6px 0;
  }

  label {
    text-align: right;
  }

  input {
    border: 1px solid #ccc;
    padding: 3px;
    border-radius: 3px;
  }
`
const InputFields = [{ name: 'name', label: t`Name` }]

export default class EditScreenGroup extends Component<
  {
    action: void,
    updateScreenGroupAction: void,
    error: Array,
    screenGroup: Object
  },
  {}
> {
  state = {
    name: '',
    id: null
  }

  constructor(props) {
    super(props)

    const {
      screenGroup,
      screenGroup: { title, id }
    } = props

    if (screenGroup) {
      this.state = { name: title, id }
    }
  }

  onSubmit = () => {
    const { action } = this.props
    const { id, name } = this.state

    action({ id, name })
  }

  onInputChange = e => {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  textInput(title, name) {
    const { [name]: value } = this.state

    return (
      <>
        <label htmlFor={`editscreengroup-${name}`}>{title}:</label>
        <input
          id={`editscreengroup-${name}`}
          name={name}
          type="text"
          onChange={this.onInputChange}
          value={value}
        />
      </>
    )
  }

  render() {
    const { error } = this.props

    return (
      <BlipModal {...this.props} onSubmit={this.onSubmit}>
        <ModalContent title={t`Screen Group information`}>
          <FormPanel>
            {InputFields.map(({ name: fieldName, label }) => {
              const { [fieldName]: fieldValue } = this.state

              const elementError =
                error && Object.prototype.hasOwnProperty.call(error, fieldName)
                  ? error[fieldName][0].message
                  : false

              return (
                <InputWithLabel
                  key={fieldName}
                  group="editscreengroup"
                  label={label}
                  name={fieldName}
                  onChange={this.onInputChange}
                  value={fieldValue}
                  error={elementError}
                />
              )
            })}
          </FormPanel>
        </ModalContent>
      </BlipModal>
    )
  }
}
