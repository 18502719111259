import { GET_SCREENS, CREATE_SCREEN, UPLOAD_IMAGE } from 'store/actions/screens'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  screens: [],
  isPending: false,
  error: null,
  lastId: null
}

export default typeToReducer(
  {
    [GET_SCREENS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const screens = payload.map(({ name, ...rest }) => ({
          title: name,
          ...rest
        }))
        return produce(state, draft => {
          draft.screens = screens
          draft.isPending = false
        })
      }
    },
    [CREATE_SCREEN]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload: { name: title, ...rest } }) => {
        const newScreen = { title, ...rest }

        const screenIndex = state.screens.findIndex(
          screen => screen.id === newScreen.id
        )
        return produce(state, draft => {
          if (screenIndex > -1) {
            draft.screens[screenIndex] = newScreen
          } else {
            draft.screens.push(newScreen)
            draft.lastId = newScreen.id
          }

          draft.error = null
          draft.isPending = false
        })
      }
    },
    [UPLOAD_IMAGE]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const screens = payload.map(({ name, ...rest }) => ({
          title: name,
          ...rest
        }))
        return produce(state, draft => {
          draft.screens = screens
          draft.isPending = false
        })
      }
    }
  },
  initialState
)
