// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ttag'
import { resetErrorMessage } from 'store/actions/error'
import {
  resetPassword,
  resetPasswordConfirm,
  resetPasswordConfirmReset
} from 'store/actions/auth'
import Input from 'components/FormElements/Inputs/FatInput/FatInput'

import Button from 'components/Button/Button'
import NavBar from 'components/Navbar/Navbar'

const Container = styled.div`
  // text-align: center;
  border-radius: 3px;
  background-color: #f2f4f9;
  margin: 0 auto;
  padding: 25px 50px;
  width: 450px;
`

const FormContainer = styled.div``

const Form = styled.form`
  margin: 50px 0;
`

const Submit = styled.button`
  width: 100%;
  height: 50px;
  padding: 0;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #56c2e5;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;

  :disabled {
    background: gray;
  }
`
class ForgotPassword extends Component<
  {
    auth: Array<Object>,
    resetPasswordConfirm: void,
    resetPasswordConfirmReset: void,
    resetPassword: void,
    history: Array<{ push: void }>,
    match: Array<{ params: Array<{ uid: string, token: string }> }>
  },
  {}
> {
  state = {
    forms: {},
    touched: false
  }

  forgotFields = [{ type: 'email', name: 'email', label: 'Email' }]

  resetFields = [
    { type: 'password', name: 'new_password1', label: t`Password` },
    { type: 'password', name: 'new_password2', label: t`Password (same again)` }
  ]

  componentDidUpdate({
    auth: { passwordResetRedirect: passwordResetRedirectPrev }
  }) {
    const {
      auth: { passwordResetRedirect, error, passwordResetMessage },
      resetPasswordConfirmReset: resetPasswordConfirmResetAction,
      history: { push }
    } = this.props

    if (passwordResetRedirectPrev === false && passwordResetRedirect === true) {
      push('/login')
    }

    if (error && error.messages && error.messages.token) {
      toast.error(t`Password reset link already used`)
      resetPasswordConfirmResetAction()
    }

    if (passwordResetMessage) {
      toast.info(passwordResetMessage)
      resetPasswordConfirmResetAction()
    }
  }

  resetPassword = e => {
    e.preventDefault()

    const { forms } = this.state
    const { resetPassword: resetPasswordAction } = this.props

    resetPasswordAction(forms)
  }

  confirmPassword = e => {
    e.preventDefault()

    const { forms } = this.state
    const {
      resetPasswordConfirm: resetPasswordConfirmAction,
      match: {
        params: { uid, token }
      }
    } = this.props

    resetPasswordConfirmAction({ ...forms, uid, token })
  }

  handleChange = e => {
    const { forms } = this.state
    this.setState({
      touched: true,
      forms: { ...forms, [e.target.name]: e.target.value }
    })
  }

  render() {
    const {
      auth: { isPending, error, loggedIn, passwordResetSend },
      match: { params }
    } = this.props

    const {
      forms: { new_password1: newPass1, new_password2: newPass2 },
      touched
    } = this.state

    if (loggedIn)
      return (
        <Redirect
          to={{
            pathname: '/home'
          }}
        />
      )

    const reset = !(params.uid && params.token)

    const inputs = (reset ? this.forgotFields : this.resetFields).map(
      ({ type, name, label }) => {
        const { messages } = error
        const fieldError = messages ? messages[name] : null

        return (
          <Input
            key={`${type}-${name}`}
            type={type}
            name={name}
            onChange={this.handleChange}
            error={fieldError}
            label={label}
          />
        )
      }
    )

    const buttonState = !(reset || (!reset && newPass1 === newPass2 && touched))

    return (
      <div>
        <NavBar />
        <Container>
          <FormContainer>
            <h1>{t`Forgot password`}</h1>
            <Form onSubmit={reset ? this.resetPassword : this.confirmPassword}>
              {inputs}
              <Submit
                type="submit"
                disabled={passwordResetSend || (isPending || buttonState)}
              >
                {t`Reset password`}
              </Submit>
            </Form>
            <Button style={{ width: '100%' }} to="/login">
              {t`Already have an account? Login here`}
            </Button>
          </FormContainer>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, error }) => ({
  auth,
  error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetPassword,
      resetPasswordConfirm,
      resetPasswordConfirmReset,
      resetErrorMessage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword)
