import { GET_CART } from 'store/actions/cart'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  id: null,
  items: [],
  error: [],
  isPending: false
}

export default typeToReducer(
  {
    [GET_CART]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const { id, items } = payload
        return produce(state, draft => {
          draft.id = id
          draft.items = items
          draft.isPending = false
        })
      }
    }
  },
  initialState
)
