import {
  GET_MEDIA_SITES,
  GET_AGE_GROUPS,
  GET_TAGS,
  GET_AD_FORMATS,
  GET_COUNTRIES,
  GET_CURRENCIES,
  GET_INDUSTRY_RESTRICTIONS,
  GET_TIMEZONES
} from 'store/actions/screenAttributes'

import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  media_sites: [],
  age_groups: [],
  tags: [],
  ad_formats: [],
  countries: [],
  currencies: [],
  industry_restrictions: [],
  timezones: [],
  isPending: false
}

export default typeToReducer(
  {
    [GET_MEDIA_SITES]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const mediaSites = payload.map(({ id, name }) => ({
          value: id,
          label: name
        }))
        return produce(state, draft => {
          draft.media_sites = mediaSites
          draft.isPending = false
        })
      }
    },
    [GET_AGE_GROUPS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const ageGroups = payload.map(({ id, name }) => ({
          value: id,
          label: name
        }))
        return produce(state, draft => {
          draft.age_groups = ageGroups
          draft.isPending = false
        })
      }
    },
    [GET_TAGS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const tags = payload.map(({ id, name }) => ({
          value: id,
          label: name
        }))
        return produce(state, draft => {
          draft.tags = tags
          draft.isPending = false
        })
      }
    },
    [GET_AD_FORMATS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const adFormats = payload.map(({ id, name }) => ({
          value: id,
          label: name
        }))
        return produce(state, draft => {
          draft.ad_formats = adFormats
          draft.isPending = false
        })
      }
    },
    [GET_COUNTRIES]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const countries = payload.map(({ id, name }) => ({
          value: id,
          label: name
        }))
        return produce(state, draft => {
          draft.countries = countries
          draft.isPending = false
        })
      }
    },
    [GET_CURRENCIES]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.currencies = payload
          draft.isPending = false
        })
    },
    [GET_INDUSTRY_RESTRICTIONS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const industryRestrictions = payload.map(({ id, name }) => ({
          value: id,
          label: name
        }))
        return produce(state, draft => {
          draft.industry_restrictions = industryRestrictions
          draft.isPending = false
        })
      }
    },
    [GET_TIMEZONES]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const timezones = payload.map(timezone => ({
          value: timezone,
          label: timezone
        }))
        return produce(state, draft => {
          draft.timezones = timezones
          draft.isPending = false
        })
      }
    }
  },
  initialState
)
