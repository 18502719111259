import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { bindActionCreators } from 'redux'
import { userRead, logout } from 'store/actions/auth'
import { exportReservations } from 'store/actions/reservations'
import { Link } from 'react-router-dom'
import { t } from 'ttag'

import BrandLogo from 'assets/DOOHManager3.png'

import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Avatar from './Avatar/Avatar'
import LanguageSelector from '../LanguageSelector/LanguageSelector'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContainerLeft = styled.div``
const ContainerRight = styled.div`
  // display: flex;
`

const Logo = styled.img`
  margin: 20px 20px;
  max-height: 50px;
`

const AppBarContainer = styled.div`
  padding: 5px 20px;
`

class Navbar extends Component<
  {
    userRead: void,
    logout: void,
    auth: Array<{ loggedIn: boolean }>,
    exportReservations: void
  },
  {}
> {
  state = {
    drawer: false
  }

  componentDidMount() {
    const {
      auth: { loggedIn }
    } = this.props

    if (loggedIn) {
      this.getUserInfo()
    }
  }

  componentDidUpdate(prevprops) {
    const {
      auth: { loggedIn }
    } = this.props

    const {
      auth: { loggedIn: prevLoggedIn }
    } = prevprops

    if (prevLoggedIn !== loggedIn && prevLoggedIn === false) {
      this.getUserInfo()
    }
  }

  exportReservations = () => {
    const { exportReservations: exportReservationsAction } = this.props

    exportReservationsAction().then(response => {
      if (response.action.type === 'EXPORT_RESERVATIONS_FULFILLED') {
        toast.success(t`Reservations sent to your email address.`)
      } else {
        toast.error(
          t`There was a problem sending reservations to your email address.`
        )
      }
    })
  }

  getUserInfo = () => {
    const { userRead: userReadAction } = this.props

    userReadAction()
  }

  closeDrawer = () => {
    this.setState({
      drawer: false
    })
  }

  openDrawer = () => {
    this.setState({
      drawer: true
    })
  }

  handleLogout = () => {
    const { logout: logoutAction } = this.props

    logoutAction()
  }

  render() {
    const {
      auth: {
        user: { username, is_admin: isAdmin },
        loggedIn
      }
    } = this.props

    const { drawer } = this.state

    return (
      <Container>
        <ContainerLeft>
          <Link to="/home">
            <Logo src={BrandLogo} alt="DOOH Manager" />
          </Link>
        </ContainerLeft>
        {loggedIn && (
          <ContainerRight>
            <AppBarContainer>
              <Avatar username={username} onClick={this.openDrawer} />
            </AppBarContainer>

            <Drawer anchor="right" open={drawer} onClose={this.closeDrawer}>
              <div
                tabIndex={0}
                role="button"
                onClick={this.closeDrawer}
                onKeyDown={this.closeDrawer}
                style={{ outline: 'none' }}
              >
                <div>
                  <List>
                    <ListItem
                      button
                      key="Dashboard"
                      component={Link}
                      to="/home"
                    >
                      <ListItemText primary={t`Dashboard`} />
                    </ListItem>
                    {isAdmin ? (
                      <ListItem
                        button
                        key="Screen and group management"
                        component={Link}
                        to="/screensandgroups"
                      >
                        <ListItemText
                          primary={t`Screen and group management`}
                        />
                      </ListItem>
                    ) : null}
                    {isAdmin ? (
                      <ListItem
                        button
                        key="Campaigns"
                        component={Link}
                        to="/campaigns"
                      >
                        <ListItemText primary={t`Campaigns`} />
                      </ListItem>
                    ) : null}
                    {isAdmin ? (
                      <ListItem
                        button
                        key="Sales report"
                        component={Link}
                        to="/salesreport"
                      >
                        <ListItemText primary={t`Sales report`} />
                      </ListItem>
                    ) : null}
                    {isAdmin ? (
                      <ListItem
                        button
                        key="Partnerships"
                        component={Link}
                        to="/partnerships"
                      >
                        <ListItemText primary={t`Partnerships`} />
                      </ListItem>
                    ) : null}
                    {isAdmin ? (
                      <ListItem
                        button
                        key="Widget"
                        component={Link}
                        to="/widget"
                      >
                        <ListItemText primary={t`Widget`} />
                      </ListItem>
                    ) : null}
                    {isAdmin ? (
                      <ListItem
                        button
                        key="Organization management"
                        component={Link}
                        to="/users"
                      >
                        <ListItemText primary={t`Organization management`} />
                      </ListItem>
                    ) : null}
                    {isAdmin ? (
                      <ListItem
                        button
                        key="Export"
                        onClick={this.exportReservations}
                      >
                        <ListItemText primary={t`Export`} />
                      </ListItem>
                    ) : null}
                  </List>
                  <Divider />
                  <List>
                    <ListItem button key="Logout" onClick={this.handleLogout}>
                      <ListItemText primary={t`Logout`} />
                    </ListItem>
                  </List>
                  <Divider />
                  <LanguageSelector />
                </div>
              </div>
            </Drawer>
          </ContainerRight>
        )}
      </Container>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ userRead, logout, exportReservations }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
