import { GET_SALES_REPORT } from 'store/actions/reports'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  sales: [],
  error: [],
  isPending: false
}

export default typeToReducer(
  {
    [GET_SALES_REPORT]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.sales = payload
          draft.isPending = false
        })
    }
  },
  initialState
)
