import { Get, Post, Patch } from 'api/index'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>
// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_WIDGET = 'GET_WIDGET'
export const CREATE_WIDGET = 'CREATE_WIDGET'
export const UPDATE_WIDGET = 'UPDATE_WIDGET'

export const getWidget = (): ThunkAction => dispatch => {
  const url = `/api/widgets-management/`
  return dispatch({
    type: GET_WIDGET,
    payload: Get(url)
  })
}

export const createWidget = (formData: Object): ThunkAction => dispatch => {
  const url = `/api/widgets-management/`

  return dispatch({
    type: CREATE_WIDGET,
    payload: Post(url, formData, true)
  })
}

export const updateWidget = (
  id: number,
  formData: Object
): ThunkAction => dispatch => {
  const url = `/api/widgets-management/${id}/`

  return dispatch({
    type: UPDATE_WIDGET,
    payload: Patch(url, formData, true)
  })
}
