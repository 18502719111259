import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import styled from 'styled-components/macro'

const AvatarEl = styled(Avatar)`
  cursor: pointer;
`

export default ({
  username = '',
  onClick,
  ...rest
}: {
  username: string,
  onClick: void
}) => (
  <AvatarEl onClick={onClick} style={{ backgroundColor: '#ff8db6' }} {...rest}>
    {username.toUpperCase().substr(0, 2)}
  </AvatarEl>
)
