import React from 'react'
import styled from 'styled-components/macro'

const TextArea = styled.textarea`
  border: 1px solid ${props => (props.error ? 'red' : '#ccc')};
  padding: 4px 9px;
  height: 84px;
  border-radius: 4px;
  color: #464850;
  font-size: 12px;
  line-height: 14px;
`
const ErrorField = styled.span`
  padding: 5px;
`
const textArea = (
  props: Array<{
    group?: string,
    label: string,
    name: string,
    onChange: void,
    value: string,
    error: string,
    placeholder?: string,
    type?: string,
    required?: boolean
  }>
) => {
  const {
    group,
    label,
    name,
    onChange,
    value,
    error,
    type,
    placeholder,
    required
  } = props

  const id = group ? `${group}-${name}` : name

  const inputType = type || 'text'
  return (
    <>
      <label htmlFor={id}>
        {label} {required ? ' *' : ''}
      </label>
      <div>
        <TextArea
          id={id}
          name={name}
          type={inputType}
          onChange={onChange}
          value={value}
          error={error}
          placeholder={placeholder}
          required={required}
        />

        {error && <ErrorField>{error}</ErrorField>}
      </div>
    </>
  )
}

textArea.defaultProps = {
  type: 'text',
  placeholder: null,
  group: null
}

export default textArea
