import { GET_PARTNERSHIPS, CREATE_PARTNERSHIP } from 'store/actions/partners'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  partnerships: [],
  isPending: false,
  error: null
}

export default typeToReducer(
  {
    [GET_PARTNERSHIPS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
          draft.error = null
        }),

      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.partnerships = payload
          draft.isPending = false
          draft.error = null
        })
    },
    [CREATE_PARTNERSHIP]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
          draft.error = null
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.partnerships.push(payload)
          draft.isPending = false
          draft.error = null
        })
    }
  },
  initialState
)
