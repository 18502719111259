import {
  GET_SCREEN_GROUPS,
  CREATE_SCREEN_GROUP
} from 'store/actions/screenGroups'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  groups: [],
  isPending: false
}

export default typeToReducer(
  {
    [GET_SCREEN_GROUPS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { response } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = response
        }),
      FULFILLED: (state, { payload }) => {
        const groups = payload.map(({ name, ...rest }) => ({
          title: name,
          ...rest
        }))
        return produce(state, draft => {
          draft.groups = groups
          draft.isPending = false
        })
      }
    },
    [CREATE_SCREEN_GROUP]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),

      REJECTED: (state, { payload: { response } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = response
        }),
      FULFILLED: (state, { payload }) => {
        const group = { title: payload.name, ...payload }
        delete group.name
        return produce(state, draft => {
          draft.groups.push(group)
          draft.isPending = false
        })
      }
    }
  },
  initialState
)
