import { encodeQueryParams } from 'util/url'
import ApiError from 'ApiError'

import 'unfetch/polyfill'
import { getLocale } from '../i18nInit'

/* const checkStatus = response => {
  if (!response.ok) {
    const error = new Error(response.statusText)
    error.response = response
    return Promise.reject(error)
  }
  return response
} */

const locale = getLocale()

const ApiCall = (path, method, body, formData = false) => {
  let headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': locale
    // Accept: '*/*'

    // Don't cache, sometimes problematic on old IE
    // pragma: 'no-cache',
    // 'cache-control': 'no-cache'
  }
  if (formData) {
    headers = {
      Accept: 'application/json',
      'Accept-Language': locale
    }
  }

  const apiUrl = `${process.env.REACT_APP_API_URL}${path}`

  // For authentication
  const token = sessionStorage.getItem('API_TOKEN')
  headers.Authorization = token ? `Token ${token}` : null

  return fetch(apiUrl, {
    headers,
    method,
    mode: 'cors',
    body: body || undefined
  }).then(response => {
    const length = parseInt(response.headers.get('content-length'), 10)
    if (!response.ok) {
      return response
        .json()
        .then(json => Promise.reject(new ApiError(response, json)))
    }
    if (response.status === 204) {
      return {}
    }
    if (response.status === 200 && length === 0) {
      return {}
    }
    return response.json()
  })
}

export function Get(path, params = {}) {
  return ApiCall(path + encodeQueryParams(params), 'GET', null)
}

export function Post(path, body, formData = false) {
  return ApiCall(path, 'POST', body, formData)
}

export function Put(path, body) {
  return ApiCall(path, 'PUT', body)
}

export function Patch(path, body, formData = false) {
  return ApiCall(path, 'PATCH', body, formData)
}

export function Delete(path) {
  return ApiCall(path, 'DELETE', null)
}
