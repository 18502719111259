// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import produce from 'immer'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { resetErrorMessage } from 'store/actions/error'

import { t } from 'ttag'
import { toast } from 'react-toastify'
import Button from 'components/Button/Button'
import {
  getCampaignMaterials,
  uploadMaterial,
  removeMaterial
} from 'store/actions/campaign'

const HeaderText = styled.h2`
  max-width: 1280px;
  line-height: 35px;
  padding-bottom: 15px;
  margin: auto;
  font-weight: lighter;
`
const Header = styled.div`
  background: #2b2d38;
  color: #ffffff;
  width: 100%;
`
const Container = styled.div`
  max-width: 1280px;
  margin: auto;
`
const Page = styled.div`
  width: 100%;
  background: #fff;
  margin-bottom: 32px;
  padding-bottom: 32px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-items: stretch;
  align-items: stretch;
`
const FlexRow = styled.div`
  display: flex;
  background: #dce0e0;
  border-radius: 4px;
`
const Info = styled.div`
  width: 50%;
`
const Form = styled.div`
  width: 25%;
`
const Materials = styled.div`
  width: 25%;
`
const H2 = styled.h2`
  color: #464850;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  margin: 32px 0;
`
const H3 = styled.h3`
  color: #464850;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  margin: 16px 0;
`
const LI = styled.li`
  color: #464850;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
  margin: 8px 0;
`
const InputLabel = styled.label`
  color: #303030;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  margin-left: 8px;
  font-weight: normal;
`
const SubmitButton = styled(Button)`
  background-color: #2e5063;
  color: #ffffff;
  font-size: 17px;
`

class WidgetMaterialUpload extends Component<
  {
    match: void,
    getCampaignMaterials: void,
    uploadMaterial: void,
    removeMaterial: void,
    error: Array<Object>,
    resetErrorMessage: () => void,
    campaign: {
      id: string,
      title: string,
      reservations: Array<Object>,
      campaigns: Array<Object>,
      error: Object,
      isPending: boolean,
      isMaterialsPending: boolean
    }
  },
  {}
> {
  constructor() {
    super()
    this.state = {
      id: '',
      files: [],
      isUploading: false,
      formKey: Date.now(),
      confirm: false
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      getCampaignMaterials: getCampaignMaterialsAction
    } = this.props

    this.setState({ id })

    getCampaignMaterialsAction(id)
  }

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props
    if (error && error.messages.non_field_errors) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  onSubmit = async () => {
    const { uploadMaterial: uploadMaterialAction } = this.props
    const { id, files, confirm } = this.state

    if (!confirm) {
      toast.error(t`Vahvistus puuttuu`)
      return
    }
    if (files.length <= 0) {
      toast.error(t`Lisää tiedosto`)
      return
    }
    this.setState({ isUploading: true })

    const calls = []
    files.forEach(currentFile => {
      const { id: resId, file: fileObject } = currentFile
      const formData = new FormData()
      formData.append('file', fileObject)
      calls.push(uploadMaterialAction(id, resId, formData))
    })

    await Promise.all(calls).then(results => {
      results.forEach(result => {
        if (result.status === 400) {
          toast.warning(t`Error: ${result.messages.message}`)
        }
      })
      this.setState({ isUploading: false })
      this.setState({ formKey: Date.now(), files: [] })
      toast.success(t`File upload success`)
    })
  }

  handleInputChange = event => {
    const {
      target: { id, value, type, checked }
    } = event
    const val = type === 'checkbox' ? checked : value
    this.setState({ [id]: val })
  }

  handleRemove = (id, resId) => {
    const {
      removeMaterial: removeMaterialAction,
      getCampaignMaterials: getCampaignMaterialsAction
    } = this.props
    removeMaterialAction(id, resId).then(response => {
      if (
        response.action &&
        response.action.type &&
        response.action.type === 'REMOVE_MATERIAL_FULFILLED'
      ) {
        getCampaignMaterialsAction(id).then(toast.success(t`File removed`))
      }
    })
  }

  onFileChange = event => {
    const {
      target: { id, files: newFiles }
    } = event

    Object.entries(newFiles).map(newFile => {
      const file = newFile[1]
      this.setState(
        produce(draft => {
          draft.files.push({
            id,
            file
          })
        })
      )
      return null
    })
  }

  render() {
    const {
      campaign: { id, reservations, title }
    } = this.props
    const { isUploading, formKey } = this.state

    return (
      <Page>
        <Header>
          <HeaderText>{t`Blip SHOP - Ulkomainonnan ammattilainen`}</HeaderText>
        </Header>
        <Container key={formKey}>
          <H2>{t`Tilauksen: ${title} materiaalit`}</H2>
          <Grid>
            {reservations.map(reservation => {
              const {
                id: resId,
                starts_at: startsAt,
                ends_at: endsAt,
                screen: {
                  name,
                  description,
                  resolution_width: resolutionWidth,
                  resolution_height: resolutionHeight,
                  width,
                  height,
                  ad_formats: adFormats,
                  industry_restrictions: industryRestrictions,
                  aspect_ratio: { exact, closest_common: closestCommon }
                },
                slot_duration_in_seconds: slotDuration,
                materials
              } = reservation

              const sDate = moment.utc(startsAt).format('D.M.YYYY') // format(new Date(startsAt), 'd.M.yyyy')
              const eDate = moment.utc(endsAt).format('D.M.YYYY') // format(new Date(endsAt), 'd.M.yyyy')

              return (
                <FlexRow key={`row-${resId}`}>
                  <Info>
                    <ul>
                      <LI>
                        <H3>{name}</H3>
                      </LI>
                      <LI>{description}</LI>
                      <LI>{t`Booking Starts: ${sDate}`}</LI>
                      <LI>{t`Booking Ends: ${eDate}`}</LI>
                      <LI>{t`Slot Duration: ${slotDuration} sec`}</LI>
                      <LI>{t`Screen Resolution: ${resolutionWidth}x${resolutionHeight} px`}</LI>
                      <LI>{t`Screen Size: ${width}x${height} cm`}</LI>
                      <LI>{t`Aspect Ratio: ${exact}`}</LI>
                      <LI>{t`Alternative Aspect Ratio: ${closestCommon}`}</LI>
                    </ul>
                  </Info>
                  <Form>
                    <H3>{t`Add Files`}:</H3>
                    <InputLabel>
                      <input
                        id={resId}
                        key={resId}
                        type="file"
                        multiple
                        onChange={this.onFileChange}
                      />
                    </InputLabel>
                    <ul>
                      <LI>
                        {t`Supported File Formats`}:
                        <ul>
                          {adFormats.map(({ name: aname }) => (
                            <LI key={aname}>{aname}</LI>
                          ))}
                        </ul>
                      </LI>
                      <LI>
                        {t`Restrictions`}:
                        <ul>
                          {industryRestrictions.map(({ name: rname }) => (
                            <LI key={rname}>{rname}</LI>
                          ))}
                        </ul>
                      </LI>
                    </ul>
                  </Form>
                  <Materials>
                    <ul>
                      <LI>
                        {t`Downloaded Files`}:
                        <ul>
                          {materials.map(({ name: mname, id: mid }) => (
                            <LI key={mid}>
                              {mname}{' '}
                              <Button
                                size="xsmall"
                                onClick={() => this.handleRemove(id, mid)}
                              >{t`Delete`}</Button>
                            </LI>
                          ))}
                        </ul>
                      </LI>
                    </ul>
                  </Materials>
                </FlexRow>
              )
            })}
            <InputLabel>
              <input
                type="checkbox"
                value="ok"
                id="confirm"
                required
                onChange={this.handleInputChange}
              />{' '}
              {t`I confirm that the downloaded content complies with the instructions and is not inappropriate.`}
            </InputLabel>
            <SubmitButton
              onClick={this.onSubmit}
              type="submit"
              disabled={isUploading}
            >
              {' '}
              {isUploading ? t`Loading...` : t`Send Files`}
            </SubmitButton>
          </Grid>
        </Container>
      </Page>
    )
  }
}

const mapStateToProps = ({ error, campaign }) => ({
  error,
  campaign
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCampaignMaterials,
      uploadMaterial,
      removeMaterial,
      resetErrorMessage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetMaterialUpload)
