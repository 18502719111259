// @flow
import React from 'react'
import styled from 'styled-components/macro'

const ErrorMessage = styled.p`
  color: red;
`

const Input = styled.input`
  display: block;
  font-size: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: ${props => (props.size === 'slim' ? '8px 15px' : '15px 30px')};
  width: 100%;
  margin-bottom: 10px;
`
const FatInput = ({
  type,
  name,
  label,
  onChange,
  error: errors,
  size,
  ...rest
}: {
  type: string,
  name: string,
  onChange: void,
  label?: string,
  size?: string,
  error: Array<Object>
}) => (
  <>
    {label && <label>{label}</label>}
    <Input type={type} name={name} onChange={onChange} size={size} {...rest} />
    {errors
      ? errors.map(({ message }) => <ErrorMessage>{message}</ErrorMessage>)
      : null}
  </>
)

FatInput.defaultProps = {
  label: '',
  size: null
}

export default FatInput
