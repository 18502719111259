import React from 'react'
import styled from 'styled-components/macro'

const Input = styled.input`
  border: 1px solid ${props => (props.error ? 'red' : '#ccc')};
  padding: 4px 9px;
  height: ${props => (props.type === 'checkbox' ? '16px' : '28px')};
  border-radius: 4px;
  color: #464850;
  font-size: 12px;
  line-height: 14px;
  width: ${props => (props.type === 'checkbox' ? '16px' : '100%')};
`
const ErrorField = styled.span`
  padding: 5px;
`

const textInput = (
  props: Array<{
    id: number,
    key: string,
    group?: string,
    label: string,
    name: string,
    onChange: void,
    value: string,
    error: string,
    placeholder?: string,
    type?: string,
    required?: boolean,
    disabled?: boolean,
    onKeyDown?: void
  }>
) => {
  const {
    group,
    label,
    name,
    onChange,
    value,
    error,
    type,
    placeholder,
    required,
    disabled,
    onKeyDown
  } = props

  const id = group ? `${group}-${name}` : name

  const inputType = type || 'text'
  return (
    <>
      {label && label.trim() !== '' && (
        <label htmlFor={id}>
          {label} {required ? ' *' : ''}
        </label>
      )}
      <div className="inputWithLabelComponent">
        <Input
          id={id}
          name={name}
          type={inputType}
          onChange={onChange}
          value={value}
          error={error}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onKeyDown={onKeyDown}
        />

        {error && <ErrorField>{error}</ErrorField>}
      </div>
    </>
  )
}

textInput.defaultProps = {
  type: 'text',
  placeholder: null,
  group: null
}

export default textInput
