const getColorForPercentage = (pct, colors) => {
  let i
  for (i = 1; i < colors.length - 1; i += 1) {
    if (pct < colors[i].pct) {
      break
    }
  }
  const lower = colors[i - 1]
  const upper = colors[i]
  const range = upper.pct - lower.pct
  const rangePct = (pct - lower.pct) / range
  const pctLower = 1 - rangePct
  const pctUpper = rangePct
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  }

  return `rgb(${[color.r, color.g, color.b].join(',')})`
  // or output as hex if preferred
}

// eslint-disable-next-line
export { getColorForPercentage }
