// @flow
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components/macro'

/* Style the accordion section */
const AccordionSection = styled.div`
  position: relative;
`

/* Style the buttons that are used to open and close the accordion panel */
const AccordionTitle = styled.button`
  background-color: #233d4c;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border: none;
  border-top: 1px solid white;
  outline: none;
  transition: background-color 0.6s ease;
  :hover {
    background-color: #2e5063;
  }
  &.active {
  }
`
/* Style the accordion content title */
const AccordionTitleText = styled.h4`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin: 0;
  padding: 7px 17px;
`
const AccordionContent = styled.div`
  overflow: hidden;
  transition: max-height 0.6s ease;
  background-color: #f2f4f9;
  border-radius: 0 0 4px 4px;
  padding: 0 8px;
  &.active {
    padding: 8px;
    padding-bottom: 16px;
    overflow: visible;
  }
`

const AccordionActive = styled.div`
  height: 10px;
  width: 10px;
  background-color: lightgrey;
  border-radius: 5px;
  position: absolute;
  top: 14px;
  right: 14px;
  &.filter-set {
    background-color: #1b3685;
  }
`

function Accordion({
  id,
  title,
  open,
  handleAccordionOpen,
  children
}: {
  id: number,
  title: string,
  open: boolean,
  handleAccordionOpen: any,
  children: React.children
}) {
  const [setActive, setActiveState] = useState('')
  const [setHeight, setHeightState] = useState('0px')

  const content = useRef(null)

  useEffect(() => {
    if (open) {
      setActiveState('active')
      setHeightState(`${content.current.scrollHeight + 24}px`)
    }
    if (!open) {
      setActiveState('')
      setHeightState('0px')
    }
  }, [open])

  return (
    <AccordionSection
      className="accordion-section"
      onClick={() => handleAccordionOpen(id)}
    >
      <AccordionTitle className={`accordion ${setActive}`}>
        <AccordionTitleText className="accordion-title">
          {title}
        </AccordionTitleText>
      </AccordionTitle>
      <AccordionContent
        ref={content}
        className={`accordion-content ${setActive}`}
        style={{ maxHeight: `${setHeight}` }}
      >
        {children}
        <AccordionActive className="filter-not-set" />
      </AccordionContent>
    </AccordionSection>
  )
}

export default Accordion
