// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
// import memoize from 'memoize-one'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { openModal, closeModal } from 'store/actions/dialogs'
import { getScreenGroups, createScreenGroup } from 'store/actions/screenGroups'
import { getScreens, createScreen, uploadImage } from 'store/actions/screens'
import {
  getTags,
  getAgeGroups,
  getAdFormats,
  getMediaSites,
  getIndustryRestrictions,
  getCountries,
  getCurrencies,
  getTimezones
} from 'store/actions/screenAttributes'
import {
  getReservations,
  createReservation,
  updateReservation,
  deleteReservation
} from 'store/actions/reservations'
import {
  searchOrganization,
  createOrganization
} from 'store/actions/organizations'
import { usersCreate } from 'store/actions/users'
import { createCustomer, getCustomers } from 'store/actions/customers'
import { getPartnerships, createPartnership } from 'store/actions/partners'
import { resetErrorMessage } from 'store/actions/error'

import CreateReservationModal from 'components/modals/CreateReservation'
import EditReservationModal from 'components/modals/EditReservation'
import AddCustomerModal from 'components/modals/AddCustomer'
import AddScreenModal from 'components/modals/AddScreen'
import AddPartnershipModal from 'components/modals/AddPartnership'

import moment from 'moment'
import SimpleTimeline from 'components/timeline/SimpleTimeline'

import Button from 'components/Button/Button'
import CollapseButton from 'components/CollapseButton/CollapseButton'

import StatusGuide from 'components/StatusGuide/StatusGuide'
import { t } from 'ttag'
import NavBar from 'components/Navbar/Navbar'
import { toast } from 'react-toastify'

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
`
const Toolbar = styled.div`
  text-align: right;
`
const ScreenGroup = styled.div`
  background-color: #e5e7ee;
  padding-left: 25px;
  cursor: ${props => props.cursor};
  position: relative;
`

const Screen = styled.div`
  padding-left: 50px;
  line-height: 45px;
  border-bottom: 1px solid #c6c6c6;
  cursor: pointer;
  position: relative;
`

class Home extends Component<
  {
    openModal: void,
    closeModal: void,
    getScreenGroups: void,
    getScreens: void,
    getReservations: void,
    getCustomers: () => void,
    dialogs: { name: string, options: Object },
    screens: {
      screens: Array,
      isPending: boolean,
      error: Object,
      lastId: number
    },
    createScreen: void,
    uploadImage: void,
    screenGroups: { groups: Array, isPending: boolean },
    createScreenGroup: void,
    reservations: { reservations: Array, isPending: boolean, error: Object },
    customers: { customers: Array, isPending: boolean, error: Object },
    organizations: void,
    createReservation: void,
    updateReservation: void,
    deleteReservation: void,
    createCustomer: void,
    screenAttributes: {
      tags: Array,
      media_sites: Array,
      age_groups: Array,
      ad_formats: Array,
      countries: Array,
      currencies: Array,
      industry_restrictions: Array,
      timezones: Array,
      isPending: boolean,
      error: Object
    },
    getTags: void,
    getAgeGroups: void,
    getAdFormats: void,
    getMediaSites: void,
    getIndustryRestrictions: void,
    getCountries: void,
    getCurrencies: void,
    getTimezones: void,
    createPartnership: void,
    searchOrganization: void,
    createOrganization: void,
    usersCreate: void
  },
  {
    openGroups: Object,
    availability: Object<{ start: Object, stop: Object }>
  }
> {
  constructor(props) {
    super(props)
    this.state = {
      openGroups: {},
      availability: {
        start: moment()
          .startOf('day')
          .add(-3, 'w')
          .format('YYYY-MM-DD'),
        stop: moment()
          .startOf('day')
          .add(5, 'w')
          .format('YYYY-MM-DD')
      },
      isLoading: true
    }
  }

  componentDidMount() {
    const {
      getScreenGroups: getScreenGroupsAction,
      getScreens: getScreensAction,
      getReservations: getReservationsAction,
      getCustomers: getCustomersAction,
      getTags: getTagsAction,
      getAgeGroups: getAgeGroupsAction,
      getAdFormats: getAdFormatsAction,
      getMediaSites: getMediaSitesAction,
      getIndustryRestrictions: getIndustryRestrictionsAction,
      getCountries: getCountriesAction,
      getCurrencies: getCurrenciesAction,
      getTimezones: getTimezonesAction
    } = this.props

    const {
      availability: { start, stop }
    } = this.state

    const params = {
      availability_from_date: start,
      availability_to_date: stop
    }

    Promise.all([
      getScreenGroupsAction(params),
      getScreensAction(params),
      getReservationsAction(params),
      getCustomersAction(),
      getTagsAction(),
      getAgeGroupsAction(),
      getAdFormatsAction(),
      getMediaSitesAction(),
      getIndustryRestrictionsAction(),
      getCountriesAction(),
      getCurrenciesAction(),
      getTimezonesAction()
    ]).finally(() => {
      /* A Resize event is dispatched to fix the timeline;
      otherwise it doesn't work correctly and the styling is broken */
      window.dispatchEvent(new Event('resize'))
      this.setState({
        isLoading: false
      })
    })
  }

  groupedScreens = () => {
    const {
      screens: { screens },
      screenGroups: { groups }
    } = this.props

    if (!screens || !groups) {
      return []
    }

    const { openGroups } = this.state

    const items = groups.map(({ id, title, ...rest }) => {
      const groupIdField = `g-${id}`

      const children = screens
        .filter(screen => screen.screen_group_id === id)
        .map(
          ({
            id: screenId,
            title: titleLabel,
            loop_duration_in_seconds: maxDuration,
            ...itemRest
          }) => ({
            id: `s-${screenId}`,
            title: titleLabel,
            sidebarTitle: (
              <Screen onClick={() => this.toggleGroup(`s-${screenId}`)}>
                {titleLabel}
                <CollapseButton collapse={!openGroups[`s-${screenId}`]} />
              </Screen>
            ),
            groupHeader: false,
            screenGroup: false,
            maxDuration,
            parent: groupIdField,
            open: !!openGroups[groupIdField],
            ...itemRest
          })
        )

      let max = null

      const childrenCount = screens.filter(
        screen => screen.screen_group_id === id
      ).length

      if (childrenCount > 0) {
        const { availability } = rest

        if (availability) {
          max = Object.values(availability).reduce((a, b) => Math.max(a, b))
        }
      }

      const groupTitle = (
        <ScreenGroup
          cursor={childrenCount > 0 ? 'pointer' : 'default'}
          onClick={() => this.toggleGroup(groupIdField)}
        >
          {title}
          {childrenCount > 0 ? (
            <CollapseButton collapse={!openGroups[groupIdField]} />
          ) : null}
        </ScreenGroup>
      )

      return {
        id: groupIdField,
        title,
        height: 45,
        sidebarTitle: groupTitle,
        maxDuration: max,
        groupHeader: true,
        root: true,
        screenGroup: true,
        children,
        open: !!openGroups[groupIdField],
        ...rest
      }
    })
    return items
  }

  sortData = () => {
    const items = this.groupedScreens()

    // Flatten to 1D
    const flatten = array =>
      array.reduce(
        (res, { children = [], ...rest }) =>
          res.concat({ ...rest }).concat(flatten(children)),
        []
      )

    const flattenedItems = flatten(items)

    return { flat: flattenedItems, grouped: items }
  }

  sortItems = (screens, groups, reservations, customers) => {
    if (
      typeof groups === 'undefined' ||
      groups.length === 0 ||
      typeof customers === 'undefined'
    )
      return []

    const { openGroups } = this.state

    const headers = groups.reduce(
      (acc, { availability, id, screenGroup, maxDuration }) => {
        if (!availability || typeof availability === 'undefined') {
          return acc
        }

        return [].concat(
          acc,
          Object.entries(availability).map(([date, length]) => {
            const title = length === null ? '∞' : length
            return {
              id: `${id}-${date}`,
              group: id,
              title,
              duration: length,
              maxDuration,
              start_time: moment(date).startOf('day'),
              end_time: moment(date)
                .startOf('day')
                .add(1, 'day'),
              groupHeader: true,
              style: [{}],
              canMove: false,
              canResize: false,
              canChangeGroup: false,
              screenGroup
            }
          })
        )
      },
      []
    )

    const items = []

    reservations.forEach(reservation => {
      const {
        slot_duration_in_seconds: slotDuration,
        customer: resCustomer,
        campaign: resCampaing,
        status,
        starts_at: startsAt,
        ends_at: endsAt,
        screen_id: screenId,
        id
      } = reservation

      let title = t`Varattu`

      if (resCampaing) {
        const { title: campTitle } = resCampaing
        if (campTitle) {
          title = campTitle
        }
      }

      if (resCustomer) {
        const { title: custTitle } = resCustomer
        if (custTitle) {
          title = custTitle
        }
      }

      if (resCustomer) {
        const { id: custId } = resCustomer
        const customer = customers.find(cust => cust.id === custId)
        if (customer) {
          const { name: cTitle } = customer
          title = cTitle
        }
      }

      if (openGroups[`s-${screenId}`]) {
        const screen = groups.find(el => el.id === `s-${screenId}`)

        if (screen) {
          const { id: group } = screen
          const reservationId = `s-${screenId}_r-${id}`

          const offset = moment(endsAt).utcOffset()

          items.push({
            id: reservationId,
            group,
            title,
            duration: slotDuration,
            status,
            start_time: moment(startsAt)
              // .utc()
              .startOf('day'),
            end_time: moment(endsAt)
              .subtract(offset, 'minutes')
              .endOf('day'),
            // .endAt('day')
            //  .format('x'), //.endOf('day'),
            //  .add(1, 'day'),
            groupHeader: false,
            style: [{}],
            canMove: false,
            canResize: false,
            canChangeGroup: false
          })
        }
      }
    })

    return [...headers, ...items]
  }

  loadNewData = (canvasStart, canvasStop) => {
    const {
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction,
      getReservations: getReservationsAction
    } = this.props

    const start = moment(canvasStart)
      .startOf('day')
      .format('YYYY-MM-DD')

    const stop = moment(canvasStop)
      .startOf('day')
      .format('YYYY-MM-DD')

    this.setState({
      availability: {
        start,
        stop
      }
    })

    const params = {
      availability_from_date: start,
      availability_to_date: stop
    }

    getReservationsAction(params)
    getScreensAction(params)
    getScreenGroupsAction(params)
  }

  showCreateReservation = () => {
    const { openModal: action } = this.props
    action('createReservation')
  }

  showAddCustomer = () => {
    const { openModal: action } = this.props
    action('addCustomer')
  }

  showModifyReservation = selectedReservation => {
    const { openModal: action } = this.props
    action('modifyReservation', { selectedReservation })
  }

  showAddScreen = () => {
    const { openModal: action } = this.props
    action('addScreen')
  }

  showAddPartnership = () => {
    const { openModal: action } = this.props
    action('addPartnership')
  }

  createReservation = data => {
    const {
      createReservation: createReservationAction,
      updateReservation: updateReservationAction,
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction,
      getReservations: getReservationsAction
    } = this.props

    const action = data.id ? updateReservationAction : createReservationAction

    action(data).then(response => {
      if (response.status && response.status !== 201) {
        toast.error(t`Invalid field(s) on form`)
      } else {
        const {
          availability: { start, stop }
        } = this.state
        const params = {
          availability_from_date: start,
          availability_to_date: stop
        }

        Promise.all([
          getReservationsAction(params),
          getScreensAction(params),
          getScreenGroupsAction(params)
        ]).then(toast.success(t`Reservation added!`))
      }
    })
  }

  createScreen = (data, imageData) => {
    const {
      createScreen: createScreenAction,
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction,
      getReservations: getReservationsAction
    } = this.props

    createScreenAction(data).then(response => {
      if (response.status && response.status !== 201) {
        const {
          screens: { error }
        } = this.props
        if (error) {
          toast.error(t`Error saving new screen`)
        }
        return null
      }
      const {
        availability: { start, stop }
      } = this.state

      const params = {
        availability_from_date: start,
        availability_to_date: stop
      }

      const {
        screens: { lastId }
      } = this.props

      Promise.all([
        getReservationsAction(params),
        getScreensAction(params),
        getScreenGroupsAction(params)
      ]).then(
        toast.success(t`Screen added!`),
        this.uploadImage(lastId, imageData)
      )
      return true
    })
  }

  uploadImage = (screenId, selectedFile) => {
    const { uploadImage: uploadImageAction } = this.props
    const formData = new FormData()
    formData.append('file', selectedFile)

    uploadImageAction(screenId, formData).then(response => {
      if (response.status && response.status !== 201) {
        const {
          screens: { error }
        } = this.props
        if (error) {
          toast.error(t`Error uploading image`)
        }
        return null
      }
      toast.success(t`Image upload success`)
      return response
    })
  }

  createPartnership = data => {
    const {
      createPartnership: createPartnershipAction,
      searchOrganization: searchOrganizationAction,
      createOrganization: createOrganizationAction,
      usersCreate: usersCreateAction
    } = this.props

    const {
      businessId,
      city,
      name,
      phoneNumber,
      postcode,
      streetAddress,
      countryId,
      email,
      screenId,
      revenueShare,
      timeShare,
      canSell,
      canOperate
    } = data

    const params = {
      business_id: businessId
    }

    searchOrganizationAction(params).then(searchResponse => {
      if (searchResponse.status && searchResponse.status === 404) {
        toast.success(t`Creating organization`)
        createOrganizationAction({
          business_id: businessId,
          city,
          name,
          phone_number: phoneNumber,
          postcode,
          street_address: streetAddress,
          country_id: countryId
        }).then(orgResponse => {
          if (orgResponse.status && orgResponse.status === 400) {
            toast.error(t`Creating organization failed`)
            return null
          }
          let orgId = false
          if (orgResponse.value && orgResponse.value.id) {
            orgId = orgResponse.value.id
          }
          if (orgId) {
            createPartnershipAction({
              organization_id: orgId,
              screen_id: screenId,
              revenue_share: revenueShare,
              time_share: timeShare,
              can_sell: canSell,
              can_operate: canOperate
            }).then(partnerResponse => {
              if (partnerResponse.status && partnerResponse.status === 400) {
                toast.error(t`Creating partnership failed`)
                return null
              }
              toast.success(t`Partnership created`)
              usersCreateAction({
                email,
                partner_organization_id: orgId
              }).then(userResponse => {
                if (userResponse.status && userResponse.status === 400) {
                  toast.error(t`Creating user failed`)
                  return null
                }
                toast.success(t`User created`)
                return userResponse
              })
              return null
            })
            return orgResponse
          }
          return null
        })
      }

      if (
        searchResponse.action &&
        searchResponse.action.type === 'SEARCH_ORGANIZATION_FULFILLED'
      ) {
        toast.success(t`Organization found - creating partnership`)

        let orgId = false
        if (searchResponse.value && searchResponse.value.id) {
          orgId = searchResponse.value.id
        }

        createPartnershipAction({
          organization_id: orgId,
          screen_id: screenId,
          revenue_share: revenueShare,
          time_share: timeShare,
          can_sell: canSell,
          can_operate: canOperate
        }).then(partnerResponse => {
          if (partnerResponse.status && partnerResponse.status === 400) {
            toast.error(t`Creating partnership failed`)
          }
          toast.success(t`Partnership created`)
          return partnerResponse
        })
        return searchResponse
      }
      return null
    })
  }

  searchOrganization = businessId => {
    const { searchOrganization: searchOrganizationAction } = this.props
    const params = {
      business_id: businessId
    }
    searchOrganizationAction(params)
  }

  deleteReservation = data => {
    const {
      deleteReservation: deleteReservationAction,
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction,
      getReservations: getReservationsAction
    } = this.props

    deleteReservationAction(data).then(() => {
      const {
        availability: { start, stop }
      } = this.state

      const params = {
        availability_from_date: start,
        availability_to_date: stop
      }

      getReservationsAction(params)
      getScreensAction(params)
      getScreenGroupsAction(params)
    })
  }

  ModifyReservationHandler = itemId => {
    const item = itemId.split('-')
    const reservationId = parseInt(item.pop(), 10)
    const type = item.shift(item)
    const isReservation = itemId.includes('_r')
    if (type === 's' && isReservation) {
      const {
        reservations: { reservations }
      } = this.props

      const reservation = reservations.find(r => r.id === reservationId)
      this.showModifyReservation(reservation)
    }
  }

  toggleGroup = id => {
    const { openGroups } = this.state
    this.setState({
      openGroups: {
        ...openGroups,
        [id]: !openGroups[id]
      }
    })
  }

  render() {
    const {
      screens: {
        screens,
        error: screensError,
        isPending: screensPending,
        lastId
      },
      screenGroups: { groups },
      reservations: {
        reservations,
        error: reservationsError,
        isPending: reservationPending
      },
      dialogs,
      customers: {
        customers,
        isPending: customersPending,
        error: customersError
      },
      createCustomer: createCustomerAction,
      createScreenGroup: createScreenGroupAction,
      closeModal: closeModalAction,
      screenAttributes: {
        tags,
        media_sites: mediaSites,
        age_groups: ageGroups,
        ad_formats: adFormats,
        countries,
        currencies,
        industry_restrictions: industryRestrictions,
        timezones
      },
      organizations
    } = this.props

    const { isLoading } = this.state

    const { flat: combinedGroups, grouped } = this.sortData(
      groups,
      /* eslint-disable no-param-reassign */
      /*
      screens.map(s => {
        delete s.height
        delete s.width
      })
*/
      screens.forEach(s => {
        delete s.height
        delete s.width
      })

      /* eslint-enable no-param-reassign */
    )
    const items = this.sortItems(
      screens,
      combinedGroups,
      reservations,
      customers
    )
    let modal = null

    if (dialogs.name === 'createReservation') {
      modal = (
        <CreateReservationModal
          contentLabel={t`Campaign`}
          isOpen
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          options={grouped}
          customers={customers}
          action={this.createReservation}
          error={reservationsError}
        />
      )
    } else if (dialogs.name === 'modifyReservation') {
      modal = (
        <EditReservationModal
          isOpen
          contentLabel={t`Modify reservation`}
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          options={grouped}
          customers={customers}
          action={this.createReservation}
          deleteAction={this.deleteReservation}
          reservation={dialogs.options.selectedReservation}
          error={reservationsError}
          pending={reservationPending}
          mode="reservation"
        />
      )
    } else if (dialogs.name === 'addCustomer') {
      modal = (
        <AddCustomerModal
          contentLabel={t`Customer`}
          isOpen
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          options={grouped}
          countries={countries}
          pending={customersPending}
          error={customersError}
          action={createCustomerAction}
        />
      )
    } else if (dialogs.name === 'addPartnership') {
      modal = (
        <AddPartnershipModal
          contentLabel={t`Create new partnership`}
          isOpen
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          screens={screens}
          countries={countries}
          action={this.createPartnership}
          searchOrganization={this.searchOrganization}
          organizations={organizations}
        />
      )
    } else if (dialogs.name === 'addScreen') {
      modal = (
        <AddScreenModal
          contentLabel={t`Screen`}
          isOpen
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          options={grouped}
          error={screensError}
          action={this.createScreen}
          uploadImageAction={this.uploadImage}
          screenGroups={groups}
          createScreenGroupAction={createScreenGroupAction}
          pending={screensPending}
          tags={tags}
          mediaSites={mediaSites}
          ageGroups={ageGroups}
          adFormats={adFormats}
          countries={countries}
          currencies={currencies}
          timezones={timezones}
          industryRestrictions={industryRestrictions}
          lastId={lastId}
        />
      )
    } else if (dialogs.name === 'addImage') {
      modal = (
        <addImageModal
          contentLabel={t`Images`}
          isOpen
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          options={grouped}
          error={screensError}
          uploadImageAction={this.uploadImage}
          pending={screensPending}
        />
      )
    }
    return (
      <div>
        <NavBar />
        <div className="App">
          <ToolbarContainer>
            <StatusGuide />
            <Toolbar>
              <Button
                size="large"
                type="button"
                onClick={this.showCreateReservation}
                background="#E556A5"
              >
                {t`Create campaign`}
              </Button>
              <Button
                size="large"
                type="button"
                onClick={this.showAddCustomer}
                background="#2E6362"
              >
                {t`Add customer`}
              </Button>
              <Button
                size="large"
                type="button"
                onClick={this.showAddScreen}
                background="#2E5063"
              >
                {t`Add screen`}
              </Button>
              <Button
                size="large"
                type="button"
                onClick={this.showAddPartnership}
                background="#44AB74"
              >
                {t`Add partnership`}
              </Button>
            </Toolbar>
          </ToolbarContainer>
          {isLoading ? (
            <h5>{t`Loading...`}</h5>
          ) : (
            <SimpleTimeline
              items={items}
              groups={combinedGroups.filter(
                ({ groupHeader, open }) => groupHeader || (open && !groupHeader)
              )}
              onBoundsChange={this.loadNewData}
              onItemDoubleClick={this.ModifyReservationHandler}
            />
          )}
          {modal}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  error,
  screenGroups,
  screens,
  customers,
  reservations,
  dialogs,
  screenAttributes,
  partners,
  organization,
  organizations
}) => ({
  error,
  screenGroups,
  screens,
  customers,
  reservations,
  dialogs,
  screenAttributes,
  partners,
  organization,
  organizations
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      getScreenGroups,
      createScreenGroup,
      getScreens,
      createScreen,
      uploadImage,
      getReservations,
      createReservation,
      updateReservation,
      deleteReservation,
      createCustomer,
      getCustomers,
      resetErrorMessage,
      getTags,
      getAgeGroups,
      getAdFormats,
      getMediaSites,
      getIndustryRestrictions,
      getCountries,
      getCurrencies,
      getTimezones,
      getPartnerships,
      createPartnership,
      searchOrganization,
      createOrganization,
      usersCreate
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Home)
