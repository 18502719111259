// @flow

import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { bindActionCreators } from 'redux'

import { openModal, closeModal } from 'store/actions/dialogs'
import {
  updateScreen,
  deleteScreen,
  getScreens,
  createScreen,
  uploadImage
} from 'store/actions/screens'
import {
  deleteScreenGroup,
  getScreenGroups,
  createScreenGroup,
  updateScreenGroup
} from 'store/actions/screenGroups'
import {
  getTags,
  getAgeGroups,
  getAdFormats,
  getMediaSites,
  getIndustryRestrictions,
  getCountries,
  getCurrencies,
  getTimezones
} from 'store/actions/screenAttributes'
import { resetErrorMessage } from 'store/actions/error'
import Button from 'components/Button/Button'
import FatInput from 'components/FormElements/Inputs/FatInput/FatInput'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import LinearProgress from '@material-ui/core/LinearProgress'

import AddScreenModal from 'components/modals/AddScreen'
import EditScreenGroup from 'components/modals/EditScreenGroup'
import NavBar from 'components/Navbar/Navbar'

const Container = styled.div`
  margin: 50px 100px;
  padding: 25px 50px;
`

const TabContainer = styled.div`
  padding: 20px;
`

class ScreensAndGroups extends Component<
  {
    screens: {
      screens: Array,
      isPending: boolean,
      error: Object,
      lastId: number
    },
    screenGroups: { groups: Array<Object>, isPending: boolean },
    screenAttributes: {
      tags: Array,
      media_sites: Array,
      age_groups: Array,
      ad_formats: Array,
      countries: Array,
      currencies: Array,
      industry_restrictions: Array,
      timezones: Array,
      isPending: boolean,
      error: Object
    },
    error: Array<Object>,
    resetErrorMessage: void,
    deleteScreen: void,
    deleteScreenGroup: void,
    getScreens: void,
    getScreenGroups: void,
    createScreenGroup: void,
    createScreen: void,
    uploadImage: void,
    openModal: void,
    closeModal: void,
    updateScreen: void,
    updateScreenGroup: void,
    dialogs: { name: string, options: Object },
    getTags: void,
    getAgeGroups: void,
    getAdFormats: void,
    getMediaSites: void,
    getIndustryRestrictions: void,
    getCountries: void,
    getCurrencies: void,
    getTimezones: void
  },
  {}
> {
  state = {
    value: 0,
    group: ''
  }

  componentDidMount() {
    const {
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction,
      getTags: getTagsAction,
      getAgeGroups: getAgeGroupsAction,
      getAdFormats: getAdFormatsAction,
      getMediaSites: getMediaSitesAction,
      getIndustryRestrictions: getIndustryRestrictionsAction,
      getCountries: getCountriesAction,
      getCurrencies: getCurrenciesAction,
      getTimezones: getTimezonesAction
    } = this.props

    Promise.all([
      getScreensAction(),
      getScreenGroupsAction(),
      getTagsAction(),
      getAgeGroupsAction(),
      getAdFormatsAction(),
      getMediaSitesAction(),
      getIndustryRestrictionsAction(),
      getCountriesAction(),
      getCurrenciesAction(),
      getTimezonesAction()
    ])
  }

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0].message) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  showAddScreen = () => {
    const { openModal: action } = this.props
    action('addScreen')
  }

  handleChange = e => {
    const { name, value } = e.currentTarget

    this.setState({ [name]: value })
  }

  handleCheckbox = e => {
    const { value, checked } = e.currentTarget

    this.setState({ [value]: checked })
  }

  changeTab = (event, value) => {
    this.setState({ value })
  }

  handleScreenDelete = id => {
    const {
      deleteScreen: deleteScreenAction,
      getScreens: getScreensAction
    } = this.props

    deleteScreenAction(id)
      .then(() => getScreensAction())
      .then(() => toast.success(t`Screen deleted!`))
  }

  handleGroupDelete = id => {
    const {
      deleteScreenGroup: deleteScreenGroupAction,
      getScreenGroups: getScreenGroupsAction
    } = this.props

    deleteScreenGroupAction(id)
      .then(() => getScreenGroupsAction())
      .then(() => toast.success(t`Screen Group deleted!`))
  }

  handleScreenGroupAdd = () => {
    const {
      createScreenGroup: createScreenGroupAction,
      getScreenGroups: getScreenGroupsAction
    } = this.props

    const { group } = this.state

    createScreenGroupAction(group)
      .then(() => getScreenGroupsAction())
      .then(() => toast.success(t`Screen Group added!`))
      .then(() => this.setState({ group: '' }))
  }

  handleScreenAdd = () => {
    const { openModal: action } = this.props
    action('addScreen')
  }

  handleScreenEdit = id => {
    const {
      screens: { screens }
    } = this.props

    const screen = screens.find(s => s.id === id)

    const { openModal: action } = this.props
    action('editScreen', { screen })
  }

  handleScreenGroupEdit = id => {
    const {
      screenGroups: { groups }
    } = this.props

    const group = groups.find(g => g.id === id)

    const { openModal: action } = this.props
    action('editScreenGroup', { group })
  }

  createScreen = (data, imageData) => {
    const {
      createScreen: createScreenAction,
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction
    } = this.props

    createScreenAction(data).then(response => {
      if (response.status && response.status !== 201) {
        const {
          screens: { error }
        } = this.props
        if (error) {
          toast.error(t`Error saving new screen`)
        }
        return null
      }

      const {
        screens: { lastId }
      } = this.props

      Promise.all([getScreensAction(), getScreenGroupsAction()]).then(
        toast.success(t`Screen added!`),
        this.uploadImage(lastId, imageData)
      )
      return true
    })
  }

  updateScreen = (data: Object) => {
    const { id, payload, selectedFile } = data

    const {
      updateScreen: updateScreenAction,
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction
    } = this.props

    updateScreenAction(id, payload)
      .then(() => {
        getScreensAction()
        getScreenGroupsAction()
        this.uploadImage(id, selectedFile)
      })
      .then(() => toast.success(t`Screen updated!`))
  }

  uploadImage = (screenId, selectedFile) => {
    const { uploadImage: uploadImageAction } = this.props

    const formData = new FormData()
    formData.append('file', selectedFile)

    uploadImageAction(screenId, formData).then(response => {
      if (response.status && response.status !== 201) {
        const {
          screens: { error }
        } = this.props
        if (error) {
          toast.error(t`Error uploading image`)
        }
        return null
      }
      toast.success(t`Image upload success`)
      return response
    })
  }

  updateScreenGroup = data => {
    const {
      updateScreenGroup: updateScreenGroupAction,
      getScreens: getScreensAction,
      getScreenGroups: getScreenGroupsAction
    } = this.props
    updateScreenGroupAction(data)
      .then(() => {
        getScreensAction()
        getScreenGroupsAction()
      })
      .then(() => toast.success(t`Screen Group updated!`))
  }

  render() {
    const {
      // eslint-disable-next-line react/prop-types
      screens: {
        screens,
        isPending: screensPending,
        error: screensError,
        lastId
      },
      screenGroups: {
        groups,
        isPending: groupsPending,
        // eslint-disable-next-line react/prop-types
        error: screenGroupsError
      },
      createScreenGroup: createScreenGroupAction,
      closeModal: closeModalAction,
      dialogs,
      screenAttributes: {
        tags,
        media_sites: mediaSites,
        age_groups: ageGroups,
        ad_formats: adFormats,
        countries,
        currencies,
        industry_restrictions: industryRestrictions,
        timezones
      }
    } = this.props

    const { value, group } = this.state

    let items
    if (groups.length > 0 && screens.length > 0) {
      items = groups.map(({ id, title }) => ({
        id,
        name: title,
        children: screens
          .filter(({ screen_group_id: parentId }) => parentId === id)
          .map(({ title: childTitle, ...rest }) => ({
            name: childTitle,
            ...rest
          }))
      }))
    }

    let modal = null

    if (dialogs.name === 'addScreen') {
      modal = (
        <AddScreenModal
          contentLabel={t`Screen`}
          isOpen
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          error={screensError}
          action={this.createScreen}
          uploadImageAction={this.uploadImage}
          screenGroups={groups}
          createScreenGroupAction={createScreenGroupAction}
          pending={screensPending}
          tags={tags}
          mediaSites={mediaSites}
          ageGroups={ageGroups}
          adFormats={adFormats}
          countries={countries}
          currencies={currencies}
          timezones={timezones}
          industryRestrictions={industryRestrictions}
          lastId={lastId}
        />
      )
    } else if (dialogs.name === 'editScreen') {
      modal = (
        <AddScreenModal
          contentLabel={t`Screen`}
          isOpen
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          error={screensError}
          screen={dialogs.options.screen}
          action={this.updateScreen}
          uploadImageAction={this.uploadImage}
          screenGroups={groups}
          createScreenGroupAction={createScreenGroupAction}
          pending={screensPending}
          tags={tags}
          mediaSites={mediaSites}
          ageGroups={ageGroups}
          adFormats={adFormats}
          countries={countries}
          currencies={currencies}
          timezones={timezones}
          industryRestrictions={industryRestrictions}
        />
      )
    } else if (dialogs.name === 'editScreenGroup') {
      modal = (
        <EditScreenGroup
          contentLabel={t`Screen Group`}
          isOpen
          onClose={closeModalAction}
          onRequestClose={closeModalAction}
          ariaHideApp={false}
          error={screenGroupsError}
          action={this.updateScreenGroup}
          pending={screensPending}
          screenGroup={dialogs.options.group}
        />
      )
    }

    // sort users by ID, slice used to make copy of the data. See Array freeze.
    // const usersSorted = users.slice().sort((a, b) => a.id - b.id)

    return (
      <div>
        <NavBar />
        <Container>
          <Paper style={{ flexGrow: 1 }}>
            <Tabs
              value={value}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label={t`Screen groups`} />
            </Tabs>
            <TabContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t`Group`}</TableCell>
                    <TableCell>{t`Screen`}</TableCell>
                    <TableCell>{t`Action`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell component="th" scope="row">
                      <FatInput
                        size="slim"
                        type="text"
                        name="group"
                        placeholder="Name"
                        onChange={this.handleChange}
                        value={group}
                      />
                    </TableCell>
                    <TableCell />
                    <TableCell>
                      <Button
                        size="small"
                        background="#7698cb"
                        onClick={this.handleScreenGroupAdd}
                      >
                        {t`Add Group`}
                      </Button>
                      <Button
                        size="small"
                        background="#7698cb"
                        onClick={this.handleScreenAdd}
                      >
                        {t`Add Screen`}
                      </Button>
                    </TableCell>
                  </TableRow>
                  {screensPending || groupsPending || !items ? (
                    <TableRow key="loading">
                      <TableCell component="th" scope="row" colSpan={3}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    items.map(({ id, name, children }) => (
                      <>
                        <TableRow key={id}>
                          <TableCell component="th" scope="row">
                            {name}
                          </TableCell>
                          <TableCell />
                          <TableCell>
                            <Button
                              size="small"
                              background="#7698cb"
                              onClick={() => {
                                this.handleScreenGroupEdit(id)
                              }}
                            >
                              {t`Edit`}
                            </Button>
                            <Button
                              size="small"
                              background="#ff8db6"
                              onClick={() => {
                                this.handleGroupDelete(id)
                              }}
                            >
                              {t`Remove`}
                            </Button>
                          </TableCell>
                        </TableRow>
                        {children &&
                          children.map(({ id: childId, name: childTitle }) => (
                            <TableRow key={childId}>
                              <TableCell />
                              <TableCell component="th" scope="row">
                                {childTitle}
                              </TableCell>

                              <TableCell>
                                <Button
                                  size="small"
                                  background="#7698cb"
                                  onClick={() => {
                                    this.handleScreenEdit(childId)
                                  }}
                                >
                                  {t`Edit`}
                                </Button>
                                <Button
                                  size="small"
                                  background="#ff8db6"
                                  onClick={() => {
                                    this.handleScreenDelete(childId)
                                  }}
                                >
                                  {t`Remove`}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    ))
                  )}
                </TableBody>
              </Table>
            </TabContainer>
          </Paper>
          {modal}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({
  auth,
  error,
  screens,
  screenGroups,
  dialogs,
  screenAttributes
}) => ({
  auth,
  error,
  screens,
  screenGroups,
  dialogs,
  screenAttributes
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getScreens,
      deleteScreen,
      createScreen,
      updateScreen,
      uploadImage,
      getScreenGroups,
      deleteScreenGroup,
      createScreenGroup,
      updateScreenGroup,
      resetErrorMessage,
      openModal,
      closeModal,
      getTags,
      getAgeGroups,
      getAdFormats,
      getMediaSites,
      getIndustryRestrictions,
      getCountries,
      getCurrencies,
      getTimezones
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreensAndGroups)
