import { Get, Post } from 'api/index'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>
// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const GET_CUSTOMER = 'GET_CUSTOMER'

export const getCustomers = (
  params: Object<{
    search: string
  }> = undefined
): ThunkAction => dispatch => {
  const url = `/api/customers/`

  return dispatch({
    type: GET_CUSTOMERS,
    payload: Get(url, params)
  })
}

export const createCustomer = (
  body: Array<{
    business_id: string,
    contact_persons: Array<{
      first_name: string,
      last_name: string,
      phone_number: string,
      email: string
    }>,
    city: string,
    name: number,
    phone_number: string,
    postcode: string,
    street_address: string,
    organization_id: number,
    country_id: number,
    invoice_address: string,
    invoice_email: string,
    einvoice_address: string,
    einvoice_operator_code: string
  }>
): ThunkAction => dispatch => {
  const url = `/api/customers/`

  return dispatch({
    type: CREATE_CUSTOMER,
    payload: Post(url, JSON.stringify(body))
  })
}

export const getCustomer = (
  id: number,
  search: string = null
): ThunkAction => dispatch => {
  const url = `/api/customers/${id}/`

  return dispatch({
    type: GET_CUSTOMER,
    payload: Get(url, { params: search })
  })
}
