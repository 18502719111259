import { Delete, Get, Post } from 'api/index'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>
// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_PARTNERSHIPS = 'GET_PARTNERSHIPS'
export const CREATE_PARTNERSHIP = 'CREATE_PARTNERSHIP'
export const DELETE_PARTNERSHIP = 'DELETE_PARTNERSHIP'
// add screen to existing partnership
export const ADD_PARTNERSHIP = 'ADD_PARTNERSHIP'

export const getPartnerships = (
  params: Object<{
    search: string
  }> = undefined
): ThunkAction => dispatch => {
  const url = `/api/partnerships/`

  return dispatch({
    type: GET_PARTNERSHIPS,
    payload: Get(url, params)
  })
}

export const addPartnership = (
  body: Array<{
    organization_id: number,
    screen_id: number,
    revenue_share: number,
    time_share: number,
    can_sell: boolean,
    can_operate: boolean
  }>
): ThunkAction => dispatch => {
  const url = `/api/partnerships/`

  return dispatch({
    type: CREATE_PARTNERSHIP,
    payload: Post(url, JSON.stringify(body))
  })
}

export const createPartnership = (
  body: Array<{
    organization_id: number,
    screen_id: number,
    revenue_share: number,
    time_share: number,
    can_sell: boolean,
    can_operate: boolean
  }>
): ThunkAction => dispatch => {
  const url = `/api/partnerships/`

  return dispatch({
    type: CREATE_PARTNERSHIP,
    payload: Post(url, JSON.stringify(body))
  })
}

export const deletePartnership = (id: number): ThunkAction => dispatch => {
  const url = `/api/partnerships/${id}/`

  return dispatch({
    type: DELETE_PARTNERSHIP,
    payload: Delete(url)
  })
}
