// @flow
import React from 'react'
import styled from 'styled-components/macro'
import { lighten } from 'polished'

import Timeline from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import './Timeline.scss'
import moment from 'moment'

import { getColorForPercentage } from 'util/color'

import { summaryColors } from 'config'

const TimelineContainer = styled.div`
  border-radius: 3px;
  background-color: #f2f4f9;
  // padding: 5px;
`

const Item = styled.div`
  border-radius: 16px;

  background-color: ${props => {
    switch (props.status) {
      case 'reserved':
        return '#c2c2c2'

      case 'confirmed':
        return '#ff8db6'

      case 'timed':
        return '#6ad69c'

      case 'invoiced':
        return '#7698cb'

      default:
        return '#c2c2c2'
    }
  }};
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;

  height: ${props => props.dimensions.height}px;
  line-height: ${props => props.dimensions.height}px;
  top: ${props => props.dimensions.top}px;

  :hover {
    z-index: 88;
  }
`

const HeaderItem = styled(Item)`
  border-radius: 0;
  background-color: ${props =>
    props.screenGroup ? '#233d4c' : lighten(0.05, '#233d4c')};
  border-right: 1px solid #2e5063;
  height: 45px;
  line-height: 45px;
  top: ${props => props.dimensions.top - 8}px;
  text-align: center;
  font-size: 15px;

  color: ${props => props.color};
`

const ItemContent = styled.div`
  position: sticky;
  left: 0;
  overflow: hidden;
  display: inline-block;
  padding: 0 10px;
  height: 45px;
  max-height: ${props => props.maxHeight}px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const ItemContentLeft = styled.div`
  // float: left;
`
const ItemContentRight = styled.div`
  // float: right;
`

const itemRenderer = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps
}: {
  item: Object,
  itemContext: Array<Object>,
  getItemProps: void,
  getResizeProps: void
}) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()

  // Extract un-wanted props so we can effectively use Styled-Componenets
  const { style, ...restProps } = getItemProps(item.itemProps)

  const {
    fontSize,
    color,
    cursor,
    background,
    border,
    height,
    lineHeight,
    top,

    ...restStyles
  } = style

  Object.assign(restProps, { style: restStyles })

  let headerColor

  if (item.groupHeader && item.maxDuration) {
    headerColor = getColorForPercentage(
      item.duration / item.maxDuration,
      summaryColors
    )
  }

  return item.groupHeader ? (
    <HeaderItem
      {...restProps}
      duration={item.duration}
      dimensions={itemContext.dimensions}
      color={headerColor}
      screenGroup={item.screenGroup}
    >
      {itemContext.title}
      {item.duration ? 's' : ''}
    </HeaderItem>
  ) : (
    <Item
      {...restProps}
      status={item.status}
      dimensions={itemContext.dimensions}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
      <ItemContent maxHeight={itemContext.dimensions.height}>
        <ItemContentLeft>{itemContext.title}</ItemContentLeft>
        <ItemContentRight>{item.duration}s</ItemContentRight>
      </ItemContent>
      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
    </Item>
  )
}

export default ({
  items,
  groups,
  onBoundsChange,
  onItemDoubleClick
}: {
  items: Array,
  groups: Array,
  onBoundsChange: void,
  onItemDoubleClick: void
}) => (
  <TimelineContainer>
    <Timeline
      keys={{
        groupIdKey: 'id',
        groupTitleKey: 'sidebarTitle',
        groupRightTitleKey: 'rightTitle',
        groupLabelKey: 'title', // key for what to show in `InfoLabel`
        itemIdKey: 'id',
        itemTitleKey: 'title', // key for item div content
        itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
        itemGroupKey: 'group',
        itemTimeStartKey: 'start_time',
        itemTimeEndKey: 'end_time'
      }}
      sidebarContent={<></>}
      sidebarWidth={350}
      groups={groups}
      items={items}
      defaultTimeStart={moment()
        .startOf('day')

        .add(-1, 'w')}
      defaultTimeEnd={moment()
        .endOf('day')

        .add(3, 'w')}
      timeSteps={{
        second: 0,
        minute: 0,
        hour: 0,
        day: 1,
        month: 1,
        year: 1
      }}
      minZoom={60 * 60 * 1000}
      maxZoom={1 * 365.24 * 86400 * 1000}
      canMove={false}
      stackItems
      itemRenderer={itemRenderer}
      lineHeight={38}
      itemHeightRatio={0.78947368421}
      verticalLineClassNamesForTime={null}
      onBoundsChange={onBoundsChange}
      onItemDoubleClick={itemId => onItemDoubleClick(itemId)}
      // onTimeChange={}
    />
  </TimelineContainer>
)
