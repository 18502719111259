// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { bindActionCreators } from 'redux'
import { usersList, usersCreate, usersDelete } from 'store/actions/users'
import { resetErrorMessage } from 'store/actions/error'
import FatInput from 'components/FormElements/Inputs/FatInput/FatInput'
import Button from 'components/Button/Button'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import LinearProgress from '@material-ui/core/LinearProgress'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import NavBar from 'components/Navbar/Navbar'

const Container = styled.div`
  // text-align: center;
  // border-radius: 3px;
  // background-color: #f2f4f9;
  margin: 50px 100px;
  padding: 25px 50px;
`

const TabContainer = styled.div`
  padding: 20px;
`

class Users extends Component<
  {
    usersList: void,
    users: { users: Array<Object>, isPending: boolean },
    error: Array<Object>,
    resetErrorMessage: void,
    usersList: void,
    usersCreate: void,
    usersDelete: void
  },
  {}
> {
  state = {
    value: 0,

    email: '',
    role: false
  }

  componentDidMount() {
    const { usersList: usersListAction } = this.props

    usersListAction()
  }

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0].message) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  handleChange = e => {
    const { name, value } = e.currentTarget

    this.setState({ [name]: value })
  }

  handleCheckbox = e => {
    const { value, checked } = e.currentTarget

    this.setState({ [value]: checked })
  }

  changeTab = (event, value) => {
    this.setState({ value })
  }

  handleInvite = () => {
    const {
      usersCreate: usersCreateAction,
      usersList: usersListAction
    } = this.props
    const { email, role } = this.state

    usersCreateAction({ email, is_admin: role })
      .then(() => usersListAction())
      .then(() => toast.success(t`User invited!`))
  }

  handleDelete = id => {
    const {
      usersList: usersListAction,
      usersDelete: usersDeleteAction
    } = this.props

    usersDeleteAction(id)
      .then(() => usersListAction())
      .then(() => toast.success(t`User deleted!`))
  }

  render() {
    const {
      users: { users, isPending: usersPending }
    } = this.props
    const { value, email, role } = this.state

    // sort users by ID, slice used to make copy of the data. See Array freeze.
    const usersSorted = users.slice().sort((a, b) => a.id - b.id)

    return (
      <div>
        <NavBar />
        <Container>
          <Paper style={{ flexGrow: 1 }}>
            <Tabs
              value={value}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label={t`User management`} />
              <Tab label={t`Organization information`} disabled />
            </Tabs>
            <TabContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t`Email`}</TableCell>
                    <TableCell>{t`Role`}</TableCell>
                    <TableCell>{t`Remove`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell component="th" scope="row">
                      <FatInput
                        size="slim"
                        type="email"
                        name="email"
                        placeholder="user@example.com"
                        onChange={this.handleChange}
                        value={email}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="role"
                            checked={role}
                            onChange={this.handleCheckbox}
                          />
                        }
                        label={t`Admin role`}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        background="#7698cb"
                        onClick={this.handleInvite}
                      >
                        Invite
                      </Button>
                    </TableCell>
                  </TableRow>
                  {usersPending ? (
                    <TableRow key="loading">
                      <TableCell component="th" scope="row" colSpan={3}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    usersSorted.map(({ id, username, is_admin: isAdmin }) => (
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">
                          {username}
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={<Checkbox checked={isAdmin} />}
                            label={isAdmin ? 'Admin' : 'User'}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            background="#ff8db6"
                            onClick={() => {
                              this.handleDelete(id)
                            }}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TabContainer>
          </Paper>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, error, users }) => ({
  auth,
  error,
  users
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      usersList,
      usersCreate,
      usersDelete,

      resetErrorMessage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users)
