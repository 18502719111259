import {
  GET_CAMPAIGN_MATERIALS,
  UPLOAD_MATERIAL,
  GET_CAMPAIGNS,
  REMOVE_MATERIAL,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  CHANGE_CAMPAIGN_STATUS
} from 'store/actions/campaign'
import typeToReducer from 'type-to-reducer'

import produce from 'immer'

const initialState = {
  id: null,
  title: [],
  reservations: [],
  campaigns: [],
  error: [],
  isPending: false,
  isMaterialsPending: false
}

export default typeToReducer(
  {
    [GET_CAMPAIGN_MATERIALS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isMaterialsPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isMaterialsPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const { id, title, reservations } = payload
        return produce(state, draft => {
          draft.id = id
          draft.title = title
          draft.reservations = reservations
          draft.isMaterialsPending = false
        })
      }
    },
    [GET_CAMPAIGNS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) =>
        produce(state, draft => {
          draft.campaigns = payload
          draft.isPending = false
        })
    },
    [UPLOAD_MATERIAL]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const { id, title, reservations } = payload
        return produce(state, draft => {
          draft.id = id
          draft.title = title
          draft.reservations = reservations
          draft.isPending = false
        })
      }
    },

    [REMOVE_MATERIAL]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = payload
        }),
      FULFILLED: state =>
        produce(state, draft => {
          draft.isPending = false
        })
    },
    [UPDATE_CAMPAIGN]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: (state, { payload }) => {
        const campaignIndex = state.campaigns.results.findIndex(
          campaign => campaign.id === payload.id
        )
        return produce(state, draft => {
          if (campaignIndex > -1) {
            draft.campaigns.results[campaignIndex] = payload
          } else {
            draft.campaigns.push(payload)
          }
          draft.isPending = false
        })
      }
    },
    [CHANGE_CAMPAIGN_STATUS]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload: { messages } }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = messages
        }),
      FULFILLED: state =>
        produce(state, draft => {
          draft.isPending = false
        })
    },
    [DELETE_CAMPAIGN]: {
      PENDING: state =>
        produce(state, draft => {
          draft.isPending = true
        }),
      REJECTED: (state, { payload }) =>
        produce(state, draft => {
          draft.isPending = false
          draft.error = payload
        }),
      FULFILLED: state =>
        produce(state, draft => {
          draft.isPending = false
        })
    }
  },
  initialState
)
