import { Get, Post } from 'api/index'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>

// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_CART = 'GET_CART'
export const CONFIRM_CART = 'CONFIRM_CART'

export const getCart = (id: string): ThunkAction => dispatch => {
  const url = `/api/carts/${id}/`

  return dispatch({
    type: GET_CART,
    payload: Get(url)
  })
}

export const confirmCart = (
  id: string,
  body: Array<{
    customer: Array<{
      name: number,
      phone_number: string,
      postcode: string,
      street_address: string,
      business_id: string,
      city: string,
      country_id: number,
      invoice_address: string,
      invoice_email: string,
      einvoice_address: string,
      einvoice_operator_code: string
    }>,
    contact_person: Array<{
      first_name: string,
      last_name: string,
      phone_number: string
    }>,
    campaign_title: string
  }> = {}
): ThunkAction => dispatch => {
  const url = `/api/carts/${id}/confirm/`

  return dispatch({
    type: CONFIRM_CART,
    payload: Post(url, JSON.stringify(body))
  })
}
