// @flow
import React from 'react'
import styled from 'styled-components/macro'
import { t } from 'ttag'

const Status = styled.span`
  color: #303030;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  text-align: right;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;

  ::after {
    margin-left: 15px;
    content: '';
    height: 16px;
    width: 16px;
    border-radius: 50px;
    background-color: ${props => props.color};
    line-height: 18px;
    display: inline-block;
  }
`

const Row = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-left: 35px;
  }
`

const Container = styled.div`
  display: inline-flex;
  padding: 5px 10px;
  background: #f2f4f9;
  border-radius: 5px;
  color: #fff;
  margin-right: 12px;
  vertical-align: middle;
`

export default () => (
  <Container>
    <Row>
      <Status color="#c2c2c2">{t`Preliminary`}</Status>
      <Status color="#6ad69c">{t`Content scheduled`}</Status>
    </Row>
    <Row>
      <Status color="#ff8db6">{t`Confirmed`}</Status>
      <Status color="#7698cb">{t`Invoiced`}</Status>
    </Row>
  </Container>
)
