import { combineReducers } from 'redux'

import { RESET_ERROR_MESSAGE } from 'store/actions/error'
import screenAttributes from 'store/reducers/screenAttributes'
import campaign from 'store/reducers/campaign'
import partners from 'store/reducers/partners'
import organizations from 'store/reducers/organizations'
import customers from './customers'
import reservations from './reservations'
import screenGroups from './screenGroups'
import screens from './screens'
import dialogs from './dialogs'
import users from './users'
import cart from './cart'
import reports from './reports'
import widget from './widget'
import auth from './auth'

const errorMessage = (state = null, action) => {
  const { type, error, payload } = action

  if (type === RESET_ERROR_MESSAGE) {
    return null
  } else if (error && payload.generalError) {
    const { status, statusText, messages } = payload
    return { status, statusText, messages }
  }

  return state
}

export default combineReducers({
  auth,
  error: errorMessage,
  customers,
  reservations,
  screenGroups,
  screens,
  dialogs,
  users,
  cart,
  screenAttributes,
  campaign,
  partners,
  organizations,
  reports,
  widget
})
