import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

const SalesGraph = props => {
  const { data, dataKeys } = props
  return (
    <ResponsiveContainer>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {dataKeys.map(item => (
          <Bar
            key={item.dataKey}
            type={item.type}
            dataKey={item.dataKey}
            fill={item.color}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default SalesGraph
