import React from 'react'
import styled from 'styled-components/macro'

const ModalFooter = styled.div`
  padding-bottom: 15px;
  display: flex;
  align-items: flex-start;
`

const ModalFooterLeft = styled.div`
  margin: 0 15px;
  flex: 1 1 auto;
  align-self: flex-start;

  button {
    margin: 0 15px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const ModalFooterRight = styled.div`
  margin: 0 15px;
  flex: 0 1 auto;
  align-self: flex-end;

  button {
    margin: 0 15px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`
export default ({ children }: { children: React.Node }) => (
  <ModalFooter>{children}</ModalFooter>
)

export { ModalFooterLeft, ModalFooterRight }
