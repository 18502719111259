import { Get, Post, Patch, Delete } from 'api/index'

type Action = { type: 'FOO', foo: number } | { type: 'BAR', bar: boolean }

type GetState = () => State
type PromiseAction = Promise<Action>

// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any

export const GET_CAMPAIGN_MATERIALS = 'GET_CAMPAIGN_MATERIALS'
export const UPLOAD_MATERIAL = 'UPLOAD_MATERIAL'
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'
export const REMOVE_MATERIAL = 'REMOVE_MATERIAL'
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
export const CHANGE_CAMPAIGN_STATUS = 'CHANGE_CAMPAIGN_STATUS'
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN'

export const modifyCampaign = (
  id: string,
  body: Array<{
    title: string
  }> = {}
): ThunkAction => dispatch => {
  const url = `/api/campaigns/${id}/`

  return dispatch({
    type: UPDATE_CAMPAIGN,
    payload: Patch(url, JSON.stringify(body))
  })
}

export const getCampaignMaterials = (id: string): ThunkAction => dispatch => {
  const url = `/api/campaigns/${id}/materials/`

  return dispatch({
    type: GET_CAMPAIGN_MATERIALS,
    payload: Get(url)
  })
}

export const uploadMaterial = (
  id: string,
  resId: string,
  formData: Object
): ThunkAction => dispatch => {
  const url = `/api/campaigns/${id}/upload_material/${resId}/`

  return dispatch({
    type: UPLOAD_MATERIAL,
    payload: Post(url, formData, true)
  })
}

export const getCampaigns = (
  page?: string,
  search?: string,
  searchFrom?: string,
  searchTo?: string
): ThunkAction => dispatch => {
  let url = `/api/campaigns/`

  const searchParams = new URLSearchParams()
  const params = { page, search, searchFrom, searchTo }
  let paramCount = 0
  Object.keys(params).forEach(key => {
    if (params[key] && String(params[key]).trim() !== '') {
      searchParams.append(key, params[key])
      paramCount += 1
    }
  })
  if (paramCount > 0) {
    const p = searchParams.toString()
    url = `/api/campaigns/?${p}`
  }

  return dispatch({
    type: GET_CAMPAIGNS,
    payload: Get(url)
  })
}

export const removeMaterial = (
  id: string,
  resId: string
): ThunkAction => dispatch => {
  const url = `/api/campaigns/${id}/materials/${resId}/`
  return dispatch({
    type: REMOVE_MATERIAL,
    payload: Delete(url)
  })
}

export const changeStatus = (
  id: string,
  formData: Object
): ThunkAction => dispatch => {
  const url = `/api/campaigns/${id}/change_statuses/`

  return dispatch({
    type: CHANGE_CAMPAIGN_STATUS,
    payload: Post(url, formData, true)
  })
}

export const deleteCampaign = (id: string): ThunkAction => dispatch => {
  const url = `/api/campaigns/${id}/`
  return dispatch({
    type: DELETE_CAMPAIGN,
    payload: Delete(url)
  })
}
